@import "../../../app/global-styles/vars";

.DrawerAdditional {
  width: 100%;
}

.Content {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  flex-grow: 1;
}

.SubmitAdditional {
  margin-top: auto;
}

.Actions {
  display: flex;
  margin-top: auto;
  height: 60px;
  grid-gap: 8px;

  button {
    height: 100%;
  }
}