@import "../../../../../../shared/ui-kit-2/_constants/colors";
@import "../../../../../../shared/ui-kit-2/_constants/typography";
@import '../../../../../../app/global-styles/vars.scss';

.Card {
  width: 100%;
  min-height: calc(100vh - 58px - 114px - 6px);
  margin-bottom: -114px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  overflow: hidden;
  position: relative;

  @media (max-width: $lk-mobile-l) {
    min-height: calc(100vh - 223px);
    margin-bottom: 0;
  }
}

.Header {
  padding: 24px 24px 0 24px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  column-gap:  8px;
  justify-content: space-between;
  align-items: center;
}

.Title {
  @include font-gilroy-24;
  color: $dark-text;
}

.HeaderActions {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  column-gap: 8px;
}

.NewSmsBtn {
  height: 48px;
  padding: 0 25px;
}