@import '../../../../../../../../shared/ui-kit-2/_constants/typography.scss';
@import '../../../../../../../../shared/ui-kit-2/_constants/colors.scss';

.CardAdditional {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 30px 20px 32px 32px;
}

.ProductName {
  color: $green;
  display: inline-block;
}

.Header {
  @include font-gilroy-48;
  color: $dark-text;
  margin-bottom: 37px;
  max-width: 420px;
}

.Content {
  display: flex;
  flex-direction: row;
}

.ModemImage {
  width: 100%;
  max-width: 143px;
  object-fit: contain;
}

.Stepper {
  display: flex;
  flex-direction: column;
  margin: 32px 0 0 69px;
}

.StepperTextContent {
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  color: $dark-text;

  padding-bottom: 48px;

  &__title {
    @include font-gilroy(20px, 106%, 700);
    position: relative;
    line-height: 21px;
  }

  &__description {
    @include font-rubik-14;
  }
}

.StepperTextContent:not(:last-child) {
  padding-bottom: 48px;
}

.Dot {
  position: absolute;
  top: 6px;
  left: -35px;
  width: 9px;
  height: 9px;
  background: $green-dark;
  border-radius: 100%;
}

.VerticalLine {
  left: -31px;
  top: 15px;
  position: absolute;
  height: 100%;
  background: $green-dark;
  width: 1px;
}