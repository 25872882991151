@import "../../_constants/colors";
@import "../../_constants/typography";
@import '../../../../app/global-styles/vars.scss';

.Container {
  position: relative;
  padding: 0 24px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background: $white-bg;

  @media (max-width: $lk-tablet) {
    overflow-x: scroll;
    height: 100%;
    min-height: calc(100vh - 300px);
  /*  &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }

    &::-webkit-scrollbar-thumb {
      background: transparent;
    }

    scrollbar-width: none;*/
  }

  @media (max-width: $lk-mobile-l) {
    height: 100%;
    min-height: calc(100vh - 300px);
    padding-bottom: 20px;
  }
}

.Header {
  display: grid;
  column-gap: 8px;
  padding: 0 16px;
  box-sizing: border-box;
  height: 57px;
  align-items: center;
  border: 1px solid $border-gray;
  border-radius: 20px;
  width: 100%;
  position: sticky;
}

.HeaderCell {
  color: $text-gray;
  @include font-gilroy(14px, 120%, 500);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 3px;

  &_right {
    justify-content: flex-end;
  }

  &_center {
    justify-content: center;
    text-align: center;
  }
}

.HeaderCellSorted {
  cursor: pointer;
}

.RowGroups {
  overflow-y: auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  row-gap: 0;
  height: calc(100% - 57px);
  padding-bottom: 20px;
  @media (max-width: $lk-tablet) {

    overflow: auto;
    height: calc(100% - 59px);
  }
}

.RowGroupsWithoutPagination {

}


.ContainerContent {
  overflow: hidden;
  padding-bottom: 58px;

  @media (max-width: $lk-tablet) {
    height: 100%;
    max-height: calc(100% + 30px);
    min-width: max-content;
    width: 100%;
    padding-bottom: 41px;
  }
}

.ContainerContentCalls {
  @media (max-width: $lk-mobile-l) {
    height: auto;
  }
}

.GroupContainer {
  position: relative;
  width: 100%;
}

.GroupHeader {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  padding: 16px 0 8px 0;
  display: flex;
  flex-direction: row;
  column-gap: 16px;
  align-items: center;
  justify-content: flex-start;
  background: $white-bg;
  color: $dark-text;
  @include font-gilroy(14px, 120%, 500);
}

.Rows {
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}

.Row {
  display: grid;
  column-gap: 8px;
  border-radius: 20px;
  background: $sidebar-link-active;
  min-height: 58px;
  padding: 0 16px;
  box-sizing: border-box;
}

.Cell {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: $dark-text;
  @include font-gilroy(14px, 120%, 500);

  &__right {
    justify-content: flex-end;
  }

  &__center {
    justify-content: center;
  }
}

.NothingFound {
  display: flex;
  align-items: center;
  justify-content: center;;
  @include font-gilroy-27;
  color: $text-gray;
  height: 100%;
  padding: 50px 0;
}

.Footer {
  z-index: 3;
  position: absolute;
  left: 0;
  bottom: 0;
  height: 59px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 32px;
  box-sizing: border-box;
  background: $white-bg;
  box-shadow: 0px -4px 12.3px 0px rgba(0, 0, 0, 0.04);
  border-radius: 25px;
  user-select: none;
}

.FooterInfo {
  color: $dark-text;
  @include font-gilroy(13px, normal, 500);
}

.Pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  column-gap: 8px;

  &__page {
    cursor: pointer;
    height: 28px;
    padding: 7px 10px;
    box-sizing: border-box;
    background: $sidebar-link-active;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $dark-text;
    @include font-gilroy(12px, normal, 500);
    border-radius: 8px;

    &_active {
      color: $white;
      background: $dark-text;
    }

    &_rest {
      cursor: default;
    }
  }

  &__arrow {
    width: 16px;
    cursor: pointer;
    height: 100%;
    align-self: center;
    display: flex;

    &_disabled {
      opacity: 0.3;
      cursor: default;
    }
  }
}

.SortedIcon {
  color: #7B8AA0;

  &_active {
    color: $green;
  }
}

.ArrowRotated {
  transform: rotate(180deg);
}