@import '../../../../../shared/ui-kit-2/_constants/colors.scss';
@import "../../../../../app/global-styles/vars";

.Container {
  position: relative;
  width: 100%;

  @media screen and (min-width: $landing-screen-laptop) and (max-width: $landing-screen-laptop-l) {
    .Wrapper {
      width: 1058px;
      margin-top: 150px;
    }

    .Title {
      margin-bottom: 37px;
      font-size: 77px;
      line-height: 94.7%;
    }

    .Card {
      &__Content {
        margin-top: 220px;
        padding: 0 23px;
      }

      &__Title {
        height: 52px;
        font-size: 25px;
      }

      &__Subtitle {
        margin-top: 16px;
        font-size: 16px;
      }

      &__Background {
        scale: 0.9;
      }
    }
  }

  @media screen and (max-width: $landing-screen-laptop) {
    overflow: hidden;

    .Wrapper {
      width: 100%;
      margin: calc((89rem / $rem-mobile) - 4.25rem) 0 0 0;
    }

    .Grid {
      position: relative;
      grid-template-columns: 1fr;
      grid-gap: calc(21rem / $rem-mobile);
      height: auto;
      margin-top: calc(-60rem / $rem-mobile);
      padding: 0 calc(16rem / $rem-mobile);
    }

    .Title {
      margin-bottom: calc(32rem / $rem-mobile);
      font-size: calc(52rem / $rem-mobile);
      line-height: 94.2%;
      letter-spacing: -1px;
    }

    .BackgroundFigures {
      width: 300%;
      height: 90%;
    }

    .Card {
      height: auto;

      &__Content {
        margin-top: calc(220rem / $rem-mobile);
        padding: 0 calc(19rem / $rem-mobile) calc(40rem / $rem-mobile) calc(16rem / $rem-mobile);
      }

      &__Title {
        height: auto;
        font-size: calc(27rem / $rem-mobile);
        font-style: normal;
        font-weight: 700;
        line-height: 100.2%
      }

      &__Subtitle {
        font-size: calc(14rem / $rem-mobile);
        font-style: normal;
        font-weight: 400;
        line-height: 1.5;
      }
    }
  }
}

.Wrapper {
  position: relative;
  width: 1280px;
  margin: 171px auto 0 auto;
}

.Title {
  position: relative;
  margin-bottom: 52px;
  color: $dark-text;
  text-align: center;
  font-family: Gilroy, sans-serif;
  font-size: 86px;
  font-style: normal;
  font-weight: 700;
  z-index: 1;
}

.BackgroundLaptop {
  position: relative;
  display: flex;
  justify-content: center;
  z-index: 1;

  img {
    width: 95%;
  }
}

.BackgroundFigures {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% + 320px);
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
  }
}

.Marked {
  color: $green-dark;
}

.Grid {
  position: absolute;
  top: 78%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 24px;
  width: 100%;
  height: 557px;
  z-index: 1;
}

.Card {
  position: relative;
  width: 100%;
  height: 557px;
  background: $white-bg;
  border-radius: 36px;
  box-shadow: 1px 1px 1px 0 rgba(255, 255, 255, 0.24) inset, 0 6px 24.2px 0 rgba(0, 0, 0, 0.07), 0 0 0 0 rgba(0, 0, 0, 0.60), 1px 1px 2px 0 rgba(0, 0, 0, 0.20), -3px -3px 6px 0 rgba(255, 255, 255, 0.21);

  &__Content {
    position: relative;
    margin-top: 252px;
    padding: 0 25px 0 32px;
    z-index: 1;
  }

  &__Title {
    height: 65px;
    color: $dark-text;
    font-family: Gilroy, sans-serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
  }

  &__Subtitle {
    margin-top: 20px;
    color: $text-gray;
    font-family: Rubik, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
  }

  &__Background {
    position: absolute;
    width: 100%;

    img {
      width: 100%;
      height: 100%;
    }
  }
}