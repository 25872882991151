@import "../../_constants/typography";
@import "../../_constants/colors";
@import "../../_constants/transition-mixins";

.Tabs {
  display: flex;
  flex-direction: row;
  width: max-content;
  height: 47px;
  border-bottom: 1px solid $border-gray;

  &_small {
    height: 38px;
  }
}

.Tab {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px 24px 17px 24px;
  width: max-content;
  max-width: 100%;
  flex-grow: 1;
  height: 100%;
  @include font-gilroy-27;
  box-sizing: border-box;
  color: $text-gray;
  cursor: pointer;
  @include transition-default(all);

  &_disabled {
    opacity: 0.7;
  }

  &_selected {
    color: $dark-text;
  }

  &_small {
    padding: 0 8px 16px 8px;
    @include font-rubik(18px, 120%, 400);
  }
}

.Shadow {
  position: absolute;
  bottom: -1px;
  width: 100%;
  height: 2px;
  background-color: $green;
  opacity: 0;
  @include transition-default(all);

  &_active {
    opacity: 1;
  }
}