@import "../../../../app/global-styles/vars";
@import "../../../../shared/ui-kit-2/_constants/transition-mixins";

.Header {
  position: fixed;
  top: 0;
  left: calc(50% - 640px);
  width: 1280px;
  padding: 28px 0;
  z-index: 10;
  @include transition-default(all);

  &_hidden {
    transform: translateY(-100px);
    opacity: 0;
  }

  @media screen and (min-width: $landing-screen-laptop) and (max-width: $landing-screen-laptop-l) {
    width: 1058px;
    left: calc(50% - 529px);
  }

  @media screen and (max-width: $landing-screen-laptop) {
    width: 100%;
    padding: calc(16rem / $rem-mobile);
    left: 0;

    .Logo {
      width: calc(100rem / $rem-mobile);
      height: calc(30rem / $rem-mobile);
    }
  }
}

.Content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Logo {
  height: 40px;

  svg, img {
    width: 100%;
    height: 100%;
  }
}