@import '../../../../../../../../../shared/ui-kit-2/_constants/colors.scss';
@import '../../../../../../../../../shared/ui-kit-2/_constants/typography.scss';

.ContactCardContainer {
  display: flex;
  flex-direction: row;
  background: $sidebar-link-active;
  width: 100%;
  min-height: 100px;
  border-radius: 20px;
  padding: 16px;
  justify-content: space-between;
}

.CardInfo {
  display: flex;
  flex-direction: column;
}

.CardName {
  color: $dark-text;
  @include font-gilroy-16;
}

.CardPhoneNumber {
  color: $text-gray;
  @include font-gilroy-14;
  margin-top: 4px;
}

.CardGroupsCount {
  padding: 1.5px 8px;
  background: $badge-gray;
  border-radius: 43px;
  margin-top: 15px;
  width: max-content;
  @include font-rubik-14;
}

.CardActions {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.DeleteBtn {
  align-self: end;
  cursor: pointer;
}

.DetailsBtn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 1px solid $green;
  border-radius: 13px;
  color: $green;
  @include font-rubik-14;
  align-items: center;
  padding: 8px 7px;
  background: transparent;
  cursor: pointer;

  svg {
    width: 12px;
    height: 12px;

    path {
      fill: currentColor;
    }
  }
}