@import "../../../../../../shared/ui-kit-2/_constants/typography";
@import "../../../../../../shared/ui-kit-2/_constants/colors";
@import '../../../../../../app/global-styles/vars';

.CardAdditional {
  width: 100%;
  overflow: hidden;
  position: relative;

  @media (max-width: $lk-mobile-l) {
    min-height: calc(100vh - 223px);
    height: 100%;
  }

  @media (max-width: 1279px) {
    min-height: calc(100vh - 223px);
    height: 100%;
  }
}

.Header {
  padding: 24px 24px 16px 24px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  column-gap:  8px;
  justify-content: space-between;
  align-items: center;
}

.Title {
  display: flex;
  align-items: center;
  grid-gap: 16px;
  @include font-gilroy-24;
  color: $dark-text;
}

.HeaderActions {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  column-gap: 8px;
}

.Action {
  height: 48px;
  padding: 0 25px;
}

.NewCallBtn {
  height: 48px;
  padding: 0 25px;
}