@import "../../../shared/ui-kit-2/_constants/colors";
@import "../../../shared/ui-kit-2/_constants/typography";
@import '../../../app/global-styles/vars.scss';

.DrawerAdditional {
  width: 100%;
}

.Container {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  height: 100%;
  flex-grow: 1;
}

.Details {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}

.Detail {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  padding: 20px 16px;
  border-radius: 20px;
  border: 1px solid $border-gray;

  &__title {
    @include font-rubik-16;
    color: $text-gray;
  }

  &__value {
    @include font-gilroy-16;
    color: $dark-text;

    &_green {
      color: $green;
    }
  }
}

.DeliveryPriceWrapper {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;

  svg {
    width: 67px;
    height: 38px;
  }
}

.DeliveryPriceChangedWrapper {
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  align-items: center;

  @media (max-width: $lk-mobile-l) {
    flex-direction: column;
    row-gap: 8px;
    align-items: start;
  }
}

.DeliveryPrice {
  color: $text-gray;
  @include font-gilroy-16;
  text-decoration: line-through;
}

.DeliveryPriceDescription {
  color: $green-dark;
  @include font-gilroy-14;
}

.TotalAmount {
  height: 97px;
  display: flex;
  align-items: center;
  flex-direction: row;;
  justify-content: space-between;
  background: $bg-gray-2;
  border-radius: 23px;
  padding: 0 32px;
  @include font-gilroy(18px, 120%, 700);
  color: $dark-text;
}

.AdditionalInfo {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  margin-top: 24px;
}

.AdditionalInfoItem {
  display: flex;
  flex-direction: column;
  row-gap: 8px;

  &__title {
    @include font-rubik-14;
    color: $text-gray;
  }

  &__value {
    @include font-gilroy-16;
    color: $dark-text;
  }
}

.Actions {
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  margin-top: auto;
}

.Action {
  height: 48px;
}

.TrackNumberContainer {
  padding: 15px 16px;
  border: 1px solid $border-gray;
  border-radius: 20px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  svg {
    width: 67px;
    height: 38px;
    align-self: end;
  }
}

.TrackNumberDescription {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  max-width: 230px;
  width: 100%;
  @include font-gilroy-20;
  color: $dark-text;
}

.TrackNumberWrapper {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}

.TrackNumberTitle {
  color: $text-gray;
}

.TrackNumber {
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  align-items: center;
  div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 142px;
  }
}


.Copy {
  display: flex;
  align-items: center;
  margin-left: 12px;
  color: $green-dark;
  font-family: Gilroy, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  border: none;
  background: transparent;
  transition: 0.3s;
  cursor: pointer;

  svg {
    width: 14px;
    height: 14px;
  }

  &:hover {
    opacity: 0.7;
  }
}

.CopyIcon {
  margin: 2px 8px 0 0;
}