@import "../../../../../shared/ui-kit-2/_constants/colors";
@import "../../../../../shared/ui-kit-2/_constants/typography";

.Container {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  padding: 22px 24px 32px 24px;
  border: 1px solid $border-gray;
  border-radius: 20px;
}

.Header {
  display: flex;
  flex-direction: row;
  column-gap: 16px;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 16px;
  border-bottom: 1px solid $border-gray;
  margin-bottom: 24px;
}

.ReplyType {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  column-gap: 8px;
  @include font-rubik-18;
  color: $dark-text;
}

.SmsTimeAndStatus {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  column-gap: 16px;
  @include font-gilroy-14;
  color: $dark-text;
}

.GrayCard {
  background: $sidebar-link-active;
  width: 100%;
  position: relative;
  border-radius: 20px;
}

.MessageContainer {
  padding: 16px;
  display: flex;
  flex-direction: column;
  row-gap: 8px;

  &__title {
    @include font-rubik-14;
    color: $text-gray;
  }

  &__text {
    @include font-rubik-16;
    color: $dark-text;
    line-height: 120%;
  }
}

