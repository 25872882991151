@import "../../../../../../shared/ui-kit-2/_constants/colors";
@import "../../../../../../shared/ui-kit-2/_constants/typography";
@import "../../../../../../shared/ui-kit-2/_constants/transition-mixins";

@keyframes header-dropdown-appearance {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.Container {
  position: relative;

  &_vw {
    min-width: 14.6rem;

    .MenuBtn {
      width: auto;
      height: auto;
      border-radius: 1.25rem;
      padding: 0.6rem 1rem 0.6rem 0.6rem;

      &_opened {
        border-radius: 1.25rem 1.25rem 0 0;
        box-shadow: none;
      }
    }

    .Icon {
      width: 1rem;
      height: 1rem;
    }

    .Avatar {
      width: 2.5rem;
      height: 2.5rem;
    }

    .Nickname {
      margin-right: 1rem;
      font-size: 0.875rem;
    }

    .DropdownContainer {
      width: 100%;
      position: absolute;
      right: 0;
      top: 0;
      border-radius: 20px;
      box-shadow: 0 6px 24.2px 0 rgba(0, 0, 0, 0.07);
      z-index: 999;
    }

    .Dropdown {
      width: 100%;
      background: $white-bg;
      padding: 1.25rem 1rem 1.5rem 1rem;
      border-radius: 0 0 1.25rem 1.25rem;
      animation: header-dropdown-appearance 0.2s ease;
    }

    .DropdownPages {
      &__page {
        column-gap: 0.5rem;
        font-size: 1rem;

        &_danger {
          font-size: 0.875rem;
        }
      }
    }

    .ButtonBuyNewDevice, .ButtonActivateDevices {
      font-size: 0.875rem;
      height: 3.125rem;
      border-radius: 1.25rem;
    }
  }
}

.MenuBtn {
  height: 50px;
  width: 228px;
  background: $white-bg;
  border-radius: 20px;
  box-shadow: 0 6px 24.2px 0 rgba(0, 0, 0, 0.07);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 5px 16px 5px 5px;
  cursor: pointer;
  user-select: none;

  &_opened {
    border-radius: 20px 20px 0 0;
    box-shadow: none;
  }

  &_hidden {
    opacity: 0;
  }
}

.AvatarAndNickname {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  column-gap: 8px;
}

.Avatar {
  min-width: 40px;
  height: 40px;
  border-radius: 66px;
  align-items: center;
  justify-content: center;
  display: flex;
  background: $badge-gray;

  & > img {
    width: 100%;
    height: 100%;
    border-radius: 100%;
  }
}

.Nickname {
  @include font-rubik-14;
  color: $dark-text;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 140px;
}

.DropdownContainer {
  z-index: 999;
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 20px;
  box-shadow: 0 6px 24.2px 0 rgba(0, 0, 0, 0.07);
}

.Dropdown {
  width: 100%;
  background: $white-bg;
  padding: 21px 16px 24px 16px;
  border-radius: 0 0 20px 20px;
  animation: header-dropdown-appearance 0.2s ease;
}

.DropdownPages {
  display: flex;
  flex-direction: column;
  row-gap: 16px;

  &__page {
    display: flex;
    flex-direction: row;
    column-gap: 8px;
    cursor: pointer;
    align-items: center;
    @include font-gilroy-16;
    @include transition-default(all);

    path, circle {
      @include transition-default(all);
    }

    &:not(.DropdownPages__page_danger):hover {
      color: $green;

      .SvgStroke {
        path, circle {
          stroke: $green;
        }
      }

      .SvgFill {
        path {
          fill: $green;
        }
      }
    }

    &_danger {
      color: $red-alert;
      @include font-rubik-14;
    }
  }
}

.DropdownDivider {
  width: 100%;
  height: 1px;
  background: $border-gray;
  margin: 24px 0 16px 0;
}

.Buttons {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  margin-top: 24px;
}

.ButtonBuyNewDevice {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  column-gap: 8px;
  cursor: pointer;
  @include font-gilroy(14px, 24px, 500);
  height: 50px;
  border-radius: 20px;
  background: $green;
  color: $white;
  @include transition-default(all);

  &:hover {
    background: $green-dark;
  }
}

.ButtonActivateDevices {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  column-gap: 8px;
  cursor: pointer;
  @include font-gilroy(14px, 24px, 500);
  padding: 0 24px;
  height: 50px;
  border-radius: 20px;
  background: none;
  color: $green-dark;
  @include transition-default(all);
  border: 1px solid $green-dark;

  svg path {
    @include transition-default(all);
  }

  &:hover {
    color: $white;
    background: $green-dark;

    svg path {
      fill: $white;
    }
  }
}

.Icon {
  display: flex;
  align-items: center;
  max-width: 22px;
  max-height: 22px;
}