@import "../../../../../shared/ui-kit-2/_constants/colors";
@import "../../../../../shared/ui-kit-2/_constants/typography";

.Container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.MailingInfoContainer {
  padding: 24px;
  border: 1px solid $border-gray;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
}

.MailingInfoHeader {
  display: flex;
  flex-direction: row;
  column-gap: 16px;
  align-items: flex-start;
  justify-content: space-between;
  padding-bottom: 16px;
  border-bottom: 1px solid $border-gray;

  &__title {
    @include font-gilroy-27;
    color: $dark-text;
  }
}

.MailingInfoDetails {
  margin-top: 24px;
  height: 42px;
  display: flex;
  flex-direction: row;

  &__divider {
    width: 1px;
    height: 100%;
    background: $border-gray;
    margin: 0 6.5px;
  }
}

.MailingInfoDetail {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
  @include font-rubik-14;

  &__title {
    color: $text-gray;
  }

  &__value {
    color: $dark-text;
  }
}

.MessageContainer {
  margin-top: 24px;
  border-radius: 20px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  background: $sidebar-link-active;

  &__title {
    @include font-rubik-14;
    color: $text-gray;
  }

  &__text {
    @include font-rubik-16;
    color: $dark-text;
  }
}