@import "../../../../../shared/ui-kit-2/_constants/typography";
@import "../../../../../shared/ui-kit-2/_constants/colors";


.Header {
  display: flex;
  align-items: center;
  flex-direction: row;
  column-gap: 24px;
  justify-content: flex-start;

  &__date {
    @include font-gilroy-14;
    color: $dark-text;
  }
}

.SimpleText {
  @include font-gilroy-14;
  color: $dark-text;
}

.OrderLink {
  text-decoration: underline;
  color: $green-dark;
}

.Document {
    display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  column-gap: 8px;
  color: $green-dark;
  @include font-gilroy-14;
}

.DocumentLink {
  color: $green-dark;
}