@import "../../_constants/colors";
@import "../../_constants/transition-mixins";

@keyframes show-drawer {
  from {
    opacity: 0;
    padding-right: 0;
  }
  to {
    opacity: 1;
    padding-right: 8px;
  }
}

@keyframes show-drawer-content {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.Drawer {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  max-height: 100vh;
  background: rgba(0, 0, 0, 0.61);
  top: 0;
  right: 0;
  z-index: 999;
  padding: 8px;
  box-sizing: border-box;
  overflow: scroll;
  overscroll-behavior: none;
  animation: show-drawer 0.1s ease;
}

.Content {
  position: relative;
  width: max-content;
  height: max-content;
  background: $white-bg;
  box-sizing: border-box;
  border-radius: 30px;
  animation: show-drawer-content 0.2s ease-out;
}