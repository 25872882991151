@import '../../../app/global-styles/vars.scss';

.loader_wrapper {
  position: fixed;
  right: 20px;
  bottom: 20px;
  color: #ffffff;
  height: 65px;
  width: 65px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  background: linear-gradient(95deg, rgb(47, 50, 74) 20%, rgb(66, 72, 103) 80%);
  z-index: 9999;

  @media (max-width: $lk-tablet) {
    display: none;
  }
}