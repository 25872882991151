@import "../../../../../../shared/ui-kit-2/_constants/colors";
@import "../../../../../../shared/ui-kit-2/_constants/typography";
@import '../../../../../../app/global-styles/vars.scss';

.StepPaymentWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 24px;
}

.PaymentsWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  column-gap: 16px;
  width: 100%;
  margin-top: 84px;

  @media screen and (max-width: $lk-mobile-xl) {
    flex-direction: column;
    grid-gap: 8px;
    margin-top: 24px;
  }
}

.StepPaymentActionsWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: auto;

  @media screen and (max-width: $lk-mobile-xl) {
    margin-top: 24px;
  }
}

.Button {
  margin-left: auto;
}

.Actions {
  display: flex;
  align-items: center;
  grid-gap: 16px;

  button {
    width: 221px;
    height: 60px;
  }

  @media screen and (max-width: $lk-mobile-xl) {
    grid-gap: 8px;

    button {
      width: 88px;
    }
  }
}