@import '../../../../../shared/ui-kit-2/_constants/colors.scss';
@import "../../../../../app/global-styles/vars";

.Container {
  width: 1280px;
  margin: 140px auto 0;

  @media screen and (min-width: $landing-screen-laptop) and (max-width: $landing-screen-laptop-l) {
    width: 1058px;

    .Title {
      margin-bottom: 46px;
      font-size: 77px;
    }

    .Grid {
      grid-template-rows: repeat(3, 240px);
    }
  }

  @media screen and (max-width: $landing-screen-laptop) {
    width: 100%;
    margin-top: calc(81rem / $rem-mobile);
    padding-left: calc(16rem / $rem-mobile);
    padding-right: calc(16rem / $rem-mobile);

    .Title {
      margin-bottom: calc(19rem / $rem-mobile);
      font-size: 10.63rem;
    }

    .Grid {
      grid-template-areas: "wifi" "iot" "mobile" "sms" "api" "chatbot";
      grid-template-rows: repeat(6, auto);
      grid-template-columns: 100%;
      grid-gap: calc(18rem / $rem-mobile);
    }

    .Card {
      height: 120rem;
      border-radius: 10rem;
    }

    .Wifi {
      min-height: calc(487rem / $rem-mobile);

      &__Background {
        margin-top: -10px;
      }
    }

    .Iot {
      &__Background {
        height: 100%;
        top: 9%;
        left: -72%;
        scale: 1.3;
      }
    }

    .Api {
      &__Background {
        height: 75%;
      }
    }

    .Sms {
      &__Background {
        height: auto;
      }
    }

    .Mobile {
      &__Background {
        top: 4%;
        left: -84%;
        scale: 1.45;
      }
    }

    .Chatbot {
      &__Background {
        width: 100%;
        height: calc(400rem  / $rem-mobile);
        left: 0;
        background: rgb(247, 249, 254);
      }
    }

    .CardWrapper {
      height: auto;
    }

    .CardContainer {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
}

.Title {
  margin-bottom: 36px;
  color: $dark-text;
  text-align: center;
  font-family: Gilroy, sans-serif;
  font-size: 87px;
  font-style: normal;
  font-weight: 700;
}

.Grid {
  display: grid;
  grid-template-areas: "wifi wifi iot iot iot pocket pocket"
                 "wifi wifi mobile mobile mobile chatbot chatbot"
                 "sms sms sms api api chatbot chatbot";
  grid-gap: 24px;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: repeat(3, 293px);
}

.Wifi {
  grid-area: wifi;
}

.Iot {
  grid-area: iot;
}

.Pocket {
  grid-area: pocket;
}

.Mobile {
  grid-area: mobile;
}

.Chatbot {
  grid-area: chatbot;
}

.Sms {
  grid-area: sms;
}

.Api {
  grid-area: api;
}