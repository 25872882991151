@import "../../../../shared/ui-kit-2/_constants/colors";
@import "../../../../shared/ui-kit-2/_constants/typography";

.Container {
  display: flex;
  flex-direction: row;
  position: absolute;
  padding-left: 210px;
  width: calc(100% - 210px);
  box-sizing: border-box;
}

.Content {
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 112px;
  padding: 0 24px;

  &_right {
    justify-content: flex-end;
  }
}

.ApiButton {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  column-gap: 8px;
  height: 50px;
  cursor: pointer;
  width: max-content;
  padding: 13px 30px 13px 16px;
  border-radius: 20px;
  box-shadow: 0 6px 24.2px 0 rgba(0, 0, 0, 0.07);
  background: $white-bg;
  border: none;
  @include font-rubik-14;
  color: $green;
}

.Bar {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  column-gap: 12px;
}
