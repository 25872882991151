@import "../../../../../../../../app/global-styles/vars";
@import "../../../../../../../../shared/ui-kit-2/_constants/colors";
@import "../../../../../../../../shared/ui-kit-2/_constants/typography";

.ProfileForm {
  @media (max-width: $lk-mobile-l) {
    .ProfileFormGrid {
      grid-template-columns: repeat(1, 1fr);
    }

    .Actions {
      justify-content: space-between;
      margin-top: 0;
    }

    .Nickname {
      font-size: 22px;
    }

    .NicknameHeader {
      margin-bottom: 0 !important;
    }

    .NicknameSection {
      display: flex;
      flex-direction: column;
      row-gap: 5px;
    }

    .Divider {
      width: 100%;
      height: 1px;
      margin: 16px 0;
      background: $border-gray;
    }

    .MobileGrid {
      display: grid;
      grid-template-columns: repeat(2, 150px);
      grid-row-gap: 16px;
      grid-column-gap: 44px;

      &__FieldName {
        margin-bottom: 5px;
        color: $text-gray;
        font-family: Rubik, sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 1.2;
      }

      &__FieldValue {
        font-family: Rubik, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        word-break: break-all;
      }
    }

    .ChangePassword, .Edit {
      width: calc(50% - 3.5px);
    }

    .DevicesAmount {
      font-size: 14px;
    }
  }

  @media (max-width: $lk-mobile-s) {
    .MobileGrid {
      grid-template-columns: repeat(2, calc(50% - 22px));
    }
  }
}

.ProfileFormGrid {
  display: grid;
  column-gap: 16px;
  row-gap: 24px;
  grid-template-columns: repeat(2, 1fr);
}

.Actions {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;

  &_submit {
    width: 212px;
    height: 48px;
    margin-left: auto;
  }
}

.fullSizeItem {
  grid-column: span 2;
}

.Controls {
  display: flex;
  justify-content: space-between;
}

.ChangePassword {
  width: 160px;
}

.Header {
  display: flex;
  flex-direction: row;
  column-gap: 26px;
  align-items: center;
}

.NicknameSection {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}

.Nickname {
  color: $dark-text;
  @include font-gilroy-27;
}

.DevicesAmount {
  display: flex;
  flex-direction: row;
  column-gap: 16px;
  align-items: center;
  @include font-rubik-14;

  &__active {
    color: $green-dark;
  }

  &__inactive {
    color: $text-gray;
  }
}

.ProfileFormContainer {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.CheckBoxLegal {
  margin: 24px 0;

  label {
    cursor: pointer;
    align-items: center;
    div:nth-child(1) {
      @include font-gilroy-20-bold;
      color: $dark-text;
      margin: 0;
    }
  }
}

.Title {
  @include font-gilroy-20-bold;
  color: $dark-text;
  margin: 24px 0;
}

.AddressTitle {
  @include font-gilroy-20-bold;
  color: $dark-text;
}

.ProfileFormWrapper {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
}

.SameWord {
  @include font-gilroy-16;
  color: $green-dark;
}

.DeliverySameTitle {
  @include font-gilroy-16;
  color: $dark-text;
  margin: 16px 0;
}