.header_menu_btn {
  height: 39px;
  text-decoration: none;
  background: none;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  text-transform: uppercase;
  border: none;
  outline: none;
  font-family: Play, sans-serif;

  &__text {
    color: #ffffff;
    font-weight: 400;
    transition: color 0.1s ease;

    &:hover, &:active {
      color: #D1D74E;
    }

    &__inverted {
      color: #080E16;
      font-weight: 400;
      transition: color 0.1s ease;

      &:hover, &:active {
        color: #D1D74E;
      }
    }
  }

  &__bordered {
    color: #ffffff;
    border: 1px solid #ffffff;
    font-weight: 700;
    transition: color, border-color 0.1s ease;

    &:hover, &:active {
      color: #D1D74E;
      border-color: #D1D74E;
    }

    &__inverted {
      color: #080E16;
      border: 1px solid #080E16;
      font-weight: 700;
      transition: color, border-color 0.1s ease;

      &:hover, &:active {
        color: #D1D74E;
        border-color: #D1D74E;
      }
    }
  }

  &__gradient {
    color: #080E16;
    background: linear-gradient(50deg, #79B75E 0%, #D1D74E 100%);
    font-weight: 700;
    transition: color 0.1s ease;

    &:hover, &:active {
      color: #ffffff;
    }
  }
}

.HeaderButton {
  padding: 10px;
  font-family: Rubik, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
}