@import '../../shared/ui-kit-2/_constants/typography.scss';
@import '../../shared/ui-kit-2/_constants/colors.scss';

.Container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 78px;
  background-color: #f8f9fa;
  border-top: 1px solid #e7e7e7;
  z-index: 4;
  border-radius: 35px 35px 0 0;
  padding: 11px 30px;
  align-items: center;
}

.PageButton {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  @include font-rubik-12;
  color: $dark-text;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.PageButtonActive {
  color: $green-dark;

  svg {
    path {
      fill: currentColor;
    }
  }
}