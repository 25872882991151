@import '../../../../../shared/ui-kit-2/_constants/colors';
@import '../../../../../shared/ui-kit-2/_constants/typography';

.Container {
  display: flex;
  flex-direction: column;
  background: $sidebar-link-active;
  min-height: 68px;
  border-radius: 20px;
}

.Header {
    display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 24px 16px 0 16px;
}

.Title {
  display: flex;
  flex-direction: row;
  column-gap: 13px;
  align-items: center;
  justify-content: center;
  @include font-rubik-18;
  color: $dark-text;
}

.ContactList {
  margin: 16px 0 8px 0;
  padding: 0 16px;
  display: flex;
  flex-direction: column;
}

.SearchContainer {
  border-top: 1px solid $border-gray;
  width: 100%;
  padding-top: 16px;
}

.EmptyContent {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  justify-content: center;
  align-items: center;
  @include font-rubik-16;
  color: $text-gray;
  margin: 24px 0 16px 0;
}

.AddContactWhenEmptyBtn {
  width: max-content;
  padding: 0 32px;
}

.Contact {
  display: flex;
  flex-direction: row;
  column-gap: 16px;
  padding: 16px 0;
  border-top: 1px solid $border-gray;
  align-items: center;
  justify-content: space-between;
  @include font-rubik-16;
  color: $dark-text;

  &_top {
    border-top: none;
    margin-top: 8px;
  }
}

.ContactText {
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  align-items: center;
  justify-content: center;
  @include font-rubik-16;

  &__textGray {
    color: $text-gray;
  }

  &__darkText {
    color: $dark-text;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 290px;
  }
}

.Actions {
  display: flex;
  flex-direction: row;
  column-gap: 22px;
  align-items: center;
  justify-content: center;
}

.Delete {
  cursor: pointer;
}