@import '../../shared/ui-kit-2/_constants/colors.scss';
@import '../../shared/ui-kit-2/_constants/typography.scss';
@import '../../app/global-styles/vars.scss';

:global {
  .MuiPaper-root {
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 120% !important;
    font-family: Rubik, sans-serif !important;
  }

  .MuiSvgIcon-root {
    font-size: 22px !important;
    padding: 0 !important;
  }

  .MuiAlert-standardSuccess {
    background: $light-green !important;
  }

  @media (max-width: $lk-tablet) {
    .MuiPaper-root {
      font-size: 16px !important;
    }
  }

  @media screen and ($lk-tablet) and ($lk-laptop) {
    .MuiPaper-root {
      font-size: 16px !important;
    }
  }

  @media (min-width: $lk-laptop) {
    .MuiPaper-root {
      font-size: 18px !important;
    }
  }
}