@import '../../_constants/colors.scss';
@import '../../_constants/typography.scss';

.StatusNetwork {
  display: flex;
  flex-direction: row;
  column-gap: 5px;
  align-items: center;
  white-space: nowrap;
  @include font-rubik-12;
  color: $green-dark;
  border-radius: 43px;
  border: 1px solid $green-dark;
  padding: 5px 8px;
  height: 25px;
}

.Offline {
  color: $red-alert;
  border: 1px solid $red-alert;
}
