@import '../../../shared/ui-kit-2/_constants/colors.scss';
@import '../../../shared/ui-kit-2/_constants/typography.scss';

.Container {
  display: flex;
  flex-direction: column;
  width: 522px;
  flex-grow: 1;

}

.Image {
  margin-top: 73px;
  max-width: 315px;
  margin-left: auto;
}

.Title {
  margin-top: auto;
  @include font-gilroy-48;
  color: $dark-text;
}

.Description {
  margin: 24px 0;
  @include font-rubik-16;
  color: $dark-text;
}