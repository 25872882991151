.DrawerAdditional {
  width: 522px;
  max-width: 100%;
  height: 100%;
}

.actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;

  &_cancel {
    width: calc(50% - 4px);
    height: 60px;
  }

  &_save {
    width: calc(50% - 4px);
  }
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

