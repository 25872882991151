@import "../../_constants/typography";
@import "../../_constants/colors";

.Card {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  border-radius: 26px;
  background: $white-bg;
  box-shadow: 0 6px 24.2px 0 rgba(0, 0, 0, 0.07);
}