@import "../../../../../ui-kit-2/_constants/colors";
@import "../../../../../ui-kit-2/_constants/typography";

.Container {
    display: flex;
  flex-direction: column;
  row-gap: 16px;
  padding: 18px 17px 16px 17px;
  border: 1px solid $border-gray;
  border-radius: 20px;
}

.Header {
  display: flex;
  align-items: center;
  flex-direction: row;
  column-gap: 16px;
  justify-content: space-between;
  cursor: pointer;
}

.HeaderTitle {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  column-gap: 10px;
  @include font-rubik-16;
  color: $dark-text;
}

.AddOrRemove {
  @include font-gilroy(27px, 24px, 400);
  color: $dark-text;
}

