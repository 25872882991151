@import "../../../../shared/ui-kit-2/_constants/typography";
@import "../../../../shared/ui-kit-2/_constants/colors";

.Container {
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-height: var(--app-height);
  overflow: hidden;
  position: relative;
}

.Logo {
  margin-left: 65px;
  width: 119px;
  min-height: 40px;
  cursor: pointer;
  z-index: 2;
}

.Title {
  margin-left: 65px;
  margin-top: 64px;
  max-width: 762px;
  @include font-gilroy-68;
  letter-spacing: -1.36px;
  color: $dark-text;
  z-index: 2;

  & > span {
    color: $green;
  }
}

.VideoContainer {
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.Video {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1;
}

.VideoOverlay {
  position: absolute;
  bottom: 0;
  padding-bottom: 49.69%;
  width: 100%;
  z-index: 2;
  background: linear-gradient(90deg, rgba(235, 235, 245, 0.00) 0.1%, rgba(235, 235, 245, 0.00) 92.8%, #EBEBF5 99.92%), linear-gradient(0deg, rgba(235, 235, 245, 0.00) 0%, rgba(235, 235, 245, 0.00) 75.99%, #EBEBF5 100.25%);
}