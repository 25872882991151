@import "../../../../../../../../app/global-styles/vars";
@import '../../../../../../../../shared/ui-kit-2/_constants/typography';
@import '../../../../../../../../shared/ui-kit-2/_constants/colors';

.Card {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  position: relative;
  height: 250px;
  margin-bottom: 16px;

  @media (max-width: $lk-mobile-l) {
    overflow: auto;
    margin-bottom: 8px;
    height: 476px;
  }
}

.CardContent {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  height: 247px;
  opacity: 0.3;
  pointer-events: none;
}

.Header {
  padding: 24px 24px 0 24px;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  @include font-gilroy-24;
}

.DevelopInfo {
  position: absolute;
  background: $green;
  border-radius: 20px;
  padding: 5.5px 8px;
  right: 15px;
  color: $white;
  @include font-gilroy-12;
  top: 24px;

  @media (max-width: $lk-mobile-l) {
    top: 16px;
  }
}