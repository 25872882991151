@import '../../../../../../../../../../shared/ui-kit-2/_constants/typography';
@import '../../../../../../../../../../shared/ui-kit-2/_constants/colors';

.ProfileLegalAddressContainer {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  margin-top: 24px;
  @include font-gilroy-20-bold;
  color: $dark-text;
}

.ProfileGrid {
  display: grid;
  column-gap: 16px;
  row-gap: 24px;
  grid-template-columns: repeat(2, 1fr);
}