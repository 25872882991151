@import "../../../../shared/ui-kit-2/_constants/typography";
@import "../../../../shared/ui-kit-2/_constants/colors";
@import '../../../../app/global-styles/vars.scss';

.CardAdditional {
  margin: 10px 10px 10px 0;
  height: 100%;
  width: max-content;
  max-height: calc(var(--app-height) - 20px);

  @media (max-width: $lk-mobile-l) {
    width: 100%;
    max-width: 343px;
    height: max-content;
    margin: 32px 0 0 0 ;
    z-index: 10;
  }
}

.CardContent {
  padding: 56px 62px 56px 70px;
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  row-gap: 32px;
  width: 554px;

  @media (max-width: $lk-mobile-l) {
    padding: 24px 16px 32px 16px;
    width: 100%;
    overflow: hidden;
  }
}

.TabsAdditional {
  width: 100% !important;

  @media (max-width: $lk-mobile-l) {
    span {
      @include font-gilroy-16;
    }
  }
}