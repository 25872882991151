@import "../../../../shared/ui-kit-2/_constants/colors";
@import "../../../../shared/ui-kit-2/_constants/typography";
@import "../../../../shared/ui-kit-2/_constants/transition-mixins";

.Container {
    display: flex;
  flex-direction: column;
  row-gap: 12px;
  width: 100%;
}

.PageButtonIcon svg {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.PageButtonTitle {
  @include font-gilroy-16;
  font-weight: 600;
  color: $dark-text;
}

.PageButton {
  cursor: pointer;
    display: flex;
  flex-direction: row;
  column-gap: 16px;
  align-items: center;
  padding: 11px 16px;
  border-radius: 100px;
  @include transition-default(all);

  &:hover {
    background: $sidebar-link-active;
  }

  &_active {
    background: $sidebar-link-active;
    box-shadow: 0 6px 24.2px 0 rgba(0, 0, 0, 0.07), -12px -12px 21px 0px rgba(255, 255, 255, 0.60), 1px 1px 2px 0px rgba(0, 0, 0, 0.20), -3px -3px 6px 0px rgba(255, 255, 255, 0.21), 1px 1px 1px 0px rgba(255, 255, 255, 0.24) inset;

    .PageButtonIcon svg path {
      fill: $green;
    }

    .PageButtonTitle {
      color: $green;
    }
  }
}