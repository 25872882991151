@import '../../../../shared/ui-kit-2/_constants/colors.scss';
@import "../../../../app/global-styles/vars";

.SliderCard {
  min-width: 352px;

  &_black {
    .Line, .Title, .Subtitle, .Text {
      color: #202D40;
    }
  }

  &_white {
    .Line {
      color: #fff;
    }

    .Text {
      color: $border-gray;
    }
  }
}

.Line {
  margin-bottom: 35px;

  svg {
    width: 100%;
    height: 100%;
  }
}

.Title {
  margin-bottom: 16px;
  font-family: Gilroy, sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 94.2%;
  letter-spacing: -1px;
}

.Subtitle {
  margin-bottom: 16px;
  font-family: Gilroy, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
}

.Text {
  font-family: Rubik, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
}