@import "../../../../../shared/ui-kit-2/_constants/colors";
@import "../../../../../shared/ui-kit-2/_constants/typography";

.Container {
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  padding: 22px 24px 21px 24px;
  align-items: flex-start;
  border: 1px solid $border-gray;
  border-radius: 20px;
}

.Redirections {
  @include font-rubik-18;
  color: $dark-text;
  line-height: 28px;

  & > span {
    color: $green-dark;
  }
}
