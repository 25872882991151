@import '../../../../shared/ui-kit-2/_constants/colors.scss';
@import '../../../../shared/ui-kit-2/_constants/typography.scss';

.Card {
  display: flex;
  flex-direction: row;
  column-gap: 55px;
  align-items: center;
  padding: 0 37px;
  height: 632px;
}

.DescriptionAndButton {
  display: flex;
  flex-direction: column;
  row-gap: 27px;

  button {
    max-width: 281px;
  }
}

.Description {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  @include font-rubik-16;
  color: $dark-text;

  &__title {
    @include font-gilroy-48;
    color: $dark-text;
  }
}