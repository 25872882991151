@import "../../../../../../../shared/ui-kit-2/_constants/colors";
@import "../../../../../../../shared/ui-kit-2/_constants/typography";

@keyframes mailing-progress-bar {
  from {
    left: -100%;
  }
  to {
    left: 300px;
  }
}

.Container {
  flex-grow: 1;
    display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.ProgressContainer {
  padding: 24px 16px;
  background: $sidebar-link-active;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  justify-content: space-between;
  align-items: center;

  &__title {
    @include font-rubik-16;
    color: $dark-text;
  }
}

.ProgressBar {
  position: relative;
  overflow: hidden;
  width: 252px;
  height: 2px;
  background: $white;
  border-radius: 1px;

  &__ticker {
    position: absolute;
    height: 2px;
    background: $green;
    border-radius: 1px;
    width: 70px;
    animation: mailing-progress-bar 1.5s ease infinite forwards;
  }
}

.Content {
  margin-top: auto;
  display: flex;
  flex-direction: column;
  row-gap: 12px;
}