@import '../../../../../shared/ui-kit-2/_constants/colors.scss';
@import "../../../../../app/global-styles/vars";

.Container {
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  height: 848px;
  margin-top: 16px;
  overflow: hidden;

  @media screen and (min-width: $landing-screen-laptop-l) {
    .VideoContainer {
      display: flex;
      justify-content: center;
    }

    .Video {
      width: auto;
    }
  }

  @media screen and (min-width: $landing-screen-laptop) and (max-width: $landing-screen-laptop-l) {
    height: 650px;

    .Wrapper {
      width: 1058px;
      grid-template-columns: calc(55% - 10px) calc(45% - 10px);
    }

    .Title {
      font-size: 77px;
      letter-spacing: -1px;
    }

    .Info {
      font-size: 16px;
    }
  }
  
  @media screen and (max-width: $landing-screen-laptop) {
    height: 189rem;
    background: $page-wrapper;

    .Wrapper {
      width: 100%;
      grid-template-columns: 100%;
      grid-gap: 0;
      padding-left: 4.25rem;
      padding-right: 4.25rem;
      padding-bottom: 0;
      background: $page-wrapper;
    }

    .Title {
      margin-bottom: 6.38rem;
      font-size: 10.638rem;
    }

    .Info {
      font-size: 3.723rem;
    }

    .Mask {
      height: 198.3rem;
      -webkit-backface-visibility: hidden;
      -moz-backface-visibility: hidden;
    }

    .Actions {
      grid-gap: 2.4rem;
      margin-top: 6.38rem;
    }

    .VideoContainer {
      width: 200%;
      height: 100%;
      top: -19%;
      left: -50%;
    }

    .Video {
      top: 18%;
      object-fit: contain;
    }
  }
}

.VideoContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
}

.Video {
  position: absolute;
  width: 100%;
  height: 100%;
  top: -1%;
  object-fit: fill;
  overflow: hidden;
  opacity: 1;
  background: none;
}

.Mask {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background:
    linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 68.85%, #EBEBF5 81.12%),
    linear-gradient(90deg, #EBEBF5 0%, rgba(255, 255, 255, 0) 50%, #EBEBF5 100%);
}

.Wrapper {
  display: grid;
  grid-template-columns: 60% 35%;
  align-items: center;
  grid-gap: 20px;
  width: 1280px;
  margin: 0 auto;
  z-index: 1;
}

.Title {
  color: $dark-text;
  font-family: Gilroy, sans-serif;
  font-size: 87px;
  font-style: normal;
  font-weight: 700;
  line-height: 1;
}

.Info {
  color: $dark-text;
  font-family: Rubik, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
}

.Actions {
  display: flex;
  grid-gap: 13px;
  margin-top: 27px;
}