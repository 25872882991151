@import '../../../../shared/ui-kit-2/_constants/typography.scss';
@import '../../../../shared/ui-kit-2/_constants/colors.scss';

.ApiPageCard {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  padding: 32px 24px;
  background: $white-bg;

  &__second {
    margin-top: 16px;
  }
}

.Title {
  @include font-gilroy-24;
  color: $dark-text;

}
:global {

  .information-container {
    display: none!important;
  }
  .swagger-ui {
    background: white !important;
    padding: 16px !important;
    border-radius: 20px;
  }
}