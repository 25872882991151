@import '../../../../../../../../shared/ui-kit-2/_constants/typography.scss';
@import '../../../../../../../../shared/ui-kit-2/_constants/colors.scss';
@import '../../../../../../../../app/global-styles/vars.scss';

.AutoRenewalWrapper {
  display: flex;
  flex-direction: column;
  row-gap: 25px;
  padding: 32px 95px 32px 32px;

  @media (max-width: $lk-mobile-l) {
    padding: 24px 16px;
    width: 100%;
    white-space: nowrap;
  }
}

.Title {
  @include font-gilroy-24;
  color: $dark-text;
}

.AutoRenewalSwitchers {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  row-gap: 18px;
  flex-wrap: wrap;

  @media (max-width: $lk-mobile-l) {
    align-items: start;
    justify-content: start;
    column-gap: 18px;
  }
}