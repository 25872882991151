@import "../../_constants/typography";
@import "../../_constants/colors";
@import "../../_constants/transition-mixins";

.Container {
  display: flex;
  flex-direction: row;
  column-gap: 5px;
  align-items: flex-start;
  max-width: max-content;
  width: 100%;
}

.Checkbox {
  cursor: pointer;
  margin: 6px;
  min-width: 18px;
  width: 18px;
  height: 18px;
  background: $bg-gray;
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  @include transition-default(all);

  &_outlined {
    background: $white-bg;
    border: 1px solid $border-gray;
  }
}

.CheckedIcon {
  opacity: 0;
}

.HiddenInput {
  display: none;

  &:checked ~ .Checkbox {
    background: $green;

    .CheckedIcon {
      opacity: 1;
    }
  }

  &:disabled ~ .Checkbox {
    background: $bg-gray;
    cursor: default;
  }

  &:checked:disabled ~ .Checkbox {
    background: $light-green;
  }
}

.LabelWrapper {
  display: flex;
  flex-direction: column;
  row-gap: 2px;
  user-select: none;
  word-break: break-word;
}

.Label {
  @include font-rubik-14;
  margin-top: 6.5px;
  color: $dark-text;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;;

  & > span {
    color: $red-alert;
  }

  &__subtext {
    color: $text-gray;
    margin-right: 8px;
  }
}

.Error {
  @include font-rubik-12;
  color: $red-alert;
}