@import "../../../shared/ui-kit-2/_constants/colors";
@import "../../../shared/ui-kit-2/_constants/typography";

.Container {
    display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  margin-top: 39px;
}

.Content {
  margin-top: auto;
  display: flex;
  flex-direction: column;
}

.Title {
  margin-bottom: 24px;
  @include font-gilroy-48;
  color: $dark-text;
}

.Description {
  margin-bottom: 32px;
  @include font-rubik-16;
  color: $dark-text;
}

.Buttons {
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  width: 100%;
}

.Bin {
  margin-top: auto;
}

