@import '../../../../shared/ui-kit-2/_constants/colors.scss';
@import "../../../../app/global-styles/vars";

.Container {
  @media screen and (min-width: $landing-screen-laptop) and (max-width: $landing-screen-laptop-l) {
    .Title {
      font-size: 77px;
      line-height: 94.2%;
      letter-spacing: -1px;
    }
  }

  @media screen and (max-width: $landing-screen-laptop) {
    .Title {
      max-width: 100%;
      margin-bottom: 16rem;
      font-size: 10.6rem;
    }

    .Slider {
      margin-top: 0;
      padding-left: 0;
    }

    .Actions {
      grid-gap: 2.13rem;
      justify-content: center;
      margin-top: 8.5rem;
      margin-right: 0;
    }

    .Action {
      min-width: 11.43rem;
      min-height: 11.43rem;
      width: 11.43rem;
      height: 11.43rem;
      border-radius: 4rem;

      img, svg {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.Title {
  position: relative;
  max-width: 775px;
  font-family: Gilroy, sans-serif;
  font-size: 87px;
  font-style: normal;
  font-weight: 700;
  z-index: 1;
}

.Slider {
  position: relative;
  display: flex;
  grid-gap: 32px;
  margin-top: 76px;
  transition: 0.3s;
}

.Header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.Actions {
  position: relative;
  display: flex;
  grid-gap: 9px;
  margin-right: 50px;
}

.Action {
  width: 55px;
  height: 55px;
  min-width: 55px;
  min-height: 55px;
  border-radius: 21px;
  transition: 0.3s;

  svg, img {
    width: 70%;
    height: 70%;
  }

  &:hover {
    opacity: 0.7;
  }
}