@import "../../../../shared/ui-kit-2/_constants/colors";
@import '../../../../app/global-styles/vars.scss';

.Container {
  display: flex;
  align-items: center;
  justify-content: center;;
  width: 100%;
  height: var(--app-height);
  background: $bg-gray-2;
  position: relative;

  @media (max-width: $lk-mobile-l) {
    width: 100%;
    max-width: 343px;
    height: max-content;
    margin: 32px 0 0 0 ;
    z-index: 10;
  }
}