@import "../../../../../../shared/ui-kit-2/_constants/colors";
@import "../../../../../../shared/ui-kit-2/_constants/typography";

.TextAction {
  background: transparent;
  border: none;
  cursor: pointer;
  color: $green;
  transition: 0.3s;
  @include font-rubik-14;

  &:not(&[disabled]):hover {
    opacity: 0.7;
  }

  &[disabled] {
    color: $text-gray;
    cursor: default;
  }
}