@import '../../../../../../../../../shared/ui-kit-2/_constants/colors.scss';
@import '../../../../../../../../../shared/ui-kit-2/_constants/typography.scss';

.ContactCardContainer {
  display: flex;
  flex-direction: row;
  background: $sidebar-link-active;
  width: 100%;
  height: 74px;
  border-radius: 20px;
  padding: 16px;
  justify-content: space-between;
  align-items: center;
}

.CardInfo {
  display: flex;
  flex-direction: column;
  row-gap: 6px;
}

.CardName {
  color: $dark-text;
  @include font-gilroy-16;
  line-height: 19px;
}

.CardCountContainer {
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  align-items: center;
}

.CardTitle {
  color: $dark-text;
  @include font-rubik-14;
}

.CardCounter {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1px 4px;
  background: $badge-gray;
  color: $dark-text;
  @include font-rubik-12;
  border-radius: 43px;
}

.CardActions {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.DetailsBtn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 13px;
  @include font-rubik-14;
  border: none;
  background: $white;
  align-items: center;
  padding: 9px;
  cursor: pointer;
  width: 34px;
  height: 34px;

  svg {
    width: 16px;
    height: 16px;
  }
}