.error {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  align-items: center;
  justify-content: flex-start;
  color: #D22F27;
  font-family: Gilroy, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}