@import "../../_constants/typography";
@import "../../_constants/colors";
@import "../../_constants/transition-mixins";

.GroupContainer {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.Container {
  display: flex;
  flex-direction: row;
  column-gap: 5px;
  align-items: flex-start;
  max-width: max-content;
  width: 100%;
}

.Radio {
  cursor: pointer;
  position: relative;
  margin: 7px;
  min-width: 16px;
  width: 16px;
  height: 16px;
  background: $bg-gray;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  @include transition-default(all);

  &_outlined {
    border: 1px solid $border-gray;
    background: $white-bg;
  }
}

.RadioChecked {
  opacity: 0;
  background: $green;
  border-radius: 100%;
  width: 10px;
  height: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.HiddenInput {
  display: none;

  &:checked ~ .Radio {
    .RadioChecked {
      opacity: 1;
    }
  }

  &:disabled ~ .Radio {
    cursor: default;
  }

  &:checked:disabled ~ .Radio {
    .RadioChecked {
      background: $light-green;
    }
  }
}

.LabelWrapper {
  display: flex;
  flex-direction: column;
  row-gap: 2px;
  user-select: none;
  word-break: break-word;
}

.Label {
  @include font-rubik-14;
  margin-top: 6.5px;
  color: $dark-text;
  display: flex;
  flex-direction: row;
  column-gap:  8px;
  align-items: center;
  justify-content: flex-start;
}

.SubLabel {
  color: $text-gray;
}

.Error {
  @include font-rubik-12;
  color: $red-alert;
}