@import '../../../shared/ui-kit-2/_constants/colors.scss';

.Container {
  width: max-content;
  height: max-content;
  position: relative;
}

.LoadingContainer {
  opacity: 0.3;
}

.HasFilters {
  position: absolute;
  top: -1px;
  right: -4px;
  height: 12px;
  min-height: 12px;
  width: 12px;
  min-width: 12px;
  box-sizing: content-box;
  border-radius: 50%;
  background: $green;
  border: 4px solid $white-bg;
  display: none;

  &_active {
    display: flex;
  }
}