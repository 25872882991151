@import '../../../../_constants/colors.scss';
@import '../../../../_constants/typography.scss';

.Container {
  display: flex;
  flex-direction: row;
  column-gap: 3px;
  @include font-gilroy-12;
  color: $dark-text;
  align-items: center;
  position: relative;
  justify-content: space-between;
}

.ContainerIsOpen {
  svg {
    transform: rotateZ(180deg);
  }
}

.DropDownContainer {
  width: 99px;
  position: absolute;
  right: 0;
  top: -118px;
  border-radius: 20px;
  box-shadow: 0 6px 24.2px 0 rgba(0, 0, 0, 0.07);
  z-index: 999;
  padding: 16px 8px 16px 16px;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  justify-content: space-between;
  background: $white-bg;
}

.DropDownOption {
  flex-direction: row;
  align-items: center;
  @include font-rubik-16;
  color: $dark-text;
  display: flex;
  height: 23px;
  width: 100%;
  justify-content: space-between;
}