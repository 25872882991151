@import '../../../../app/global-styles/vars.scss';

.Container {
  max-width: 1248px;
  width: 100%;
  min-height: 646px;
  display: grid;
  grid-template-columns: 1fr 286px;
  grid-gap: 16px;
  justify-content: center;
  margin: 0 auto;

  @media (max-width: $lk-mobile-xl) {
    display: flex;
    flex-direction: column;
    grid-gap: 8px;
    min-height: auto;
  }
}