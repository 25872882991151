@import "../../../../../../../../../../shared/ui-kit-2/_constants/typography";
@import "../../../../../../../../../../shared/ui-kit-2/_constants/colors";
@import "../../../../../../../../../../shared/ui-kit-2/_constants/transition-mixins";

.Card {
  padding: 16px;
  border-radius: 20px;
  border: 1px solid $border-gray;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.Header {
  display: flex;
  flex-direction: row;
  column-gap:  8px;
  justify-content: space-between;
  align-items: center;
}

.Title {
  @include font-gilroy(20px, 125%, 600);
  color: $dark-text;
}

.InnerHeader {
  @include font-rubik(14px, 120%, 600);
  color: $dark-text;
  display: flex;
  flex-direction: row;
  column-gap:  8px;
  justify-content: space-between;
  align-items: center;

  & > div {
    &:last-child {
      @include font-gilroy-14;
    }
  }
}

.InnerBody {
  @include font-rubik(14px, 120%, 600);
  color: $dark-text;
  display: flex;
  flex-direction: row;
  column-gap:  8px;
  justify-content: space-between;
  align-items: center;
}

.EditTextBtn {
  @include font-rubik(14px, 120%, 400);
  padding: 0;
}

.InnerCardsContainer {
  display: flex;
  flex-direction: column;
  row-gap: 7px;
  margin-top: auto;
}

.InnerCard {
  position: relative;
  background: $sidebar-link-active;
  border-radius: 15px;
  padding: 16px 13px;
    display: flex;
  flex-direction: column;
  row-gap: 8px;

  &:first-child {
    margin-top: 8px;
  }
}

.InnerCardTitle {
  display: flex;
  flex-direction: row;
  column-gap: 5px;
  align-items: center;
  justify-content: center;
  @include font-rubik(14px, 120%, 600);
  color: $dark-text;
}

.ForwardTo {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 15px);
  z-index: 2;
}