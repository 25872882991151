@import '../../../../../shared/ui-kit-2/_constants/typography.scss';
@import '../../../../../shared/ui-kit-2/_constants/colors.scss';

.WifiWrapper {
  position: relative;
  display: flex;
  grid-area: wifi;
  flex-direction: column;
  height: 100%;
}

.Card {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 24px 16px;
  border-radius: 20px;
  border: 1px solid $border-gray;
  background: $white-bg;
}

.Offline {
  pointer-events: none;
  opacity: 30%;
}

.Header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.Title {
  font-family: Gilroy, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  z-index: 3;
}

.WifiSettings {
  display: flex;
  width: 37px;
  margin-left: 10px;
  color: $text-gray;
  background: none;
  border: none;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    opacity: 0.7;
  }
}

.WifiSwitcher {
  margin-left: auto;
}

.Divider {
  width: 100%;
  height: 1px;
  margin: 21px 0;
  background: $border-gray;
}

.Cols {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  grid-gap: 10px;
}

.Col {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.Label {
  margin-bottom: 8px;
  color: $text-gray;
  font-family: Rubik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.2;
}

.Value {
  word-break: break-all;
  color: $dark-text;
  font-family: Gilroy, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

.OfflineWifi {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  row-gap: 8px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  > div {
    color: $danger;
    width: 191px;
    @include font-rubik-14;
  }
}

.ErrorSubscription {
  opacity: 0.3;
  pointer-events: none;
}