@import '../../../../../../../../../../shared/ui-kit-2/_constants/colors.scss';
@import '../../../../../../../../../../shared/ui-kit-2/_constants/typography.scss';

.LegalAddressWrapper {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}

.Title {
  @include font-gilroy-16;
  color: $dark-text;
  margin: 16px 0 8px 0;
}

.RowItems {
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  width: 100%;
}

.FullWidthItem {
  width: 100%;
}