@import "../../../../app/global-styles/vars";

.Cols {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 16px;

  @media (max-width: $lk-tablet) {
    grid-template-columns: 1fr;
  }

  @media (max-width: $lk-mobile-l) {
    margin-bottom: 39px;
    display: flex;
    flex-direction: column;
  }
}

.Col {
  display: flex;
  flex-direction: column;
}