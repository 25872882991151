@import '../../../shared/ui-kit-2/_constants/colors.scss';
@import '../../../shared/ui-kit-2/_constants/typography.scss';

.Content {
  display: flex;
  flex-direction: column;
  row-gap: 32px;
  width: 100%;
  flex-grow: 1;
}

.InputPostfix {
  font: inherit;
  color: $dark-text;
}

.RadioGroupWrapper {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  width: 100%;

  &__title {
    color: $dark-text;
    @include font-gilroy-27;
  }
}

.RadioButtons {
  display: flex;
  flex-direction: row;
  column-gap: 16px;
}

.BankCards {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  width: 100%;
}

.BankCardNewWrapper {
  position: relative;
}

.BankCardIcon {
  transform: translate(21px, -50%);
  position: absolute;
  top: 50%;
  width: 39px;
  height: 29px;
  z-index: 0;
  pointer-events: none;
}

.WireMethod {
  display: flex;
  flex-direction: column;
  row-gap: 34px;
  align-items: center;
  justify-content: center;
  height: 324px;
  background: $sidebar-link-active;
  border-radius: 30px;
  width: 100%;

  &__title {
    @include font-rubik-16;
  }
}

.RadioButtonBankCard {
  label {
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    background: $sidebar-link-active;
    padding: 24px 21px;
    border-radius: 16px;
    cursor: pointer;
    max-width: 100%;

    div {
      display: flex;
      @include font-gilroy-27;
      color: $dark-text;
    }
  }
}

.RadioButtonBankCardNew {
  display: flex;
  position: relative;
  flex-direction: row-reverse;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  background: $sidebar-link-active;
  padding: 24px 21px;
  border-radius: 16px;
  cursor: pointer;
  max-width: 100%;

  div:nth-of-type(2) {
    margin-left: 58px;

    div {
      display: flex;
      @include font-gilroy-27;
      color: $dark-text;
      margin-top: 0;
    }
  }
}

.SubmitButton {
  margin-top: auto;
}