@import '../../../shared/ui-kit-2/_constants/colors.scss';
@import '../../../shared/ui-kit-2/_constants/typography.scss';

.Actions {
  display: flex;
  justify-content: space-between;
  grid-gap: 8px;

  button {
    height: 60px;
    margin: 24px 0 47px;
  }
}

.ProfileFormGrid {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.Title {
  @include font-gilroy-16;
  color: $dark-text;
}

.RowItems {
  display: flex;
  width: 100%;
  flex-direction: row;
  column-gap: 8px;
  justify-content: space-between;
}

.ItemFieldCustom {
  width: 100%;
}

.CheckBoxLegal {
  margin: 16px 0;
}