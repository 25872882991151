@import "../../app/global-styles/vars";
@import "../../shared/ui-kit-2/_constants/typography";
@import "../../shared/ui-kit-2/_constants/colors";
@import "../../shared/ui-kit-2/_constants/transition-mixins";

.Container {
  background: $bg-gray-2;
  width: 100%;
  min-height: var(--app-height);
  padding: 123px 16px 105px 16px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: $lk-mobile-l) {
    padding-top: 80px;
  }
}

.Content {
  width: 100%;
  max-width: $screen-xl;
}

.Logo {
  position: absolute;
  top: 32px;
  left: 65px;
  width: 119px;
  height: 40px;
  cursor: pointer;

  @media (max-width: $lk-mobile-l) {
    display: none;
  }
}