
@import "../../../shared/ui-kit-2/_constants/colors";

.FAQ {
  width: 1280px;
  margin: 140px auto 0;
}

.Head {
  padding-bottom: 12px;
  border-bottom: 1px solid $border-gray;
}

.Title {
  color: $dark-text;
  font-family: 'Gilroy', sans-serif;
  font-size: 60px;
  font-weight: 700;
  line-height: 1;
}

.Body {
  display: flex;
  justify-content: space-between;
}