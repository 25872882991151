@import "../../../shared/ui-kit-2/_constants/colors";
@import "../../../shared/ui-kit-2/_constants/typography";

.DrawerAdditional {
  width: 100%;
  position: relative;
}

.Container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.Actions {
  margin-top: auto;
  display: flex;
  flex-direction: row;
  column-gap: 8px;
}

.ReplyBtnAdditional {
  flex-grow: 1;
}

.ContainerSmsc {
  padding: 10px 0;
  border: 1px solid $border-gray;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  row-gap: 3px;
  width: 100%;
  align-items: center;
  justify-content: center;
  @include font-rubik-18;
  color: $dark-text;
}

.Title {
  @include font-rubik-16;
  color: $text-gray;
}