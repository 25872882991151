@import '../../../../shared/ui-kit-2/_constants/colors.scss';
@import '../../../../shared/ui-kit-2/_constants/typography';

.Container {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  width: 100%;
}

.InputContainer {
  position: relative;
  width: 100%;
}

.Label {
  @include font-rubik-14;
  color: $dark-text;
  height: 16.8px;
}

.Datepicker {
  display: flex;
  width: 100%;
  padding: 21px 70px 21px 20px;
  @include font-rubik-14;
  border-radius: 20px;
  background: transparent;
  border: 1px solid $border-gray;
  color: $dark-text;
  outline: none;
  cursor: text !important;
  text-overflow: ellipsis;

  &:hover {
    border: 1px solid $green;
  }

  &::placeholder {
    color: $text-gray;
  }

  &__error {
    border: 1px solid $red-alert;
  }
}

.NextPrevButtonsWrapper {
  width: 58px;
  height: 24px;
  position: absolute;
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  top: 80px;
  left: 237px;
  z-index: 9999999;

  svg {
    cursor: pointer;
  }
}

.Icons {
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  top: 50%;
  transform: translateY(-50%);
  right: 21px;
  column-gap: 8px;

  & > svg {
    cursor: pointer;
  }

  &_calendar {
  }

  &_clear {
    width: 16px;
    height: 16px;
  }
}

:global {
  .flatpickr-calendar {
    border-radius: 20px 20px 20px 20px !important;
    //top: 83px !important;
    padding: 20px 20px 10px 20px !important;
    width: 347px !important;
    background: $white;

    &::before {
      content: '';

    }
  }

  .flatpickr-months {

  }

  .flatpickr-month {
    background: $white !important;
    height: 50px !important;
    width: 100% !important;
    border-radius: 20px 20px 0 0 !important;
  }

  .flatpickr-current-month {
    display: flex !important;
    width: 100% !important;
    box-sizing: border-box !important;
    padding: 10px !important;
    position: static !important;
    flex-direction: row !important;
    column-gap: 10px !important;
    border-radius: 20px 20px 0 0 !important;
    height: auto !important;
    align-items: center !important;
  }

  .flatpickr-monthDropdown-months {
    background: $white !important;
    display: flex !important;
    padding: 0 !important;
    width: max-content !important;
    font-family: Gilroy, sans-serif !important;
    font-size: 24px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: 111% !important;
    color: $dark-text !important;
    margin: 0 !important;
    height: 30px !important;
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    appearance: none !important;
  }

  .numInputWrapper {
    font-family: Gilroy, sans-serif !important;
    font-size: 24px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: 100% !important;
    color: $dark-text !important;
    border-radius: 0 !important;

    span {

    }
  }

  .numInput {

  }

  .cur-year {
    font-family: Gilroy, sans-serif !important;
    font-size: 24px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: 100% !important;
    color: $dark-text !important;
    padding: 0 !important;
    display: flex !important;
    background: $white !important;
  }

  .numInputWrapper .arrowUp {
    background: $white !important;
    border: none !important;
    height: 12px !important;
    padding: 0 !important;
    top: 3px !important;

    &::after {
      content: '';
      border-left: 4px solid transparent !important;
      border-right: 4px solid transparent !important;
      border-bottom: 4px solid $dark-text !important;
      top: 26%;
    }
  }

  .arrowUp {
    background: $white !important;
    border: none !important;
    height: 12px !important;
    padding: 0 !important;
    top: 10px !important;

    &::after {
      content: '';
      border-left: 4px solid transparent !important;
      border-right: 4px solid transparent !important;
      border-bottom: 4px solid $dark-text !important;
      top: 26%;
    }
  }

  .arrowDown {
    background: $white !important;
    border: none !important;
    height: 12px !important;
    padding: 0 !important;

    &::after {
      content: '';
      border-left: 4px solid transparent !important;
      border-right: 4px solid transparent !important;
      border-top: 4px solid $dark-text !important;
      top: 40%;
    }
  }

  .flatpickr-innerContainer {

  }

  .flatpickr-rContainer {

  }

  .flatpickr-weekdays {

  }

  .flatpickr-weekdaycontainer {
    font-family: Rubik, sans-serif !important;
    font-size: 18px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 120% !important;
    color: $dark-text !important;
  }

  .flatpickr-weekday {

  }

  .flatpickr-days {

  }

  .dayContainer {
    border-bottom: 1px solid $border-gray !important;
  }

  .selected {
    background: $green !important;
    border: none !important;
    color: $white !important;
    border-radius: 100% !important;
    filter: drop-shadow(0px 2px 3px rgba(11, 15, 40, 0.26)) !important;
  }

  .today {
    border: 1px solid $green !important;
  }

  .nextMonthDay, .prevMonthDay {
    background: transparent !important;
    border: none !important;
    color: $border-gray !important;
  }

  .flatpickr-weekdays {
    background: $white !important;
  }

  .flatpickr-weekday {
    background: $white !important;
  }

  .flatpickr-days {
    border: none !important;
  }

  .flatpickr-time {
    border: none !important;
    border-radius: 0 0 20px 20px !important;
  }

  .flatpickr-next-month {
    top: 35px !important;
    height: 19px !important;
    width: 19px !important;
    padding: 0 !important;
    right: 0 !important;
    left: 90% !important;

    svg {
      height: 19px !important;
      width: 19px !important;
      fill: $dark-text !important;
    }

    &:hover {

      svg {
        fill: $dark-text !important;
      }
    }
  }

  .flatpickr-prev-month {
    top: 35px !important;
    height: 19px !important;
    width: 19px !important;
    padding: 0 !important;
    right: 0 !important;
    left: 77% !important;

    svg {
      height: 19px !important;
      width: 19px !important;
      fill: $dark-text !important;
    }

    &:hover {

      svg {
        fill: $dark-text !important;
      }
    }
  }

  .flatpickr-hour {
    background: $white !important;
    color: $dark-text !important;
    font-family: Rubik, sans-serif !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 120% !important;

    &:hover {
      background: $white !important;
    }

    &:focus {
      background: $white !important;
    }
  }

  .flatpickr-minute {
    background: $white !important;
    color: $dark-text !important;
    font-family: Rubik, sans-serif !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 120% !important;

    input ~ span {
      width: 100px !important;

    }

    &:hover {
      background: $white !important;
    }

    &:focus {
      background: $white !important;
    }
  }

  .flatpickr-second {
    background: $white !important;
    color: $dark-text !important;
    font-family: Rubik, sans-serif !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 120% !important;

    &:hover {
      background: $white !important;
    }

    &:focus {
      background: $white !important;
    }
  }

  .flatpickr-time-separator {
    color: $dark-text !important;
    font-family: Rubik, sans-serif !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 120% !important;
    align-items: center !important;
    display: flex !important;
  }

  .arrowTop {

    &::before {
      border-bottom-color: $white !important;
    }

    &::after {
      border-bottom-color: $white !important;
    }
  }

  .flatpickr-day {
    font-family: Rubik, sans-serif !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 120% !important;
    display: flex !important;
    align-items: center !important;
    width: 43px !important;
    height: 43px !important;
    max-width: 43px !important;

    &:hover {
      background: $bg-gray !important;
      border: none !important;
      color: $text-gray !important;
    }
  }

  .flatpickr-disabled {
    display: flex !important;

    &:hover {
      background: $white !important;
      color: rgba(72, 72, 72, 0.1) !important;
    }

    svg {
      fill: $bg-gray !important;

      &:hover {
        fill: $bg-gray !important;
      }
    }
  }

  .flatpickr-day.prevMonthDay.flatpickr-disabled {
    color: rgba(72, 72, 72, 0.1) !important;;
  }

  .flatpickr-day.selected {
    &:hover {
      color: $white !important;
      background: $green !important;
    }
  }
}
