@import "../../../../../../../../../../shared/ui-kit-2/_constants/typography";
@import "../../../../../../../../../../shared/ui-kit-2/_constants/colors";
@import "../../../../../../../../../../shared/ui-kit-2/_constants/transition-mixins";

.Card {
  padding: 16px;
  border-radius: 20px;
  border: 1px solid $border-gray;
}

.Header {
  display: flex;
  flex-direction: row;
  column-gap:  8px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;

  > svg {
    min-height: 16px;
    min-width: 16px;
  }
}

.Title {
  @include font-gilroy(20px, 125%, 600);
  color: $dark-text;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Delete {
  cursor: pointer;
  @include transition-default(opacity);

  &:hover {
    opacity: 0.7;
  }
}

.Message {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  padding: 16px 14px;
  background: $sidebar-link-active;
  border-radius: 15px;
  color: $dark-text;

  &__title {
    opacity: 0.8;
    @include font-rubik(14px, 125%, 600);
  }

  &__text {
    @include font-rubik-14;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.Bottom {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  column-gap: 16px;
  justify-content: space-between;
  align-items: center;
}

.Devices {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  column-gap: 8px;
  @include font-rubik(14px, 120%, 600);
  color: $dark-text;
}

.MoreButton {
  background: $sidebar-link-active;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 9px;
  border-radius: 13px;
  cursor: pointer;
  @include transition-default(opacity);

  &:hover {
    opacity: 0.7;
  }
}