@import '../../../../../shared/ui-kit-2/_constants/colors.scss';
@import "../../../../../app/global-styles/vars";

.Container {
  position: relative;
  width: 100%;
  min-height: 950px;
  padding: 124px 45px;
  background-color: $page-wrapper;
  border-radius: 60px;
  z-index: 1;
  overflow: hidden;

  @media screen and (min-width: $landing-screen-laptop) and (max-width: $landing-screen-laptop-l) {
    .Wrapper {
      width: 1058px;
      flex-direction: column;
      justify-content: center;
    }

    .Title {
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 29px;
      text-align: center;
    }

    .Button {
      width: 410px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 70px;
    }

    .FaqList {
      margin: 0 auto;
    }
  }

  @media screen and (max-width: $landing-screen-laptop) {
    min-height: auto;
    padding: 0 calc(16rem / $rem-mobile) calc(64rem / $rem-mobile);
    border-radius: calc(38rem / $rem-mobile);

    .Wrapper {
      flex-direction: column;
      width: 100%;
    }

    .Title {
      max-width: 100%;
      margin-bottom: calc(24rem / $rem-mobile);
      font-size: calc(40rem / $rem-mobile);
    }

    .Button {
      width: 100%;
    }

    .FaqList {
      width: 100%;
      margin-top: calc(32rem / $rem-mobile);
    }
  }
}

.Background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  opacity: 0.3;

  img {
    width: 100%;
    height: 100%;
  }
}

.Wrapper {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 1280px;
  margin: 0 auto;
  z-index: 1;
}

.Title {
  max-width: 508px;
  margin-bottom: 32px;
  color: $dark-text;
  font-family: Gilroy, sans-serif;
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
}

.Button {
  width: 410px;
}

.FaqList {
  width: 731px;
}