@import "../../../../../shared/ui-kit-2/_constants/typography";
@import "../../../../../shared/ui-kit-2/_constants/colors";

.SimpleText {
  @include font-gilroy-14;
  color: $dark-text;
}

.Device {
  display: flex;
  flex-direction: column;
  row-gap: 3px;

  &__name {
    @include font-gilroy(12px, 120%, 500);
    color: $green;
    width: 110px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__phone {
    @include font-gilroy-14;
    color: $dark-text;
  }
}

.SimpleTextWithRightBorder {
  height: 34px;
  display: flex;
  align-items: center;
  @include font-gilroy-14;
  color: $dark-text;
  border-right: 1px solid $border-gray;
  flex-grow: 1;
  margin-right: 8px;
}

.MessageText {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 183px;
  white-space: nowrap;
}

.Actions {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  column-gap: 8px;

  svg {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}