
@import "../../../../../shared/ui-kit-2/_constants/colors";

.FAQArticles {
  display: flex;
  flex-direction: column;
  grid-gap: 24px;
  width: calc(100% - 450px);
  margin-left: auto;
  padding: 12px 36px 36px;
  border-left: 1px solid $border-gray;

  &_ru {
    width: 100%;
    padding-left: 0;
    border-left: none;
  }
}

.Article {
  * {
    font-family: Rubik, sans-serif;
    color: $dark-text;
  }

  h1, h2, h3, h4, h5, h6 {
    margin: 24px 0;
    font-family: Gilroy, sans-serif;
    line-height: 1;
  }

  h1 {
    font-size: 48px;
    font-weight: 700;
  }

  h2 {
    font-size: 36px;
    font-weight: 700;
  }

  h3 {
    font-size: 28px;
    font-weight: 500;
  }

  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
  }

  blockquote {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      width: 4px;
      height: 100%;
      left: -20px;
      background: $green-dark;
      border-radius: 8px;
    }

    p {
      margin-top: 4px;
      margin-bottom: 4px;
    }
  }

  ul, ol {
    display: flex;
    flex-direction: column;
    grid-gap: 8px;
    margin-top: 24px;
    padding-left: 12px;

    p {
      margin: 0;
    }
  }

  img {
    max-width: 100%;
  }
}