@import "../../../../../../shared/ui-kit-2/_constants/typography";
@import "../../../../../../shared/ui-kit-2/_constants/colors";
@import '../../../../../../app/global-styles/vars.scss';

.CardAdditional {
  overflow: hidden;
  position: relative;
  min-height: calc(100vh - 58px - 120px);
  margin-bottom: -114px;

  @media (max-width: $lk-mobile-l) {
    min-height: calc(100vh - 223px);
    margin-bottom: 0;
  }
}

.CardHeader {
  padding: 24px 24px 16px 24px;
  display: flex;
  flex-direction: row;
  column-gap: 16px;
  justify-content: space-between;
  align-items: center;
}

.Title {
  @include font-gilroy-24;
  color: $dark-text;
}

.HeaderNavigation {
  display: flex;
  flex-direction: row;
  column-gap:  8px;
}

.CreateButtonAdditional {
  font-size: 14px;
  padding: 0 32px 0 24px;
}