
@import "../../../../../shared/ui-kit-2/_constants/colors";

.FAQNavigation {
  position: fixed;
  top: 220px;
  display: flex;
  flex-direction: column;
  grid-gap: 36px;
  width: 450px;
  max-height: calc(100vh - 112px);
  padding: 42px 12px 200px 0;
  overflow-y: scroll;

  a {
    text-decoration: none;
  }
}

.NavItem {
  position: relative;

  &_current {
    &:before {
      content: '';
      position: absolute;
      top: 8px;
      left: -60px;
      width: 52px;
      height: 4px;
      background: $green-dark;
    }
  }
}

.Title {
  font-family: Gilroy, sans-serif;
  font-size: 22px;
  font-weight: 700;
  line-height: 1;
}

.SubItems {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  margin-left: 16px;
  grid-gap: 16px;
}

.SubTitle {
  font-family: Gilroy, sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 1;
}

.Title, .SubTitle {
  color: $dark-text;
  transition: 0.3s;
  cursor: pointer;

  &:hover {
    opacity: 0.6;
  }
}