@import "../../../../../../../../app/global-styles/vars";
@import '../../../../../../../../shared/ui-kit-2/_constants/colors.scss';
@import '../../../../../../../../shared/ui-kit-2/_constants/typography.scss';

.Balance {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 184px;
  padding: 28px 0 23px 32px;
  position: relative;
  opacity: 0.3;
  pointer-events: none;

  @media (max-width: $lk-mobile-l) {
    padding: 16px;
    background-size: 160px;
    height: 137px;
    .Label {
      display: inline-block;
      font-size: 20px;
    }

    .Counter {
      margin-left: 7px;
    }

    .Action {
      width: 115px;
    }

    .Money {
      display: block;
    }
  }
}

.Money {
  display: flex;
  font-family: Gilroy, sans-serif;
  font-size: 27px;
  font-style: normal;
  font-weight: 700;
}

.Counter {
  display: inline-flex;
  margin-left: 16px;
}

.Action {
  max-width: 260px;

  button {
    height: 48px;
    pointer-events: none;
  }
}

.DevelopInfo {
  position: absolute;
  background: $green;
  border-radius: 20px;
  padding: 5.5px 8px;
  right: 15px;
  color: $white;
  @include font-gilroy-12;
  top: 24px;

  @media (max-width: $lk-mobile-l) {
    top: 16px;
  }
}

.BalanceIcon {
  position: absolute;
  width: 274px;
  height: 304px;
  right: -63px;
  top: 0;

  @media (max-width: $lk-mobile-l) {
    width: 117px;
    height: 137px;
    right: 30px;
  }
}