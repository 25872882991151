@import "../../_constants/typography";
@import "../../_constants/colors";
@import "../../_constants/transition-mixins";
@import "../../../../app/global-styles/vars.scss";

.Container {
  display: flex;
  flex-direction: row;

  @media screen and (max-width: $lk-mobile-l) {
    margin: 0 auto;
  }
}

.Step {
  display: flex;
  flex-direction: column;
  row-gap: 6px;
  align-items: center;
  justify-items: center;
  cursor: default;
  position: relative;

  &_active {
    cursor: pointer;
  }

  &_disabled {
    cursor: default;
  }
}

.StepLine {
  position: absolute;
  top: 6px;
  left: 0;
  width: 100%;
  height: 2px;
  background: $light-green;
  @include transition-default(all);

  &_first {
    width: 50%;
    left: 50%;
  }

  &_last {
    width: 50%;
    left: unset;
    right: 50%;
  }

  &_active {
    background: $green;
  }
}

.FirstHalfLine {
  margin-top: 6px;
  height: 2px;
  width: 63px;
  background: $light-green;
  @include transition-default(all);

  &_last {
    display: none;
  }

  &_active {
    background: $green;
  }
}

.SecondHalfLine {
  margin-top: 6px;
  height: 2px;
  width: 63px;
  background: $light-green;
  @include transition-default(all);

  &_last {
    display: none;
  }

  &_active {
    background: $green;
  }
}

.StepCircle {
  min-width: 14px;
  width: 14px;
  min-height: 14px;
  height: 14px;
  border-radius: 100%;
  background: $light-green;
  @include transition-default(all);

  &_active {
    background: $green;
  }
}

.StepLabel {
  @include font-rubik-12;
  color: $dark-text;
}