@import "../../../shared/ui-kit-2/_constants/colors";
@import "../../../shared/ui-kit-2/_constants/typography";

.DrawerAdditional {
  width: 100%;
}

.Container {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  height: 100%;
  flex-grow: 1;
  overflow-x: hidden;
}

.SegmentedControlContent {
  width: 100%;
  overflow-x: auto;
}

.Dates {
  display: flex;
  flex-direction: row;
  column-gap: 8px;
}

.PhoneNumbers {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  padding: 24px 0 19px 0;
  border-top: 1px solid $border-gray;
  border-bottom: 1px solid $border-gray;

  &__switcher {
    display: flex;
    flex-direction: row;
    column-gap: 8px;
    align-items: center;
    justify-content: space-between;
  }

  &__radioGroupAdditional {
    display: flex;
    flex-direction: row;
    column-gap: 16px;
  }

  &__radioAdditional, &__switcherAdditional {
    div {
      margin-left: 0;
    }
  }
}

.Actions {
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  margin-top: auto;

  button {
    height: 55px;
  }
}