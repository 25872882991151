@import "../../../../../../../../app/global-styles/vars";
@import '../../../../../../../../shared/ui-kit-2/_constants/colors';
@import '../../../../../../../../shared/ui-kit-2/_constants/typography';

.Card {
  position: relative;
  height: max-content;
  padding: 32px 24px 32px 32px;
  background: $white-bg;
  border-radius: 30px;
  overflow: hidden;

  @media (max-width: $lk-mobile-l) {
    padding: 16px;
    border-radius: 20px;
  }

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  &_npAll {
    padding: 0;
  }

  &_npTop {
    padding-top: 0;
  }

  &_npBottom {
    padding-bottom: 0;
  }

  &_npRight {
    padding-right: 0;
  }

  &_npLeft {
    padding-left: 0;
  }
}