@import '../../../../../../../shared/ui-kit-2/_constants/colors.scss';
@import "../../../../../../../app/global-styles/vars";

.Row {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2.25rem;
  justify-content: space-between;
  margin: 3.25rem 0 6.25rem;

  @media screen and (max-width: $landing-screen-laptop) {
    grid-template-columns: 1fr;
    grid-gap: calc(31rem / $rem-mobile);
    margin: 0 0 calc(31rem / $rem-mobile);

    .Button {
      margin-top: calc(40rem / $rem-mobile);
    }
  }
}

.Button {
  margin-top: 3.56rem;

  @media screen and (max-width: $landing-screen-laptop) {
    margin-top: calc(40rem / $rem-mobile);
  }
}