@import "../../../../../../shared/ui-kit-2/_constants/colors";
@import "../../../../../../shared/ui-kit-2/_constants/typography";
@import '../../../../../../app/global-styles/vars.scss';

.CardAdditional {
  height: 100%;
  padding: 40px 32px 30px 32px;

  @media (max-width: $lk-mobile-xl) {
    width: 100%;
    padding: 20px 16px;

    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }
}

.Header {
  display: flex;
  flex-direction: row;
  column-gap: 16px;
  align-items: flex-end;
  justify-content: space-between;

  @media (max-width: $lk-mobile-l) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.Title {
  @include font-gilroy(42px, 110%, 700);
  letter-spacing: -0.84px;
  max-width: 448px;
  color: $dark-text;

  @media (max-width: $lk-mobile-l) {
    max-width: 100%;
    margin-bottom: 25px;
    font-size: 27px;
  }
}