@import '../../../../app/global-styles/vars.scss';


.Container {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: -114px;
  column-gap: 16px;
  height: 100%;
  min-height: calc(100vh - 115px);

  @media (max-width: $lk-tablet) {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    height: auto;
    margin-bottom: 0;
  }
}