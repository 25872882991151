@import "../../../../../../app/global-styles/vars";

.header_menu {
  height: 51px;
  border-radius: 8px;
  background: transparent;
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  align-items: center;
  padding: 8px 12px;
  box-sizing: border-box;
  transition: 0.2s ease;

  &__onTop {
    background: rgba(8, 14, 22, 0.5);
  }

}

.Container {
  display: flex;
  align-items: center;
  grid-gap: 16px;

  @media screen and (max-width: $landing-screen-laptop) {
    grid-gap: 1rem;
  }
}

.Menu {
  display: flex;
  align-items: center;
  grid-gap: 18px;
  height: 56px;
  padding: 10.4px 16px;
  background: #EEEEF7;
  border-radius: 22px;
  box-shadow: 1px 1px 1px 0 rgba(255, 255, 255, 0.24) inset, 0 6px 24.2px 0 rgba(0, 0, 0, 0.07), -12px -12px 21px 0 rgba(255, 255, 255, 0.60), 1px 1px 2px 0 rgba(0, 0, 0, 0.20), -3px -3px 6px 0 rgba(255, 255, 255, 0.21);
}