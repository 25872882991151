@import "../../../../shared/ui-kit-2/_constants/typography";
@import "../../../../shared/ui-kit-2/_constants/colors";

.Container {
  display: flex;
  flex-direction: row;
  column-gap: 45px;
  justify-content: space-between;
}

.Title {
  @include font-gilroy-48;
  color: $dark-text;
}

.Description {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  width: 443px;
  margin-top: 71px;
}

.Subtitle {
  display: flex;
  @include font-rubik-16;
  color: $dark-text;
}

.Button {
  width: 342px;
}

.VideoContainer {
  position: relative;
  height: 586px;
  width: 628px;
  max-height: 586px;
  overflow: hidden;
}

.Video {
  position: absolute;
  top: -276px;
  right: 0;
  left: -38px;
  width: 685px;
}

.Mask {
  position: absolute;
  height: 615px;
  width: 695px;
  top: 0;
  right: 0;
  bottom: 0;
  left: -37px;
  background: radial-gradient(46.77% 50% at 50% 50%, rgba(236, 236, 246, 0) 0%, rgba(236, 236, 246, 0) 58.02%, #ECECF6 100%);
}

