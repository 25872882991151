@import "../../../../../app/global-styles/vars";
@import "../../../../../shared/ui-kit-2/_constants/colors";
@import "../../../../../shared/ui-kit-2/_constants/typography";

.Content {
  display: flex;
  align-items: center;
  height: 78px;
  color: $dark-text;
  @include font-gilroy-14;

  &_name {
    word-break: break-word;
  }

  &_needNewPhoneNumber {
    color: $red-alert;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
      transition: 0.3s;
    }
  }
}

.Sms, .Calls {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  row-gap: 4px;
  height: 100%;
  color: $dark-text;
  @include font-rubik-14;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Row {
    display: flex;
  flex-direction: row;
  white-space: nowrap;
}

.Icon {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;;
  margin-right: 8px;
}

.Actions {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  column-gap: 10px;
  height: 100%;
  margin-left: auto;
}

.Error {
  margin-top: 3px;
  margin-right: 8px;
}