@import "../../../shared/ui-kit-2/_constants/colors";
@import "../../../shared/ui-kit-2/_constants/typography";

.DrawerAdditional {
  width: 100%;
  position: relative;
}

.DeleteContact {
  cursor: pointer;
}

.Container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.SubmitBtn {
  margin-top: auto;
}