@import '../../../../../../shared/ui-kit-2/_constants/typography.scss';
@import '../../../../../../shared/ui-kit-2/_constants/colors.scss';

.Container {
  display: flex;
  flex-direction: column;
  row-gap: 48px;
}

.Section {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
}

.SectionDescription {
  display: flex;
  color: $text-gray;
  @include font-rubik-16;
}
.SectionHeader {
  display: flex;
  flex-direction: row;
  column-gap: 24px;
  color: $dark-text;
  align-items: flex-end;

  &__title {
    @include font-gilroy-27;
  }

  &__description {
    @include font-rubik-14;
  }
}

.SectionCards {
  display: flex;
  flex-direction: row;
  column-gap: 16px;
}

.SubscriptionTypeCardContainer {
  max-width: 306px;
  width: 100%;
  max-height: 151px;
}

.SubscriptionTypeContent {
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  align-items: center;

  & > div {
    @include font-gilroy-27;
    color: $dark-text;
  }

  & > span {
    @include font-rubik-12;
    color: $green-dark;
  }
}

.PaymentMethodCardContainer {
  max-width: 333px;
  width: 100%;
  max-height: 143px;

  &_disabled {
    opacity: 0.5;
  }
}

.Footer {
  margin-top: auto;
    display: flex;
  flex-direction: row;
  column-gap: 16px;
  align-items: center;

  &__actions {
    display: flex;
    align-items: center;
    flex-direction: row;
    column-gap: 8px;
    justify-content: flex-end;
    flex-grow: 1;
  }
}

.LaterBtn {
  width: max-content;
  padding: 0 48px;
}

.PayNowBtn {
  width: max-content;
  padding: 0 163px;
  min-width: 390px;
}