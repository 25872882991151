@import '../../../../../../../../shared/ui-kit-2/_constants/colors.scss';
@import '../../../../../../../../shared/ui-kit-2/_constants/typography.scss';

.Container {
  min-height: calc(100vh - 182px);
  margin-top: 24px;
  padding: 32px 16px;
  position: relative;
  box-shadow: none;
}

.Header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.Title {
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  @include font-gilroy-24();
  color: $dark-text;
}

.Actions {
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  position: relative;
}

.AddContactBtnAdditional {
  width: 48px;
  background: $green;
  border-radius: 20px;

  span {
    span {
      display: none;
    }
  }

}

.ContainerList {
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  overflow-y: auto;
  height: calc(100% - 83px);
  margin-bottom: 40px;
}