@import '../../_constants/colors.scss';
@import '../../_constants/typography.scss';

.InputContainer {
  display: flex;
  width: 48px;
  height: 48px;
  background: $sidebar-link-active;
  padding: 17px;
  border-radius: 20px;
  position: relative;
  pointer-events: auto;

  &_outlined {
    background: transparent;
    box-shadow: inset 0 0 0 1px $border-gray;
  }
}

.Input {
  width: 250px;
  position: absolute;
  right: 0;
  top: 0;
  height: 47px;
  background: $sidebar-link-active;
  border: none;
  border-radius: 20px;
  padding: 17px;
  z-index: 4;
  outline: none;
  opacity: 0;
  transition: opacity 0.3s ease, width 0.3s ease;
  pointer-events: none;
  @include font-rubik-14;
  color: $text-gray;
}

.InputActive {
  opacity: 1;
  pointer-events: auto;
  height: 49px;
  border: 1px solid $border-gray;
  background: $white;
  color: $dark-text;

  &:focus {
    border: 1px solid $green;
  }
}

.InputHasValue {
  border: 1px solid $green;
  color: $green;

  svg {
    g {
      circle {
        stroke: currentColor;
      }

      path {
        stroke: currentColor;
      }
    }
  }
}