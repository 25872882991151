@import '../../../../../../../../../shared/ui-kit-2/_constants/colors.scss';
@import '../../../../../../../../../shared/ui-kit-2/_constants/typography.scss';

.Wrapper {
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  align-items: center;
}

.Balance {
  display: flex;
  flex-direction: column;
  row-gap: 11px;

  &__info {
    display: flex;
    flex-direction: row;
    column-gap: 8px;

    > :nth-child(1) {
      @include font-rubik-14;
      color: $text-gray;
    }

    > :nth-child(2) {
      @include font-gilroy-14-bold;
      color: $dark-text;
    }
  }
}

.EmptyBalance {
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  align-items: center;

  > :nth-child(1) {
    @include font-rubik-14;
    color: $text-gray;
  }

  > :nth-child(2) {
    @include font-gilroy-14;
    cursor: pointer;
    color: $green-dark;
  }
}