@import "../../../../../../../../app/global-styles/vars";
@import '../../../../../../../../shared/ui-kit-2/_constants/typography';
@import '../../../../../../../../shared/ui-kit-2/_constants/colors';

.Card {
  width: 100%;
  min-height: calc(100vh - 58px - 114px - 6px);
  margin-bottom: -114px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  overflow: hidden;
  position: relative;

  @media (max-width: $lk-mobile-l) {
    min-height: calc(100vh - 223px);
    margin-bottom: 0;
  }
}

.Header {
  padding: 24px 24px 0 24px;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  @include font-gilroy-24;
  color: $dark-text;
}