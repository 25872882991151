@import "../../app/global-styles/vars";
@import '../../shared/ui-kit-2/_constants/typography.scss';

.footer {
  width: 100%;
  height: $footer-height;
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;

  &_content {
    height: 100%;
    width: 100%;
    max-width: $landing-screen-laptop-l;
    display: flex;
    align-items: center;
    justify-content: space-between;

    color: #161616;
    @include font-gilroy-14;
    letter-spacing: 1px;

    @media (min-width: $landing-screen-laptop) and (max-width: $landing-screen-laptop-l) {
      max-width: $landing-screen-laptop;
      font-size: 14px;
    }

    @media (min-width: $landing-screen-tablet) and (max-width: $landing-screen-laptop) {
      max-width: $landing-screen-tablet;
      font-size: 14px;
    }

    @media (max-width: $landing-screen-tablet) {
      max-width: $landing-screen-mobile;
      font-size: 11px;
    }

    &_links {
      display: flex;
      flex-direction: row;
      column-gap: 48px;

      @media (min-width: $landing-screen-laptop) and (max-width: $landing-screen-laptop-l) {
        column-gap: 48px;
      }

      @media (min-width: $landing-screen-tablet) and (max-width: $landing-screen-laptop) {
        column-gap: 48px;
      }

      @media (max-width: $landing-screen-tablet) {
        column-gap: 8px;
      }

      &_link {
        cursor: pointer;
      }
    }
  }
}