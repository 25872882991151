@import "../../../../shared/ui-kit-2/_constants/typography";
@import "../../../../shared/ui-kit-2/_constants/colors";

.Container {
  margin-top: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  row-gap: 16px;;
  width: 100%;
}

.Title {
  @include font-rubik-14;
  color: $dark-text;
}

.Buttons {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
}