//ADAPTIVE SCREEN SIZES

//LANDING SCREEN SIZES
$landing-screen-laptop-l: 1280px;
$landing-screen-laptop: 1070px;
$landing-screen-tablet: 680px;
$landing-screen-mobile: 335px;

//LK SCREEN SIZES
$lk-mobile-xs: 376px;
$lk-mobile-s: 529px;
$lk-mobile: 749px;
$lk-mobile-l: 999px;
$lk-mobile-xl: 1150px;
$lk-tablet: 1259px;
$lk-laptop: 1440px;

//OTHER SCREEN SIZES
$container-xl: 1646px;
$screen-xl: 1920px;
$footer-height: 48px;

// REM
$rem-desktop: 16;
$rem-mobile: 3.76;
