@import '../../../../shared/ui-kit-2/_constants/colors.scss';
@import "../../../../app/global-styles/vars";

.Card {
  position: relative;
  width: 100%;
  height: 100%;
  background: $white;
  border-radius: 36px;
  box-shadow: 1px 1px 1px 0 rgba(255, 255, 255, 0.24) inset, 0 6px 24.2px 0 rgba(0, 0, 0, 0.07), -3px -3px 6px 0 rgba(255, 255, 255, 0.21);
  overflow: hidden;

  &_vertical {
    .Title {
      margin-top: auto;
    }

    .Subtitle {
      width: 100%;
      margin-top: 16px;
    }

    &_withInsideImage {
      .Title {
        margin-top: 16px;
      }
    }
  }

  &_blurred {
    background: $sidebar-link-active;
  }

  @media screen and (min-width: $landing-screen-laptop) and (max-width: $landing-screen-laptop-l) {
    .Wrapper {
      padding: 25px 24px 26px 30px;
    }

    .Title {
      margin-bottom: 13px;
      font-size: 28px;
      line-height: 1;
    }

    .Subtitle {
      font-size: 14px;
      line-height: 1.2;
    }
  }

  @media screen and (max-width: $landing-screen-laptop) {
    border-radius: calc(30rem / $rem-mobile);
    // background: radial-gradient(50% 50% at 50% 50%, rgba(235, 235, 245, 0.74) 0%, #EBEBF5 100%), lightgray 50% / cover no-repeat;

    .Wrapper {
      padding: 0 11.23rem 10.64rem 6.38rem;
    }

    .Title {
      margin-bottom: 6.39rem;
      font-size: 7.18rem;
    }

    .Subtitle {
      font-size: 3.72rem;
    }

    &_halving {
      .Background {
        position: relative;
        width: 150%;
        height: 50%;
        left: -50%;
      }

      .Wrapper {
        height: 50%;
      }
    }

    &_vertical {
      .Subtitle {
        margin-top: 0;
      }
    }

    &_columnView {
      .Wrapper {
        padding: calc(220rem / $rem-mobile) calc(16rem / $rem-mobile) calc(40rem / $rem-mobile) !important;
      }
    }

    .Background {
      height: auto;
    }

    .Video {
      z-index: 1;
    }

    .Blur {
      z-index: 2;
    }
  }
}

.Background {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;

  img {
    width: 100%;
    // height: 100%;
  }
}

.Wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 35px 18px 24px 30px;
  z-index: 3;
}

.Title {
  color: $dark-text;
  font-family: Gilroy, sans-serif;
  font-size: 34px;
  font-style: normal;
  font-weight: 700;

  &_medium {
    font-size: 30px;
  }
}

.Subtitle {
  width: 70%;
  margin-top: auto;
  color: $text-gray;
  font-family: Rubik, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;

  @media screen and (max-width: 1400px) {
    width: 100%;
  }
}

.ImageBeforeText {
  img {
    width: 100%;
    height: 320px;
  }
}

.Video {
  position: relative;
  width: calc(100% + 4px);
  margin: -1px;
  height: calc(100% + 4px);
  object-fit: fill;
}

.Blur {
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  background: linear-gradient(180deg, rgba(235, 235, 245, 0.00) 53.74%, $sidebar-link-active 100%);
}