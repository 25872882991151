@import '../../../../shared/ui-kit-2/_constants/colors.scss';
@import '../../../../shared/ui-kit-2/_constants/typography.scss';

.Card {
  display: flex;
  flex-direction: row;
  column-gap: 55px;
  align-items: center;
  padding: 0 66px;
  height: 632px;
}

.DescriptionAndButton {
  display: flex;
  flex-direction: column;
  row-gap: 27px;
}

.Description {
  max-width: 554px;
  @include font-gilroy-48;
  color: $dark-text;
}