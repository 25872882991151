
@import '../../../../shared/ui-kit-2/_constants/colors.scss';

.HelperFrame {
  display: flex;
  grid-gap: 12px;
}

.IconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 48px;
  min-height: 48px;
  border-radius: 17px;
  background: #D9E8F5;
  overflow: hidden;
}

.Icon {
  width: 24px;
  height: 24px;
  color: $dark-text;
}

.Text {
  display: flex;
  align-items: center;
  color: $dark-text;
  font-family: Rubik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px
}