@import "../../../shared/ui-kit-2/_constants/colors";
@import "../../../shared/ui-kit-2/_constants/typography";

.Container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  row-gap: 24px;
}

.Title {
  margin-top: 100%;
  font-size: 100px;
  @include font-gilroy(100, 120%, 700);
  color: $dark-text;
}