@import "../../../../../../../../shared/ui-kit-2/_constants/typography";
@import "../../../../../../../../shared/ui-kit-2/_constants/colors";
@import '../../../../../../../../app/global-styles/vars.scss';

.CardAdditional {
  position: relative;
  width: 339px;
  min-width: 339px;
  padding: 32px 24px 88px 24px;

  @media (max-width: $lk-tablet) {
    width: 100%;
  }
}

.Header {
  display: flex;
  flex-direction: row;
  column-gap:  8px;
  align-items: center;
  width: 100%;
  height: 26px;
  margin-bottom: 19px;

  &__title {
    @include font-gilroy-24;
    color: $dark-text;
  }

  &__counterAdditional {
    margin-left: auto;
  }
}

.ScrollableContent {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}

.CreateContainer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 16px 24px 24px 24px;
  background: $white-bg;
  border-radius: 0 0 30px 30px;
  overflow: hidden;
}