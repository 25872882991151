body > jdiv > jdiv > jdiv:first-child {
    @media screen and (max-width: 1000px) {
        display: none !important;
    }
}

.MuiAlert-message {
    display: flex;
    align-items: center;
}

.MuiAlert-action {
    display: flex;
    align-items: center !important;
    padding-top: 0 !important;
}