@import "../../_constants/typography";
@import "../../_constants/colors";
@import "../../_constants/transition-mixins";

.Container {
  display: flex;
  flex-direction: column;
}

.Content {
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  padding: 16px;
  position: relative;

  &_error {
    border-color: $red-alert;
  }
}

.Area {
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  border: 1px solid $border-gray;

  &_error {
    border-color: $red-alert;
  }
}

.Label {
  padding-bottom: 16px;
  height: 35px;
  display: flex;
  align-items: center;
  flex-direction: row;;
  justify-content: space-between;
  border-bottom: 1px solid $border-gray;

  &__title {
    @include font-rubik-16;
    color: $dark-text;

    & > span {
      color: $red-alert;
    }
  }

  &__maxLengthText {
    @include font-rubik-12;
    color: $text-gray;
  }
}

.Textarea {
  background: none;
  z-index: 2;
  resize: none;
  border: none;
  min-height: 42px;
  padding: 16px 0 0 0;
  outline: none;
  @include font-rubik-14;
  color: $dark-text;

  &::placeholder {
    color: $text-gray;
  }
}

.Textarea:hover + .Area, .Textarea:focus + .Area {
  border-color: $green;
}

.Error {
  margin-top: 8px;
  @include font-rubik-14;
  color: $red-alert;
  word-break: break-word;
}