@import "../../../../../../app/global-styles/vars";
@import '../../../../../../shared/ui-kit-2/_constants/typography.scss';
@import '../../../../../../shared/ui-kit-2/_constants/colors.scss';
@import "../../../../../../app/global-styles/vars";

.Container {
  display: flex;
  position: relative;
}

.Dropdown {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  @media (max-width: $lk-mobile-l) {
    .Avatar {
      padding: 0;
    }

    .Nickname {
      font-size: 20px;
    }
  }

  &__Content {
    position: relative;
    top: 17px;
    display: flex;
    flex-direction: column;
    padding: 16px 24px;
    left: 16px;
    width: calc(100% - 32px);
    border-radius: 20px;
    animation: show-header-menu-dropdown 0.2s ease;
    background: $page-wrapper;
    filter: drop-shadow(-5px 4px 17.6px rgba(0, 0, 0, 0.25));
  }

  &__Closer {
    position: absolute;
    top: 13px;
    right: 16px;
    width: 24px;
    height: 24px;
    background: none;
    border: none;
    color: $dark-text;
  }
}

.Avatar {
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  align-items: center;
  padding: 0 8px;
}

.AvatarCircle {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  min-width: 40px;
  min-height: 40px;
  background: $badge-gray;
}

.Nickname {
  @include font-gilroy-27;
  color: $dark-text;
}

.Border {
  width: 100%;
  height: 1px;
  margin: 12px 0 18px;
  background: $border-gray;
}

.MainLinksWrapper {
  display: flex;
  flex-direction: column;
  row-gap: 18px;
  padding: 0 8px;
}

.MainLinkItem {
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  align-items: center;
  color: $dark-text;
  @include font-gilroy-20;
  cursor: pointer;

  svg {
    width: 23px;
    height: 23px;
  }
}

.SecondLinksWrapper {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  margin-top: 24px;
  margin-bottom: 24px;
}

.SecondLinkItem {
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  align-items: center;
  padding: 0 8px;
  @include font-rubik-14;
  color: $text-gray;

  svg {
    width: 20px;
    height: 20px;

    path {
      fill: currentColor;
    }
  }
}

.JivoChat {
  color: $text-gray;
  svg {
    path {
      fill: none;
    }

    path:first-child {
      stroke: currentColor;
    }

    path:nth-last-child(-n+2) {
      fill: currentColor;
    }
  }
}

.LogOut {
  color: $red-alert;

  svg {
    path {
      fill: none;
      stroke: currentColor;
    }
  }
}

.DeviceActions {
  display: flex;
  flex-direction: row;
  width: 100%;
  column-gap: 8px;
}

.ActivateDevice {
  height: 60px;
}

.BurgerMenu {
  cursor: pointer;
  color: $green-dark;

  &:hover {
    svg {
      path {
        stroke: currentColor;
      }
    }
  }
}