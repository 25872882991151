@import "../../../shared/ui-kit-2/_constants/colors";
@import "../../../shared/ui-kit-2/_constants/typography";

.Container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;;
  padding-top: 21px;
  height: 100%;
}

.Image {
  width: 100%;
  filter: blur(3px);
  max-width: 445px;
}

.Content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  row-gap: 16px;
}

.Title {
  @include font-gilroy-48;
  color: $dark-text;
  text-align: center;
}

.Description {
  @include font-rubik-16;
  color: $text-gray;
  text-align: center;
  max-width: 445px;
}
