@import "../../_constants/typography";
@import "../../_constants/colors";
@import "../../_constants/transition-mixins";

.Container {
  width: max-content;
  height: 48px;
  background: $bg-gray-2;
  border-radius: 20px;
  padding: 4px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  column-gap:  8px;
}

.Option {
  cursor: pointer;
  background: none;
  box-shadow: none;
  border-radius: 15px;
  padding: 8px 16px;
  @include font-rubik-14;
  line-height: 24px;
  color: $dark-text;
  @include transition-default(all);
  user-select: none;
}

.HiddenInput {
  display: none;

  &:checked ~ .Option {
    background: $white;
    box-shadow: 0 2px 13px 0 rgba(0, 0, 0, 0.14);
  }
}