@import "../../_constants/typography";
@import "../../_constants/colors";
@import "../../_constants/transition-mixins";

.Container {
  display: flex;
  flex-direction: column;
}

.Label {
  margin-bottom: 8px;
  height: 20px;
  @include font-rubik-14;
  line-height: 20px;
  color: $dark-text;

  & > span {
    color: $red-alert;
  }
}

.Area {
  position: relative;
  width: 100%;
  height: 55px;
  border-radius: 20px;
  display: flex;
  flex-direction: row;

  & > input {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-grow: 1;
    border-radius: 20px;
    box-sizing: border-box;
    padding: 21px 20px 21px 20px;
    border: none;
    @include font-rubik-14;
    line-height: 20px;
    color: $dark-text;
    background: none;
    outline: none;
    z-index: 1;
    text-overflow: ellipsis;

    &::placeholder {
      color: $text-gray;
    }
  }

  &__background {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 55px;
    box-sizing: border-box;
    border-radius: 20px;
    border: 1px solid $border-gray;
    pointer-events: none;
    z-index: 0;

    &_error {
      border: 1px solid $red-alert;
    }
  }

  & > input:hover ~ &__background, & > input:focus ~ &__background {
    border: 1px solid $green;
  }

  & > input:not(:placeholder-shown):not(:focus) ~ &__background {
    background: none;
  }

  & > input:not(:placeholder-shown):not(:focus) ~ .AdditionalContentGroup {
    background: none;
  }
}

.AdditionalContent {
  display: flex;
  flex-direction: row;
  column-gap: 12px;
  align-items: center;
  justify-items: center;
  z-index: 2;
  background: transparent;
  height: 53px;
  border-radius: 0 20px 20px 0;
  margin-top: 1px;
  margin-right: 14px;

  &__validationElement {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__inputTypeSwitch {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}

.Error {
  margin-top: 8px;
  @include font-rubik-14;
  color: $red-alert;
  word-break: break-word;
}

.HelperText {
  margin-top: 8px;
  @include font-rubik-12;
  color: $text-gray;
  word-break: break-word;
}