@import "../../../../../shared/ui-kit-2/_constants/colors";
@import "../../../../../shared/ui-kit-2/_constants/typography";
@import "../../../../../shared/ui-kit-2/_constants/transition-mixins";
@import '../../../../../app/global-styles/vars.scss';

.Content {
  display: flex;
  flex-direction: row;
  column-gap: 16px;
  padding: 24px;
  justify-content: space-between;

  &__titleAndDetails {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__countAndAmount {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
  }

  @media (max-width: $lk-mobile-l) {
    flex-direction: column;
    padding: 24px 16px;
  }
}

.TitleContainer {
  display: flex;
  flex-direction: row;
  column-gap: 16px;
}

.Title {
  @include font-gilroy-27;
  color: $dark-text;

  @media (max-width: $lk-mobile-l) {
    font-size: 20px;
  }
}

.DetailList {
  display: flex;
  flex-direction: row;
  column-gap: 8px;

  @media (max-width: $lk-mobile-l) {
    margin: 12px 0;
  }
}

.Detail {
  height: 74px;
  min-width: 138px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px 16px;
  border: 1px solid $border-gray;
  border-radius: 20px;

  @media (max-width: $lk-mobile-l) {
    height: 68px;
    padding: 10px 0;
    border: none;
  }

  @media (max-width: $lk-mobile-s) {
    min-width: 97px;
  }

  @media (max-width: $lk-mobile-xs) {
    min-width: 85px;
    grid-gap: 8px;
    height: auto;
  }

  &__title {
    @include font-rubik-14;
    color: $dark-text;

    @media (max-width: $lk-mobile-xs) {
      font-size: 12px;
    }
  }

  &__value {
    display: flex;
    flex-direction: row;
    column-gap: 8px;
    align-items: flex-end;

    @media (max-width: $lk-mobile-xs) {
      column-gap: 0;
      margin-top: auto;
      margin-bottom: auto;
      font-size: 14px;
    }

    &_default {
      @include font-gilroy(20px, 120%, 500);
      color: $dark-text;

      @media (max-width: $lk-mobile-xs) {
        font-size: 14px;
      }
    }

    &_smaller {
      @include font-gilroy(12px, 120%, 600);
      color: $dark-text;

      @media (max-width: $lk-mobile-xs) {
        font-size: 14px;
      }
    }

    &_green {
      @include font-gilroy(20px, 120%, 500);
      color: $green-dark;

      @media (max-width: $lk-mobile-xs) {
        font-size: 14px;
      }
    }

    &_greenCrossedOut {
      @include font-gilroy(16px, 120%, 500);
      color: $green-dark;
      text-decoration: line-through;

      @media (max-width: $lk-mobile-xs) {
        margin-left: auto;
        font-size: 14px;
      }
    }
  }
}

.Counter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  border-radius: 20px;
  border: 1px solid $border-gray;
  width: 211px;
  height: 56px;
  padding: 14px 24px;
  box-sizing: border-box;

  @media (max-width: $lk-mobile-l) {
    width: 100%;
  }

  &__Input {
    width: 20px;
    height: 30px;
    background: transparent;
    outline: none;
    color: #333;
    @include font-gilroy(19px, 24px, 500);
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    border: none;
    border-radius: 0;

    &_long {
      width: 32px;
    }

    &_2long {
      width: 40px;
    }
  }

  &__Label {
    font-size: 14px;
    font-family: 'Gilroy', sans-serif;
    font-weight: 500;
  }

  &__operation {
    height: 40px;
    display: flex;
    align-items: center;
    cursor: pointer;
    width: 27px;
    user-select: none;

    & > svg {
      width: 27px;
      height: 27px;
    }

    & > svg path {
      @include transition-default(all);
    }

    &:not(&_disabled):hover {
      & > svg path{
        stroke: $green;
      }
    }

    &_disabled {
      cursor: default;
      opacity: 0.2;
    }
  }
}

.Amount {
  display: flex;
  flex-direction: column;
  row-gap: 9px;
  border-radius: 20px;
  border: 1px solid $border-gray;
  padding: 10px 16px;

  &__title {
    color: $dark-text;
    @include font-rubik-14;
  }

  &__value {
    color: $dark-text;
    @include font-gilroy(35px, 120%, 600);

    @media (max-width: $lk-mobile-l) {
      font-size: 20px;
    }
  }
}