@import '../../../shared/ui-kit-2/_constants/colors.scss';
@import '../../../shared/ui-kit-2/_constants/typography.scss';
@import '../../../app/global-styles/vars.scss';

.Drawer {
  width: 100%;
  position: relative;
}

.Content {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  flex-grow: 1;
}

.Actions {
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  margin-top: auto;

  button {
    height: 58px;
  }
}

.Delete {
  height: 60px;
}