@import "../../../../../shared/ui-kit-2/_constants/colors";
@import "../../../../../shared/ui-kit-2/_constants/typography";
@import "../../../../../shared/ui-kit-2/_constants/transition-mixins";

.Container {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  padding: 16px 16px 24px 16px;
  border-radius: 20px;
  background: $sidebar-link-active;
}

.Header {
    display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 16px;
  box-sizing: border-box;
  border-bottom: 1px solid $border-gray;
}

.Title {
  display: flex;
  flex-direction: row;
  column-gap:  8px;
  align-items: center;
  @include font-rubik-16;
  color: $dark-text;
}

.Content {
  display: flex;
  flex-direction: row;
  column-gap:  8px;
  row-gap: 8px;
  flex-wrap: wrap;
}

.Item {
  background: $white;
  border-radius: 15px;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  column-gap: 8px;
  @include font-rubik-14;
  line-height: 106%;
  color: $dark-text;

  &__close {
    cursor: pointer;
  }
}