@import "../../../../../../app/global-styles/vars";
@import '../../../../../../shared/ui-kit-2/_constants/colors.scss';

@keyframes show-header-menu-dropdown {
  from {
    opacity: 0;
    top: 0;
  }
  to {
    opacity: 1;
    top: calc(17rem / $rem-mobile);
  }
}

.Menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  column-gap: calc(10rem / $rem-mobile);
  padding: calc(8rem / $rem-mobile);
  color: $dark-text;
  font-family: Play, sans-serif;
  font-size: calc(14rem / $rem-mobile);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  box-sizing: border-box;
  cursor: pointer;
  transition: 0.2s ease;

  &_opened {
    padding: 10px;
  }
}

.Dropdown {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: var(--app-height);

  &__Content {
    position: relative;
    top: calc(17rem / $rem-mobile);
    left: calc(17rem / $rem-mobile);
    display: flex;
    flex-direction: column;
    row-gap: calc(18rem / $rem-mobile);
    width: calc(100% - calc(34rem / $rem-mobile));
    padding: calc(32rem / $rem-mobile) calc(24rem / $rem-mobile) calc(24rem / $rem-mobile) calc(24rem / $rem-mobile);
    border-radius: 20px;
    animation: show-header-menu-dropdown 0.2s ease;
    background: $page-wrapper;
    filter: drop-shadow(-5px 4px 17.6px rgba(0, 0, 0, 0.25));
  }

  &__Actions {
    display: flex;
    justify-content: space-between;
    grid-gap: calc(8rem / $rem-mobile);
    margin-top: calc(28rem / $rem-mobile);
  }

  &__Link {
    color: $dark-text;
    font-family: Gilroy, sans-serif;
    font-size: calc(30rem / $rem-mobile);
    font-style: normal;
    font-weight: 600;
    cursor: pointer;
    line-height: normal;
    text-decoration: none;
  }

  &__Closer {
    position: absolute;
    top: calc(14rem / $rem-mobile);
    right: calc(14rem / $rem-mobile);
    width: 24px;
    height: 24px;
    background: none;
    border: none;
    padding: calc(4rem / $rem-mobile);
    color: $dark-text;
  }
}