@import '../../../../../shared/ui-kit-2/_constants/colors.scss';
@import "../../../../../app/global-styles/vars";

.Container {
  position: relative;
  width: 100%;
  height: auto;
  margin-top: -60px;
  overflow: hidden;

  @media screen and (min-width: $landing-screen-laptop) and (max-width: $landing-screen-laptop-l) {
    .Wrapper {
      width: 1058px;
    }

    .Cols {
      grid-template-columns: 100%;
      grid-gap: 0;
    }

    .Title {
      max-width: 622px;
      margin-bottom: 10px;
      font-size: 87px;
      letter-spacing: -1px;
      line-height: 104.2%;
    }

    .Col {
      &_top {
        display: flex;
      }
    }

    .Info {
      &__Cols {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 24px;
      }

      &__Label {
        font-size: 16px;
      }

      &__Value {
        font-size: 14px;
      }
    }

    .Address {
      font-size: 16px;
    }
  }

  @media screen and (max-width: $landing-screen-laptop) {
    height: auto;

    .Background {
      width: 300%;
      height: 73%;
      left: calc(-100rem / $rem-mobile);
    }

    .Wrapper {
      width: 100%;
      padding: calc(319rem / $rem-mobile) calc(17rem / $rem-mobile) calc(47rem / $rem-mobile);
    }

    .Cols {
      display: flex;
      flex-direction: column;
      grid-gap: calc(40rem / $rem-mobile);
    }

    .Title {
      margin-bottom: 0;
      font-size: calc(50rem / $rem-mobile);
    }

    .Address {
      font-size: calc(16rem / $rem-mobile);
    }

    .Info {
      &__Cols {
        grid-template-columns: 40% 60%;
        grid-gap: calc(24rem / $rem-mobile);
      }

      &__Label {
        font-size: calc(16rem / $rem-mobile);
      }

      &__Value {
        font-size: calc(14rem / $rem-mobile);
      }
    }

    .Address {
      margin-top: calc(24rem / $rem-mobile);
    }

    .Divider {
      margin: calc(24rem / $rem-mobile) 0;
    }

    .Logo {
      width: calc(65rem / $rem-mobile);
      height: calc(38rem / $rem-mobile);

      img {
        width: 100%;
        height: 100%;
      }
    }

    .Copyright {
      margin-top: calc(24rem / $rem-mobile);
      font-size: calc(12rem / $rem-mobile);
    }

    .Footer {
      align-items: flex-start;

      &__MainMobile {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }

    .Links {
      grid-gap: calc(8rem / $rem-mobile);
      margin-top: calc(8rem / $rem-mobile);
    }

    .Link {
      font-size: calc(14rem / $rem-mobile);
    }

    .Mask {
      position: absolute;
      width: 100%;
      height: 50%;
      top: 50%;
      background: linear-gradient(180deg, rgba(31, 164, 51, 0.00) 0%, #1FA433 46.24%);
    }
  }
}

.Wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 1280px;
  height: 100%;
  margin: 0 auto;
  padding: 422px 0 24px;
  color: $white;
  z-index: 1;
}

.Background {
  position: absolute;
  width: 101%;
  height: 100%;
  margin: 0 -2px;

  img {
    width: 101%;
    height: 100%;
  }
}

.Title {
  margin-bottom: 75px;
  font-family: Gilroy, sans-serif;
  font-size: 87px;
  font-style: normal;
  font-weight: 700;
}

.Cols {
  display: grid;
  grid-template-columns: 622px 1fr;
  justify-content: space-between;
  grid-gap: 70px;
}

.Info {
  &__Cols {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 42px;
  }

  &__Col {
    display: flex;
    flex-direction: column;
    grid-gap: 8px;
    width: 100%;
  }

  &__Label {
    font-family: Rubik, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
  }

  &__Value {
    display: block;
    color: $white;
    text-decoration: none;
    font-family: Rubik, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.2;
    transition: 0.3s;

    &:hover {
      opacity: 0.8;
    }
  }
}

.Address {
  margin-top: 25px;
  font-family: Rubik, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
}

.Footer {
  display: flex;
  justify-content: space-between;
  align-items: end;
  margin-top: 49px;
}

.Copyright {
  font-family: Gilroy, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.2;
  text-transform: uppercase;
}

.Links {
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-end;
  grid-gap: 8px;
}

.Link {
  color: $white;
  text-decoration: none;
  font-family: Gilroy, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.2;
  transition: 0.3s;

  &:hover {
    opacity: 0.7;
  }
}

.Actions {
  button {
    height: 55px;
  }
}

.Divider {
  width: 100%;
  height: 1px;
  background: $white;
}