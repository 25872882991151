@import "../../../../shared/ui-kit-2/_constants/colors";
@import "../../../../shared/ui-kit-2/_constants/typography";
@import "../../../../app/global-styles/vars";

.Paper {
  width: 453px !important;
  padding: 0 !important;;
  border-radius: 20px !important;

  @media (max-width: $lk-mobile-s) {
    margin: 16px !important;
  }
}

.Title {
  color: $dark-text;
  font-family: Gilroy, sans-serif;
  font-size: 27px;
  font-style: normal;
  font-weight: 700;
}

.Subtitle {
  margin-bottom: 20px;
  color: $dark-text;
  font-family: Gilroy, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}

.Actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 8px;

  button {
    height: 60px;
  }
}

.CloseButton {
  position: absolute;
  top: 27px;
  right: 18px;
  width: 24px;
  height: 24px;
  background: transparent;
  border: none;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    opacity: 0.8;
  }
}