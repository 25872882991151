@import '../../../../../../../../../../shared/ui-kit-2/_constants/colors.scss';
@import '../../../../../../../../../../shared/ui-kit-2/_constants/typography.scss';

.GroupCard {
  padding: 16px 8px 8px 16px;
  border: 1px solid $border-gray;
  border-radius: 20px;
  justify-content: space-between;
  flex-direction: column;
  row-gap: 21px;
  display: flex;
}

.NameGroup {
  @include font-gilroy-20;
  color: $dark-text;
  width: 225px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.GroupInfo {
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  align-items: center;
  width: 100%;
}

.GroupNumber {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 5px;
}

.Button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 34px;
  border-radius: 13px;
  border: none;
  background: $sidebar-link-active;
  margin-left: auto;
  cursor: pointer;
}