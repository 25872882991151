@import "../../../../../../../../app/global-styles/vars";
@import '../../../../../../../../shared/ui-kit-2/_constants/typography.scss';
@import '../../../../../../../../shared/ui-kit-2/_constants/colors.scss';

.Balance {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 184px;
  padding: 28px 0 23px 32px;
  background: no-repeat url('../../../../../../../../assets/icons/balance.svg') right;

  @media (max-width: $lk-mobile-l) {
    padding: 16px;
    background: no-repeat url('../../../../../../../../assets/icons/balance-mobile.svg') right;
    background-size: 160px;

    .Label {
      display: inline-block;
      @include font-gilroy-20;
    }

    .Counter {
      margin-left: 7px;
    }

    .Action {
      width: 115px;
    }

    .Money {
      display: block;
    }
  }
}

.Money {
  display: flex;
  @include font-gilroy-27;
  color: $dark-text;
}

.Counter {
  display: inline-flex;
  margin-left: 16px;
}

.Action {
  max-width: 260px;

  button {
    height: 48px;
  }
}