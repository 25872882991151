@import '../../../shared/ui-kit-2/_constants/colors.scss';
@import '../../../shared/ui-kit-2/_constants/typography.scss';

.Wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  flex: 1;
}

.ImageAndDescription {
  display: flex;
  flex-direction: column;
  row-gap: 124px;
  img {
    padding: 78px;
  }
}

.Description {
  @include font-gilroy-48;
  color: $dark-text;
}

.Actions {
  display: flex;
  flex-direction: row;
  width: 100%;
  column-gap: 8px;
}