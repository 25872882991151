@import "../../../../../shared/ui-kit-2/_constants/colors";
@import "../../../../../shared/ui-kit-2/_constants/typography";
@import "../../../../../app/global-styles/vars";

.Ussd {
  width: 100%;
  height: 100%;
  margin-top: 16px;
}

.Chat {
  position: relative;
  width: 100%;
  height: calc(100vh - 250px);
  background: #EEEEF7;
  border-radius: 8px;
  overflow: hidden;
}

.Top {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  grid-gap: 8px;
  width: 100%;
  height: 80px;
  background: #EEEEF7;
  padding: 16px;
  border-bottom: 1px solid $border-gray;
  z-index: 1;
}

.DeviceInfo {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  grid-gap: 8px;
}

.DeviceName {
  color: $dark-text;
  font-family: Gilroy, 'sans-serif';
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
}

.DevicePhone {
  display: flex;
  grid-gap: 2px;

  &__Label {
    font-family: 'Gilroy', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    color: $text-gray;
  }

  &__Number {
    font-family: 'Gilroy', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    color: $dark-text;
  }
}

.Button {
  width: 109px;
  height: 48px;
}

.Input div {
  max-height: 48px;
}

.Messages {
  position: relative;
  display: flex;
  flex-direction: column;
  grid-gap: 16px;
  height: calc(100% - 160px);
  margin-top: 80px;
  padding: 16px;
  overflow-y: scroll;

  &_event {
    justify-content: center;
  }
}

.Message {
  width: 100%;

  &__Container {
    display: grid;
    grid-template-columns: 1fr max-content;
    grid-gap: 8px;
    max-width: 495px;
    padding: 11px 16px;
    border-radius: 20px 20px 20px 4px;
    background: $white;
  }

  &__Text {
    width: max-content;
    max-width: 431px;
    color: $dark-text;
    font-family: Gilroy, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
  }

  &__Info {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    grid-gap: 2px;
    margin-top: auto;
  }

  &__Time {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    min-width: 22px;
    color: $text-gray;
    font-family: 'Gilroy', sans-serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 1;
  }

  &__Status {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 14px;
    min-width: 14px;
    height: 10px;
  }

  &_outgoing {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    grid-gap: 8px;
    margin-left: auto;

    .Message {
      &__Container {
        background: $text-gray;
        border-radius: 20px 20px 4px 20px;
      }

      &__Text {
        color: $white;
      }

      &__Time {
        color: $badge-gray;
      }

      &__Repeat {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 36px;
        height: 36px;
        color: $text-gray;
        background: $white;
        border-radius: 50%;
        border: none;
        cursor: pointer;
        transition: 0.3s;

        &:hover {
          color: #32435B;
        }

        &:disabled {
          opacity: 0.5;
          cursor: default;
        }
      }

      &__Alert {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 32px;
        background: #FFDADA;
        border-radius: 50%;
      }
    }
  }
}

.Bottom {
  display: grid;
  grid-template-columns: 1fr max-content;
  grid-gap: 8px;
  width: 100%;
  height: 80px;
  padding: 16px;
  border-top: 1px solid #BFCCD7;
}

.Date {
  color: $text-gray;
  text-align: center;
  font-variant-numeric: lining-nums tabular-nums;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: 'Rubik', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.Event {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 450px;
  margin: 0 auto;

  &__Icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 100px;
    border-radius: 50%;

    svg {
      width: 60px;
      height: 60px;
    }

    &_green {
      background: $light-green;
    }

    &_red {
      background: #FCC6C6;
    }
  }

  &__Title {
    margin-top: 48px;
    font-family: 'Gilroy', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    text-align: center;
    color: $dark-text;
  }

  &__Subtitle {
    margin-top: 16px;
    font-family: 'Gilroy', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    text-align: center;
    color: $dark-text;
  }
}

.ToDownButton {
  position: absolute;
  right: 15px;
  bottom: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  color: $white;
  background: $dark-text;
  border-radius: 50%;
  cursor: pointer;
  border: none;
  transition: 0.3s;

  &:hover {
    opacity: 0.8;
  }
}

// Hack to push messages to the bottom of the block if scrolling is not enabled due to the number of messages
.HiddenMessage {
  margin-top: auto;
}