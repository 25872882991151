
.Form {
  display: flex;
  flex-direction: column;
  row-gap: 0;
  flex-grow: 1;
  height: 100%;
}

.Actions {
  display: flex;
  height: 60px;
  grid-gap: 8px;
  margin-top: auto;

  button {
    height: 100%;
  }
}