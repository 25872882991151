@import "../../../../../../../../app/global-styles/vars";
@import "../../../../../../../../shared/ui-kit-2/_constants/colors";
@import "../../../../../../../../shared/ui-kit-2/_constants/typography";

.Container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: max-content;
  padding: 32px 32px 39px 32px;
  background-size: 230px;
  opacity: 0.3;
  pointer-events: none;

  @media (max-width: $lk-mobile-l) {
    height: 340px;
  }
}

.BankCards {
  display: flex;
  flex-direction: column;
  min-height: 370px;
  padding: 32px 32px 25px 32px;
}

.Title {
  font-family: Gilroy, sans-serif;
  font-size: 27px;
  font-style: normal;
  font-weight: 700;
}

.Button {
  width: 184px;
  height: 48px;
  z-index: 3;

  button {
    height: 48px;
  }
}

.BankCardsList {
  margin-top: 16px;
  overflow-y: auto;
  height: 306px;
}

.BankCardsIcon {
  position: absolute;
  right: -40px;
  width: 281px;
  height: 252px;
  top: 40px;
  z-index: 0;
  svg {
    width: 320px;
    height: 300px;
  }
}

.DevelopInfo {
  position: absolute;
  background: $green;
  border-radius: 20px;
  padding: 5.5px 8px;
  right: 15px;
  color: $white;
  z-index: 2;
  @include font-gilroy-12;
  top: 32px;

  @media (max-width: $lk-mobile-l) {
    top: 16px;
  }
}