@import "../../../../../../shared/ui-kit-2/_constants/colors";
@import "../../../../../../shared/ui-kit-2/_constants/typography";
@import '../../../../../../app/global-styles/vars.scss';

.Container {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-top: 36px;
  flex-grow: 1;
}

.InputGrid {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  column-gap: 16px;
  row-gap: 16px;
  margin-top: 24px;

  @media (max-width: $lk-mobile-xl) {
    width: 100%;
    grid-template-columns: 1fr;
  }
}

.Footer {
  margin-top: auto;
  display: flex;
  align-items: center;
  flex-direction: row;;
  justify-content: space-between;
}

.BackAndSave {
  display: flex;
  flex-direction: row;
  column-gap: 56px;
  align-items: center;
  justify-content: center;
}

.Actions {
  display: flex;
  align-items: center;
  grid-gap: 16px;

  button {
    width: 221px;
    height: 60px;
  }
}

.CheckBoxLegal {
  margin: 24px 0;

  label {
    cursor: pointer;
    align-items: center;

    div:nth-child(1) {
      @include font-gilroy-20-bold;
      color: $dark-text;
      margin: 0;
    }
  }
}

.CategoryTitle {
  font-family: Gilroy, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  color: $dark-text;
}

.DeliveryAddress {
  margin-bottom: 24px;
}

.Error {
  @include font-rubik-14;
  color: $red-alert;

  @media (max-width: $lk-mobile-xl) {
    margin-bottom: 16px;
  }
}