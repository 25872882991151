@import "../../../../app/global-styles/vars";

.LandingButton {
  height: 60px;
  padding: 20px 24px;
  border-radius: 22px;
  font-size: 16px;

  @media screen and (max-width: $landing-screen-laptop) {
    height: calc(55rem / $rem-mobile);
    font-size: calc(14rem / $rem-mobile);
  }
}
