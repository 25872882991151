@import "../../../../../../shared/ui-kit-2/_constants/typography";
@import "../../../../../../shared/ui-kit-2/_constants/colors";


.Form {
  display: flex;
  flex-direction: column;
}

.FormFields {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.PasswordFields {
  display: flex;
  flex-direction: column;
  row-gap: 16px;

  & > div {
    width: 100%;
  }
}

.AgreementLink {
  @include font-rubik-14;
  font-weight: 700;
  color: $dark-text;
  text-decoration: underline;
  cursor: pointer;
}

.SignUpButtonAdditional {
  width: 100% !important;
  margin-top: 24px;
}