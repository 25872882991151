@import '../../../../../../shared/ui-kit-2/_constants/colors.scss';
@import '../../../../../../shared/ui-kit-2/_constants/typography.scss';

.CardAdditional {
  position: relative;
  padding: 24px 16px 16px 16px;
  min-height: calc(100vh - 223px);
  overflow: auto;
}

.Header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}

.HeaderLeft {
  display: flex;
  flex-direction: row;
  align-items: center;

  &__title {
    margin-left: 8px;
    @include font-gilroy-24;
    color: $dark-text;
  }

  &__counterAdditional {
    margin-left: 24px;
  }
}

.CreateBtnAdditional {
  width: 100%;
  padding: 0 35px 0 28px;
  margin-top: auto;
  min-height: 48px;
  z-index: 1;
}

.ScrollableContent {
  overflow: auto;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  margin-bottom: 10px;
}