@import "../../../../../shared/ui-kit-2/_constants/typography";
@import "../../../../../shared/ui-kit-2/_constants/colors";

.Header {
  display: flex;
  align-items: center;
  flex-direction: row;
  column-gap: 24px;
  justify-content: flex-start;

  &__date {
    @include font-gilroy-14;
    color: $dark-text;
  }

  &__sentAndReceived {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      column-gap: 16px;

    &__item {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      column-gap: 8px;
      @include font-rubik-12;
      color: $text-gray;
    }
  }
}

.Time {
  @include font-gilroy-14;
  color: $dark-text;
}

.Contact {
  display: flex;
  flex-direction: column;
  row-gap: 3px;

  &__name {
    @include font-gilroy(12px, 120%, 500);
    color: $text-gray;
    max-width: 117px;
    word-break: break-word;

    &_device {
      color: $green;
    }
  }

  &__phone {
    @include font-gilroy-14;
    color: $dark-text;
  }
}

.Actions {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 8px;
}

.Action {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $text-gray;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    opacity: 0.7;
  }
}