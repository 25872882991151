@import "../../../../app/global-styles/vars";
@import "../../../../shared/ui-kit-2/_constants/colors";

.CardAdditional {
  overflow: hidden;
  min-height: calc(100vh - 120px);
  margin-bottom: -114px;
  position: relative;

  @media (max-width: $lk-mobile-l) {
    min-height: calc(100vh - 157px);
    margin-bottom: 4px;
  }
}

.Header {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  justify-content: space-between;
  align-items: center;
  padding: 28px 24px 13px 24px;

  @media (max-width: $lk-mobile-l) {
    display: flex;
    flex-direction: column;
    align-items: start;
    row-gap: 16px;
    padding: 24px 16px;

    .Button {
      width: 100%;
      height: 40px;
      margin-right: 0;
    }

    .Title {
      margin-right: 12px;
      font-size: 24px;
    }

    .Col {
      display: flex;
      flex-direction: row;
      column-gap: 6px;
      width: 100%;

      &_row {
        flex-direction: column;
        grid-gap: 8px;
      }
    }
  }
}

.Title {
  margin-right: 17px;
  font-family: Gilroy, sans-serif;
  font-size: 27px;
  font-style: normal;
  font-weight: 700;
}

.Col {
  display: flex;
  align-items: center;
}

.Button {
  width: auto;
  min-width: 210px;
  height: 48px;
  margin-right: 8px;
  padding: 0 16px;

  &:last-child {
    margin-right: 0;
  }
}

.DeviceListMobile {
  position: relative;

  @media (max-width: $lk-mobile-l) {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0 16px 57px 16px;
    overflow: auto;
    &__Item {
      margin-bottom: 8px;
      padding: 16px;
      border-radius: 20px;
      background: $sidebar-link-active;
    }

    &__TopRow {
      display: flex;
      flex-direction: row;
      column-gap: 5px;
      justify-content: space-between;
      margin-bottom: 4px;
    }

    &__Title {
      font-family: Gilroy, sans-serif;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      word-break: break-word;
    }

    &__Number {
      color: $text-gray;
      font-family: Gilroy, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 120%;
    }

    &__Divider {
      width: 100%;
      height: 1px;
      margin: 16px 0 13px;
      background: $border-gray;
    }

    &__Row {
      display: flex;
      align-items: center;
      justify-content: center;
      white-space: nowrap;
      font-family: Rubik, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
    }

    &__Icon {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 8px;
    }

    &__Cols {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__Col {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      grid-gap: 4px;
    }

    &__Label {
      margin-bottom: 8px;
      font-family: Gilroy, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 1.2;
    }

    &__Button {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-top: 24px;

      button {
        width: 93px;
        height: 100%;
      }
    }
  }
}