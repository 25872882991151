@import "../../../../../../../../app/global-styles/vars";
@import "../../../../../../../../shared/ui-kit-2/_constants/colors";
@import "../../../../../../../../shared/ui-kit-2/_constants/typography";

.Container {
  @media (max-width: $lk-mobile-l) {
    .Empty {
      background: no-repeat url("assets/bank-cards.svg") right -21px bottom 15px;
      background-size: 160px;
      padding: 16px;
    }

    .Action {
      width: 114px;
      height: 40px;
    }

    .SubText {
      max-width: 62%;
      @include font-rubik-14;
    }
  }
}

.Empty {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 370px;
  padding: 32px 32px 39px 32px;
  background: no-repeat url("assets/bank-cards.svg") right 0 bottom 0;
  background-size: 230px;
}

.BankCards {
  display: flex;
  flex-direction: column;
  height: 258px;
  padding: 32px 32px 25px 32px;
}

.Row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Title {
  @include font-gilroy-27;
  color: $dark-text;
}

.Action {
  width: 184px;
  height: 48px;

  button {
    height: 100%;
  }
}

.SubText {
  max-width: 296px;
  margin-bottom: 7px;
  color: $dark-text;
  @include font-rubik-16;
}

.BankCardsList {
  margin-top: 16px;
  height: 306px;
}

.PaymentMethod {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 69px;
  padding: 21px 16px 20px 20px;
  background: $sidebar-link-active;
  border-radius: 16px;
  color: $dark-text;

  &:not(:last-child) {
    margin-bottom: 8px;
  }

  &Name {
    max-width: 320px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    @include font-gilroy-27;
  }

  &Actions {
    display: flex;
    align-items: center;
    grid-gap: 16px;
  }

  &Trash {
    min-width: 0;
    width: auto;
    background: transparent;
    transition: 0.3s;

    &:hover {
      opacity: 0.7;
    }
  }
}