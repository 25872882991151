@import "../../../../shared/ui-kit-2/_constants/colors";
@import "../../../../shared/ui-kit-2/_constants/typography";
@import "../../../../shared/ui-kit-2/_constants/transition-mixins";

.Container {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  padding: 16px 16px 16px 16px;
  border-radius: 20px;
  background: $sidebar-link-active;

  &_error {
    border: 1px solid $red-alert;
  }
}

.Header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 16px;
  box-sizing: border-box;
  border-bottom: 1px solid $border-gray;

  &_all {
    border-bottom: none;
    padding-bottom: 0;
  }
}

.Title {
  display: flex;
  flex-direction: row;
  align-items: center;
  @include font-rubik-16;
  color: $dark-text;
}

.Content {
  display: flex;
  flex-direction: row;
  row-gap: 8px;
  flex-wrap: wrap;
  padding-bottom: 8px;
}

.Text {
  margin-right: 6px;
}

.Item {
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  align-items: center;
  justify-content: center;
  background: $white;
  border-radius: 15px;
  padding: 8px 16px;
  @include font-rubik-14;
  line-height: 106%;

  &__close {
    cursor: pointer;
  }
}