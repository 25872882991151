@import '../../../../../../../../app/global-styles/vars';


.Container {
  display: flex;
  flex-direction: row;
  column-gap: 16px;
  min-height: calc(100vh - 114px - 6px);
  margin-bottom: -114px;

  @media (max-width: $lk-tablet) {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    height: auto;
    min-height: auto;
    margin-bottom: 0;
  }
}