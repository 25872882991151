@import '../../../../../shared/ui-kit-2/_constants/colors.scss';
@import '../../../../../shared/ui-kit-2/_constants/typography.scss';
@import "../../../../../app/global-styles/vars";

.GridRedirects {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 8px;

  @media (max-width: $lk-mobile-l) {
    grid-template-columns: 1fr;
  }
}

.Redirect {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  height: 135px;
  padding: 16px;
  background: #EEEEF7;
  border-radius: 20px;

  &__title {
    font-family: Rubik, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
  }
}

.RedirectActions {
  display: flex;
  flex-direction: row;
  column-gap: 16px;
  margin-top: auto;
  justify-content: space-between;
  align-items: center;
}

.RedirectionType {
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  align-items: center;
  justify-content: center;
  color: $dark-text;
  @include font-rubik-14;
}

.RedirectionDetailsBtn {
  height: 34px;
  @include font-rubik-14;
  padding: 0 16px;
  width: max-content;
  border-radius: 13px;
}

.EmptyRedirects {
  display: flex;
  flex-direction: column;
  width: 100%;
  color: $text-gray;
  font-family: Rubik, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
}