@import "../../../../../../../../shared/ui-kit-2/_constants/typography";
@import "../../../../../../../../shared/ui-kit-2/_constants/colors";

.CardAdditional {
  position: relative;
  width: 100%;
  padding: 24px 16px;
  overflow: hidden;
}

.Header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}

.HeaderLeft {
  display: flex;
  flex-direction: row;
  align-items: center;

  &__title {
    margin-left: 8px;
    @include font-gilroy-24;
    color: $dark-text;
  }

  &__counterAdditional {
    margin-left: 24px;
  }
}

.CreateBtnAdditional {
  width: max-content;
  padding: 0 35px 0 28px;
  z-index: 1;
}

.ScrollableContent {
  overflow: auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(333px, 1fr));
  gap: 10px;
}