@import "../../../../../../../shared/ui-kit-2/_constants/colors";
@import "../../../../../../../shared/ui-kit-2/_constants/typography";

.Container {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  column-gap:  8px;
}

.Button {
  margin-top: auto;
}