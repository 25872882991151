@import "../../../../../../../shared/ui-kit-2/_constants/typography";
@import "../../../../../../../shared/ui-kit-2/_constants/colors";
@import '../../../../../../../app/global-styles/vars.scss';

.CardAdditional {
  width: 100%;
  max-width: 540px;
  padding: 24px 24px 44px 24px;
  position: relative;
  @media (max-width: $lk-tablet) {
    max-width: 100%;
  }
}

.Title {
  @include font-gilroy-27;
  color: $dark-text;
  padding-bottom: 24px;
}

.ScrollableContent {
  overflow-y: auto;
  padding-bottom: 24px;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}

.LoadMoreBtn {
  min-height: 60px;
}