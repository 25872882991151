@import '../../../../shared/ui-kit-2/_constants/typography.scss';
@import '../../../../shared/ui-kit-2/_constants/colors.scss';

.Container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 60px;
  padding: 12px 16px;
  position: fixed;
  z-index: 4;
  width: 100%;
  align-items: center;
}

.Actions {
  display: flex;
  flex-direction: row;
  column-gap: 16px;
  align-items: center;
}

.JivoChat {
  cursor: pointer;
  color: $green-dark;

  &:hover svg, &:focus-within svg {
    path:first-child {
      stroke: currentColor;
    }

    path:nth-last-child(-n+2) {
      fill: currentColor;
    }
  }
}

.Logo {
  cursor: pointer;
}