@import "../../../../../shared/ui-kit-2/_constants/colors";
@import "../../../../../shared/ui-kit-2/_constants/typography";
@import "../../../../../app/global-styles/vars";

@keyframes threeD-appearance {
  from {
    opacity: 0;
    filter: blur(10px);
    top: 50px;
  }
  to {
    opacity: 1;
    filter: blur(0);
    top: 0;
  }
}

@keyframes appearance {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.Card {
  width: 25%;
  overflow: hidden;

  @media screen and (max-width: $lk-mobile-l) {
    width: 100%;
    margin-bottom: 50px;
  }
}

.Content {
  height: 76vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;;
  position: relative;
  padding: 31px;
}

.Loader {
  height: 540px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.Canvas {
  height: 60vh;
  cursor: pointer;
  border-radius: 20px;
  animation: threeD-appearance 1.5s ease;
}

.RotateTip {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: auto;
  width: 100%;
  margin-bottom: 24px;

  &__text {
    margin-bottom: 3px;
    @include font-rubik(18px, 24px, 400);
    letter-spacing: -0.72px;
    color: $dark-text;
  }

  &__icon {
    width: 29px;
    height: 17px;
  }
}

.AboutBtn {
  max-width: 232px;
}

.About {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 24px;
  border-radius: 26px;
  box-shadow: 0 6px 24.2px 0 rgba(0, 0, 0, 0.21);
  background: $white-bg;
  z-index: 2;
  animation: appearance 0.2s ease;

    display: flex;
  flex-direction: column;

  &__header {
    display: flex;
    align-items: center;
    flex-direction: row;;
    justify-content: space-between;
    margin-bottom: 16px;

    &__title {
      @include font-gilroy-27;
      color: $dark-text;
    }

    &__close {
      width: 13px;
      height: 13px;
      cursor: pointer;
    }
  }

  &__description {
    @include font-rubik-16;
    line-height: 150%;
    color: $dark-text;
    margin-bottom: 24px;
  }
}