@mixin font-params($font-family, $font-size, $line-height, $font-weight) {
  font-family: $font-family, sans-serif;
  font-size: $font-size;
  font-style: normal;
  font-weight: $font-weight;
  line-height: $line-height;
}

@mixin font-gilroy($fz, $lh, $fw) {
  @include font-params(Gilroy, $fz, $lh, $fw);
}

@mixin font-gilroy-68 {
  @include font-params(Gilroy, 68px, 110%, 700);
}

@mixin font-gilroy-48 {
  @include font-params(Gilroy, 48px, 100%, 700);
}

@mixin font-gilroy-14 {
  @include font-params(Gilroy, 14px, 120%, 500);
}

@mixin font-gilroy-12 {
  @include font-params(Gilroy, 12px, 14.32px, 600);
}

@mixin font-gilroy-14-bold {
  @include font-params(Gilroy, 14px, 120%, 700);
}

@mixin font-gilroy-16 {
  @include font-params(Gilroy, 16px, 150%, 700);
}

@mixin font-gilroy-18 {
  @include font-params(Gilroy, 18px, 100%, 700);
}

@mixin font-gilroy-20 {
  @include font-params(Gilroy, 20px, 120%, 600);
}

@mixin font-gilroy-20-thin {
  @include font-params(Gilroy, 20px, 120%, 500);
}

@mixin font-gilroy-20-bold {
  @include font-params(Gilroy, 20px, 24px, 700);
}

@mixin font-gilroy-29-bold {
  @include font-params(Gilroy, 29px, 31.9px, 700);
}

@mixin font-gilroy-24 {
  @include font-params(Gilroy, 24px, 100%, 700);
}

@mixin font-gilroy-27 {
  @include font-params(Gilroy, 27px, 100%, 700);
}

@mixin font-rubik($fz, $lh, $fw) {
  @include font-params(Rubik, $fz, $lh, $fw);
}

@mixin font-rubik-16 {
  @include font-params(Rubik, 16px, 106%, 400);
}

@mixin font-rubik-18 {
  @include font-params(Rubik, 18px, 120%, 400);
}

@mixin font-rubik-14 {
  @include font-params(Rubik, 14px, 120%, 400);
}

@mixin font-rubik-12 {
  @include font-params(Rubik, 12px, 120%, 400);
}

@mixin font-rubik-13 {
  @include font-params(Rubik, 13px, 120%, 400);
}