@import '../../../../../../../../shared/ui-kit-2/_constants/colors.scss';
@import '../../../../../../../../shared/ui-kit-2/_constants/typography.scss';
@import '../../../../../../../../app/global-styles/vars.scss';


.CardAdditional {
  width: 100%;
  padding: 24px 0 0 0;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  @media (max-width: $lk-tablet) {
    height: max-content;
    min-height: auto;
  }
}

.Header {
  width: 100%;
  display: flex;
  flex-direction: row;
  column-gap: 16px;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
}

.HeaderTitle {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  column-gap: 16px;
  @include font-gilroy-24;
  color: $dark-text;
}

.HeaderActions {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  column-gap: 8px;
}

.SearchInputAdditional {
  min-width: 185px;
}

.AddContactBtnAdditional {
  width: max-content;
  padding: 0 20px;
}