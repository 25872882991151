@import '../../../shared/ui-kit-2/_constants/colors.scss';
@import '../../../shared/ui-kit-2/_constants/typography.scss';

.Button {
  display: flex;
  padding: 13px 0;
  align-items: center;
  border: none;
  border-radius: 20px;
  background: $green;
  flex-direction: row;
  column-gap: 8px;
  height: 50px;
  width: 100%;
  cursor: pointer;
  justify-content: center;

  &:hover {
    background: $green-dark;
  }

  &__text {
    color: $white;
    @include font-gilroy-14;

  }

  &__icon {
    width: 16px;
    height: 16px;
  }
}