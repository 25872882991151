@import "../../../shared/ui-kit-2/_constants/colors";
@import "../../../shared/ui-kit-2/_constants/typography";
@import "../../../app/global-styles/vars";

.Container {
  display: flex;
  flex-direction: column;
  height: 100%;

  @media (max-width: $lk-mobile-l) {
    align-items: center;
  }

  @media (max-width: $lk-tablet) {
    align-items: center;
  }

}

.Image {
  width: 100%;

  @media (max-width: $lk-mobile-l) {
    width: 100%;
    filter: blur(3px);
    max-width: 445px;
  }

  @media (max-width: $lk-tablet) {
    width: 100%;
    max-width: 345px;
  }

}

.Content {
  margin-top: auto;
  display: flex;
  flex-direction: column;
  row-gap: 16px;

  @media (max-width: $lk-mobile-l) {
    margin-top: 0;
  }

}

.Title {
  @include font-gilroy-27;
  color: $dark-text;
}

.Description {
  @include font-rubik-16;
  color: $text-gray;
}
