@import "../../../../../../../../shared/ui-kit-2/_constants/typography";
@import "../../../../../../../../shared/ui-kit-2/_constants/colors";

.Time {
  @include font-gilroy-14;
  color: $dark-text;
}

.Counter div {
  font-size: 14px !important;
}

.Actions {
  display: flex;
  grid-gap: 8px;
  align-items: center;
}

.Action {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $text-gray;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    opacity: 0.7;
  }
}