@import "../../../../../../shared/ui-kit-2/_constants/colors";
@import "../../../../../../shared/ui-kit-2/_constants/typography";
@import "../../../../../../shared/ui-kit-2/_constants/transition-mixins";

@keyframes use-add-contact-overlay-appearance {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.5;
  }
}

.Container {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  margin-top: 16px;
}

.GrayCard {
  padding: 24px 16px 0 16px;
  background: $sidebar-link-active;
  border-radius: 20px;
}

.Header {
  display: flex;
  flex-direction: row;
  height: 38px;
  width: 100%;
  position: relative;
  border-bottom: 1px solid $border-gray;
  align-items: flex-start;
  justify-content: flex-end;
}

.TabsAdditional {
  position: absolute;
  left: 0;
  top: 0;
}

.Form {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  position: relative;
}

.FormOverlay {
  cursor: default;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: $sidebar-link-active;
  animation: use-add-contact-overlay-appearance 0.2s ease;
  opacity: 0.5;
  pointer-events: unset;
  z-index: 999;
}

.ScrollableContent {
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  min-height: 195px;
  max-height: 195px;
  overflow-y: auto;
}

.ContactCheckbox {
  max-width: none !important;
  cursor: pointer;
  padding: 10px 0;
  border-bottom: 1px solid $border-gray;

  &:last-child {
    border-bottom: none;
  }
}