@import "../../shared/ui-kit-2/_constants/colors";
@import "../../shared/ui-kit-2/_constants/typography";
@import '../../app/global-styles/vars.scss';

.Container {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: var(--app-height);
  background: $bg-gray-2;

  @media (max-width: $lk-mobile-l) {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 100%;
    padding: 0 16px;
  }
}

.Showcase {
  display: flex;
  flex-direction: column;
  row-gap: 64px;
  flex-grow: 1;
}

.Title {
  width: 100%;
  max-width: 343px;
  @include font-gilroy-29-bold;
  color: $dark-text;
  z-index: 2;

  & > span {
    color: $green;
  }
}

.HeaderContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: 16px;
  justify-content: center;
  align-items: center;
}

.Header {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 60px;
}


.VideoContainer {
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.Video {
  position: absolute;
  width: 100%;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.VideoOverlay {
  position: absolute;
  bottom: 0;
  padding-bottom: 49.69%;
  width: 100%;
  height: max-content;
  background: linear-gradient(90deg, rgba(235, 235, 245, 0) 0.1%, rgba(235, 235, 245, 0) 92.8%, #EBEBF5 99.92%), linear-gradient(0deg, rgb(235, 235, 245) 0%, rgba(235, 235, 245, 0) 75.99%, #EBEBF5 100.25%);
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}