@import "../../../../../shared/ui-kit-2/_constants/colors";
@import "../../../../../shared/ui-kit-2/_constants/typography";

.Container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.Actions {
  margin-top: auto;
  gap: 8px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
}

.ButtonFullWidth {
  grid-column: span 2;
}