@import '../../../../shared/ui-kit-2/_constants/typography.scss';
@import '../../../../shared/ui-kit-2/_constants/colors.scss';

.Banner {
  margin-top: auto;
  border-radius: 20px;
  width: 178px;
  height: 168px;
  background: $yellow;
  overflow: hidden;
  position: relative;
  padding: 16px;
}

.BannerInfo {
  display: flex;
  flex-direction: column;
  margin-top: 66px;
  row-gap: 8px;
  width: 100%;
}

.Title {
  @include font-gilroy-18;
  color: $dark-text;
}

.DescriptionAndIcon {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  color: $dark-text;
  @include font-rubik-12;

  div {
    width: 153px;
  }

  svg {
     cursor: pointer;
     width: 29px;
     height: 15px;
     align-self: end;
  }
}

.Svg {
  position: absolute;
  transform: rotate(-63deg);
  top: -81px;
  right: 36px;

  svg {
    width: 113px;
    height: 176px;
  }
}