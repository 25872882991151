@import '../../../../../../../../shared/ui-kit-2/_constants/typography.scss';
@import '../../../../../../../../shared/ui-kit-2/_constants/colors.scss';

.CardAdditional {
  position: relative;
  width: 462px;
  min-width: 462px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 32px 24px;
  overflow: hidden;
  min-height: 629px;
}

.Content {
  z-index: 3;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.Header {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}

.Title {
  @include font-gilroy-27;
  color: $dark-text;
}

.Description {
  @include font-rubik-14;
  color: $dark-text;
}

.Form {
  margin-top: auto;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.ImeiInputContainer {
  display: flex;
  flex-direction: column;
  row-gap: 8px;

  &__label {
    @include font-rubik-14;
    color: $dark-text;
  }
}

.SubmitBtn {
  margin-top: 21px;
}

.VideoContainer {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 360px;
  overflow: hidden;
}

.Video {
  width: 462px;
  position: absolute;
  top: -120px;
}

.Mask {
  z-index: 2;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: -37px;
  background: linear-gradient(180deg, #F6F6FB 0%, rgba(245, 245, 250, 0) 40%, #F6F6FB 100%);
}