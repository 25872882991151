@import "../../../shared/ui-kit-2/_constants/typography.scss";
@import "../../../shared/ui-kit-2/_constants/colors.scss";
@import "../../../app/global-styles/vars";

.NoData {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;;
  align-items: flex-start;
  width: 100%;
  height: 400px;

  @media (max-width: $lk-mobile-l) {
    margin-top: 16px;
    justify-content: center;
    align-items: center;

    .Label {
      margin-top: 240px;
      margin-bottom: 16px;
      font-size: 31px;
      text-align: center;
    }

    .Image {
      width: 100%;
      left: 8%;
      scale: 1.5;
      filter: none;
    }
  }
}

.Image {
  position: absolute;
  right: -150px;
  top: 50px;
  max-width: 846px;
  filter: blur(3px);
}

.Label {
  max-width: 454px;
  margin-top: 37px;
  margin-bottom: 20px;
  text-align: left;
  @include font-gilroy-48;
  color: $dark-text;
  z-index: 2;
}

.Button {
  z-index: 2;
  max-width: 196px;
}