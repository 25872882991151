@import '../../../../../../app/global-styles/vars.scss';

.Container {
  display: flex;
  flex-direction: row;
  column-gap: 16px;

  @media (max-width: $lk-tablet) {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
  }
}