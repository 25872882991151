@import "../../../../shared/ui-kit-2/_constants/colors";
@import "../../../../shared/ui-kit-2/_constants/typography";
@import "../../../../shared/ui-kit-2/_constants/transition-mixins";
@import '../../../../app/global-styles/vars.scss';

@keyframes appearance {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes card-appearance {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.Overlay {
  position: absolute;
  display: flex;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 30px;
  z-index: 9999;
  animation: appearance 0.2s ease;
}

.CardAdditional {
  display: flex;
  justify-content: end;
  flex-direction: column;
  padding: 24px 24px 32px 24px;
  margin-top: auto;
  position: fixed;
  left: 0;
  bottom: 0 !important;
  width: 100%;
  z-index: 999;
  box-shadow: 0 6px 24.2px 0 rgba(0, 0, 0, 0.21);
  animation: card-appearance 0.2s ease;

  @media (max-width: $lk-tablet) {
    bottom: initial;
  }
}

.Header {
  display: flex;
  justify-content: end;
  width: 100%;
  flex-direction: row;
  margin-bottom: 24px;
  align-items: center;
}

.Close {
  cursor: pointer;
}

.Image {
  width: 200px;
  display: flex;
  flex-direction: column;
  margin: 0 auto 100px;
}

.Description {
  @include font-gilroy-27;
  color: $dark-text;
  margin: 0 auto;
  max-width: 413px;
}

.Actions {
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  margin-top: 32px;
  button {
    height: 50px;
  }
}