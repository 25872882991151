@import '../../../../../shared/ui-kit-2/_constants/colors.scss';
@import "../../../../../app/global-styles/vars";

.Container {
  margin-top: 120px;
  color: #fff;
}

.Wrapper {
  position: relative;
  width: 1280px;
  margin: 0 auto;
  padding: 50px 50px 64px 50px;
  overflow-x: hidden;

  @media screen and (max-width: $landing-screen-laptop) {
    width: 100%;
    padding: 12.8rem 6.4rem 8.5rem;
    border-radius: calc(30rem / $rem-mobile);
  }
}

.Background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 30px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
  }
}

.Title {
  position: relative;
  max-width: 775px;
  margin-bottom: 48px;
  color: #202D40;
  font-family: Gilroy, sans-serif;
  font-size: 80px;
  font-style: normal;
  font-weight: 700;
  z-index: 1;
}

.Cards {
  position: relative;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 32px;
  z-index: 1;

  div {
    min-width: unset !important;
  }
}