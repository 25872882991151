@import "../../../../../../shared/ui-kit-2/_constants/colors";
@import "../../../../../../shared/ui-kit-2/_constants/typography";
@import '../../../../../../app/global-styles/vars.scss';

.CardAdditional {
  width: 286px;
  min-width: 286px;
  min-height: inherit;
  height: 100%;
  padding: 35px 8px 8px 8px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;

  @media (max-width: $lk-mobile-xl) {
    width: 100%;
    padding: 20px 16px;
  }
}

.NestedContainer {
  display: flex;
  flex-direction: column;
  padding: 0 24px;

  @media (max-width: $lk-mobile-xl) {
    padding: 0;
  }
}

.Title {
  @include font-gilroy(28px, 110%, 700);
  letter-spacing: -0.56px;
  color: $dark-text;

  span {
    color: $green;
  }
}

.DetailList {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  width: 100%;
  margin-top: 60px;

  @media (max-width: $lk-mobile-xl) {
    flex-direction: row;
    justify-content: space-between;
    margin-top: 30px;
    column-gap: 8px;
  }
}

.TotalContainer {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  margin-top: auto;

  @media (max-width: $lk-mobile-xl) {
    margin-top: 24px;
  }
}

.EditBtn {
  width: 100%;
}

.Total {
  height: 97px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  column-gap: 16px;
  @include font-gilroy(18px, 120%, 700);
  color: $dark-text;
  background: $bg-gray-2;
  border-radius: 23px;

  @media (max-width: $lk-mobile-xl) {
    height: 71px;
  }
}