@import '../../../../app/global-styles/vars.scss';
@import '../../../../shared/ui-kit-2/_constants/typography.scss';
@import '../../../../shared/ui-kit-2/_constants/colors.scss';
@import '../../../../shared/ui-kit-2/_constants/transition-mixins.scss';
@import '../../../../app/global-styles/vars.scss';

.Overlay {
  position: absolute;
  display: flex;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 30px;
  z-index: 9999;
  animation: appearance 0.2s ease;
}

.CardAdditional {
  display: flex;
  flex-direction: column;
  padding: 24px 24px 32px 24px;
  row-gap: 32px;
  margin-top: auto;
  position: sticky;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 999;
  box-shadow: 0 6px 24.2px 0 rgba(0, 0, 0, 0.21);
  animation: card-appearance 0.2s ease;

  @media (max-width: $lk-tablet) {
    bottom: initial;
  }
}

.Header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.Title {
  @include font-gilroy-27;
  letter-spacing: -0.56px;
  color: $dark-text;
  display: flex;
  flex-direction: row;
  column-gap: 14px;
  align-items: center;
}

.Close {
  cursor: pointer;
}

.PaymentsWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  column-gap: 16px;
  width: 100%;

  @media (max-width: $lk-mobile-l) {
    flex-direction: column;
    row-gap: 8px;
  }
}