@import '../../../shared/ui-kit-2/_constants/colors.scss';
@import '../../../shared/ui-kit-2/_constants/typography.scss';

.Content {
  display: flex;
  flex-direction: column;
  row-gap: 54px;
  align-items: center;
  margin-top: 102px;

  &_title {
    color: $dark-text;
    text-align: left;
    width: 100%;
    align-self: flex-start;
    max-width: 338px;
    @include font-gilroy-48;
  }

  &__subTitle {
    @include font-rubik-16;
    color: $dark-text;
  }
}

.Button {
  margin-top: auto;
}