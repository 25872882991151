@import "../../../../../../../../shared/ui-kit-2/_constants/colors.scss";
@import "../../../../../../../../shared/ui-kit-2/_constants/typography.scss";
@import "../../../../../../../../shared/ui-kit-2/_constants/transition-mixins";
@import '../../../../../../../../app/global-styles/vars.scss';

.PaymentMethodCard {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: $white-bg;
  border-radius: 20px;
  padding: 16px 24px 26px 24px;
  height: 192px;
  max-height: 100%;
  width: 100%;
  box-shadow: 0 6px 24.2px 0 rgba(0, 0, 0, 0.07);
  cursor: pointer;
  @include transition-default(box-shadow);

  &:hover {
    outline: 1px solid $green;
  }

  &_active {
    outline: 1px solid $green;
    box-shadow: none;
  }

  &_disabled {
    cursor: default;

    &:hover {
      outline: none;
    }
  }

  @media screen and (max-width: $lk-mobile-xl) {
    height: 122px;
    padding: 16px;
  }
}

.TitleAndRadio {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  width: 100%;
  justify-content: space-between;

  &__title {
    @include font-gilroy-20;
    color: $dark-text;
  }

  &__checkbox {
    width: 16px;
    height: 16px;
    border-radius: 100%;
    background: $bg-gray;
    position: relative;
    min-width: 16px;

    &__active {
      &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 10px;
        height: 10px;
        background: $green;
        border-radius: 100%;
        transform: translate(-50%, -50%);
      }
    }
  }
}

.ChildrenPaymentCard {

}