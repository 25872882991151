@import '../../../shared/ui-kit-2/_constants/colors.scss';
@import '../../../shared/ui-kit-2/_constants/typography.scss';

.Button {
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  align-items: center;
  justify-content: center;
  @include font-rubik-14;
  width: max-content;
  border-radius: 25px;
  cursor: pointer;
  border: 1px dashed $border-gray;
  padding: 8px 16px;
  color: $green;
  outline: none;
  background: none;
  user-select: none;
}