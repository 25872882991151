@import '../../../../../shared/ui-kit-2/_constants/colors.scss';
@import "../../../../../app/global-styles/vars";

.Container {
  position: relative;
  display: grid;
  width: 1280px;
  grid-template-columns: 55% 45%;
  align-items: center;
  margin: 86px auto 0;
  border-radius: 20px;
  background: radial-gradient(50% 50% at 50% 50%, rgba(235, 235, 245, 0.74) 0%, #EBEBF5 100%), lightgray 50% / cover no-repeat;
  overflow: hidden;
  box-shadow: 1px 1px 1px 0 rgba(255, 255, 255, 0.24) inset, 0 6px 24.2px 0 rgba(0, 0, 0, 0.07), -3px -3px 6px 0 rgba(255, 255, 255, 0.21);

  @media screen and (min-width: $landing-screen-laptop) and (max-width: $landing-screen-laptop-l) {
    width: 1058px;
    grid-template-columns: 55% 45%;

    .Info {
      font-size: 27px;
    }

    .Image {
      display: flex;
      justify-content: center;

      img {
        width: 538px;
        height: 421px;
      }
    }
  }

  @media screen and (max-width: $landing-screen-laptop) {
    width: calc(100% - 32px);
    grid-template-columns: 100%;
    margin: calc(37rem / $rem-mobile) auto 0;
    padding-right: 0;
    border-radius: calc(30rem / $rem-mobile);

    .Image {
      width: 140rem;
      height: 100rem;
      max-width: 110vw;
      margin: 0 auto;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .Info {
      margin: calc(-31rem / $rem-mobile) calc(16rem / $rem-mobile) calc(35rem / $rem-mobile) calc(16rem / $rem-mobile);
      padding: 0;
      font-size: 5.85rem;
    }
  }
}

.Col {
  position: relative;
}

.Background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
  }
}

.Image {
  img {
    width: 750px;
  }
}

.Info {
  color: $dark-text;
  font-size: 32px;
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: 500;
  line-height: 1.3;
}

.Marked {
  color: $green-dark;
}