@import "../../../../../../../../shared/ui-kit-2/_constants/typography";
@import "../../../../../../../../shared/ui-kit-2/_constants/colors";

.CardAdditional {
  margin-top: 24px;
  width: 100%;
  padding: 24px 24px 44px 24px;
  position: relative;
  height: calc(100% - 223px);
}

.Title {
  @include font-gilroy-27;
  color: $dark-text;
  padding-bottom: 24px;
}

.ScrollableContent {
  padding-bottom: 24px;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}

.LoadMoreBtn {
  min-height: 60px;
}