@import "../../../../../../../shared/ui-kit-2/_constants/typography";
@import "../../../../../../../shared/ui-kit-2/_constants/colors";
@import '../../../../../../../app/global-styles/vars.scss';

.CardAdditional {
  padding-top: 24px;
  overflow: hidden;
  position: relative;
  width: 100%;
  min-height: calc(100vh - 223px);
  @media (max-width: $landing-screen-laptop) {
    margin-top: 24px;
    width: 100%;
    padding: 24px 24px 44px 24px;
    position: relative;
    height: calc(100% - 223px);

  }
}

.Title {
  padding: 0 24px 24px 24px;
  @include font-gilroy-27;
  color: $dark-text;
}