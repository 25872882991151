@import "../../../shared/ui-kit-2/_constants/colors";

.Call {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 12px;
  min-width: 265px;
  padding: 24px;
  font-family: Gilroy, sans-serif;

  &_hasAccepted {
    min-width: 352px;
    grid-gap: 32px;
    align-items: flex-start;

    .MainInfo {
      display: flex;
      justify-content: space-between;
      width: 100%;
      min-width: max-content;
    }

    .Info {
      width: 215px;
      align-items: flex-start;
    }

    .Name {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.Info {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.TimeLeft {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-left: auto;
  color: $text-gray;
  font-family: Gilroy, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}

.Name {
  color: $dark-text;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;

  &_to {
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }
}

.PhoneNumber {
  margin-top: 3px;
  color: $text-gray;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}

.Arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  color: $green-dark;
  border: 2px solid $border-gray;
  border-radius: 50%;

  &_rotated {
    transform: rotate(180deg);
  }
}

.Actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.Action {
  margin: 0 auto;

  &__IconButton {
    color: $white;
    transition: 0.3s ease;

    &:hover {
      opacity: 0.8;
    }

    &_red {
      background-color: $red-alert;
    }

    &_green {
      background-color: $green;
    }
  }

  &__Text {
    display: flex;
    justify-content: center;
    margin-top: 8px;
    color: $text-gray;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
  }
}