@import '../../../../../shared/ui-kit-2/_constants/colors.scss';
@import "../../../../../app/global-styles/vars";

.Container {
  margin-top: 120px;
  color: $white;

  @media screen and (min-width: $landing-screen-laptop) and (max-width: $landing-screen-laptop-l) {
    .Wrapper {
      width: 1058px;
    }

    .Item {
      &__Line {
        margin-bottom: 36px;
      }

      &__Title {
        margin-bottom: 18px;
        font-size: 38px;
      }

      &__Subtitle {
        margin-bottom: 16px;
        font-size: 20px;
      }

      &__Text {
        font-size: 16px;
      }
    }
  }

  @media screen and (max-width: $landing-screen-laptop) {
    margin-top: calc(57rem / $rem-mobile);

    .Wrapper {
      width: 100%;
      padding: 12.8rem 6.4rem 8.5rem;
      border-radius: calc(30rem / $rem-mobile);
    }

    .Item {
      min-width: 60rem;

      &__Line {
        margin-bottom: 8.5rem;
      }

      &__Title {
        margin-bottom: 4.25rem;
        font-size: 8rem;
      }

      &__Subtitle {
        margin-bottom: 4.25rem;
        font-size: 5.31rem;
      }

      &__Text {
        font-size: 4.25rem;
      }
    }

    .Background {
      scale: 1.5;
    }
  }
}

.Wrapper {
  position: relative;
  width: 1280px;
  margin: 0 auto;
  padding: 50px 0 64px 50px;
  overflow-x: hidden;
}

.Background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 30px;
  overflow: hidden;

  img {
    width: 100%;
  }
}

.Title {
  position: relative;
  max-width: 775px;
  font-family: Gilroy, sans-serif;
  font-size: 87px;
  font-style: normal;
  font-weight: 700;
}
