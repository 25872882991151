@import "../../shared/ui-kit-2/_constants/colors";
@import "../../shared/ui-kit-2/_constants/typography";

.Container {
  display: flex;
  flex-direction: row;
  column-gap: 35px;
  padding: 16px 20px 16px 29px;
  border-radius: 26px;
  border: 1px solid $border-gray;
  align-items: center;
}

.Image {
  width: 62px;
  height: 41px;
}

.Text {
  @include font-rubik-16;
  color: $dark-text;
  line-height: 150%;

  a {
    color: $green-dark;
    text-decoration: none;
  }
}