@import '../../../../../shared/ui-kit-2/_constants/typography.scss';
@import '../../../../../shared/ui-kit-2/_constants/colors.scss';
@import '../../../../../app/global-styles/vars.scss';

.Container {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.ProductConditionsContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  flex-direction: row;
  column-gap: 8px;
}

.Header {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.Title {

}

.Badge {
  background: $light-green;
  border-radius: 20px;
  color: $dark-text;
  @include font-rubik-12;
  padding: 8.5px 12px;
}

.ProductContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  row-gap: 49px;
  padding: 26px;
  @include font-gilroy-27;
  color: $dark-text;
}

.ProductConditions {
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  align-items: center;
}

.ProductCondition {
  display: flex;
  flex-direction: column;
  padding: 10px 16px;
  width: 183px;
  row-gap: 13px;
  border: 1px solid $border-gray;
  border-radius: 20px;

  @media (max-width: $lk-mobile-xl) {
    padding: 10px 10px 10px 0;
  }
}

.ProductConditionTitle {
  @include font-rubik-14;
  color: $dark-text;
}

.ProductConditionPrice {
  @include font-gilroy-20-thin;
  color: $dark-text;
}

.PriceGreen {
  text-decoration: line-through;
  color: $green-dark;
  margin-left: 8px;
}

.FormContainer {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  padding: 24px;
  border: 1px solid $green;
  border-radius: 20px;
  width: 100%;
  height: 236px;
}

.FormTitle {
  @include font-gilroy-27;
  color: $dark-text;
  width: 537px;
}

.Form {
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  align-items: center;
}

.Button {
  width: 156px;
  height: 56px;
}

.ItemButton {
  padding-top: 31px;
}

.Item {
  height: 78px;
  align-self: center;
}

.ItemName {
  width: 280px;
  height: 78px;
}

.ItemPhone {
  width: 300px;
  height: 78px;
}