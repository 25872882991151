@import "../../../../../../shared/ui-kit-2/_constants/typography";
@import "../../../../../../shared/ui-kit-2/_constants/colors";
@import '../../../../../../app/global-styles/vars.scss';

.VerifySignUpContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}

.VerifySignUpContent {
  display: flex;
  flex-direction: column;
}

.VerificationContainer {
  display: flex;
  flex-direction: column;
  row-gap: 32px;
}

.BackBtn {
  padding: 0;
}

.Title {
  margin-bottom: 16px;
  @include font-gilroy-48;
  color: $dark-text;

  @media (max-width: $lk-mobile-l) {
    @include font-gilroy-27;
  }

}

.Description {
  margin-bottom: 32px;
  @include font-rubik-16;
  color: $dark-text;
}