
@import "../../../shared/ui-kit-2/_constants/colors";

.MainIMG {
  margin: 0 auto 20px;
}

.Attention {
  display: flex;
  align-items: center;
  margin-bottom: 27px;
  padding: 16px;
  border: 1px solid $border-gray;
  border-radius: 26px;
  box-shadow: 0  6px 24.2px 0 rgba(0, 0, 0, 0.07);

  &Text {
    max-width: 296px;
    margin-left: 24px;
    color: $dark-text;
    font-family: Rubik, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
  }
}

.Next {
  margin-top: auto;
}

.Content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}