@import "../../_constants/typography";
@import "../../_constants/colors";
@import "../../_constants/transition-mixins";

.Button {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 20px;
  height: 48px;
  min-height: 48px;
  width: 48px;
  min-width: 48px;
  cursor: pointer;
  border: none;

  &:disabled {
    cursor: default;
    background: $white;
    opacity: 0.5;
  }

  &_outlined {
    border: 1px solid $border-gray;
    background: transparent;
  }

  &_filled {
    background: $bg-gray-2;
  }

  &_cancel {
    background: $red-alert;
    color: $white
  }
}