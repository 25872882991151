// GILROY
@font-face {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 100;
  src:
    url('../../../public/fonts/gilroy/Gilroy-Thin.eot') format("embedded-opentype"),
    url('../../../public/fonts/gilroy/Gilroy-Thin.woff') format("woff"),
    url('../../../public/fonts/gilroy/Gilroy-Thin.ttf') format("truetype");
}
@font-face {
  font-family: 'Gilroy';
  font-style: italic;
  font-weight: 100;
  src:
    url('../../../public/fonts/gilroy/Gilroy-ThinItalic.eot') format("embedded-opentype"),
    url('../../../public/fonts/gilroy/Gilroy-ThinItalic.woff') format("woff"),
    url('../../../public/fonts/gilroy/Gilroy-ThinItalic.ttf') format("truetype");
}

@font-face {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 200;
  src:
    url('../../../public/fonts/gilroy/Gilroy-UltraLight.eot') format("embedded-opentype"),
    url('../../../public/fonts/gilroy/Gilroy-UltraLight.woff') format("woff"),
    url('../../../public/fonts/gilroy/Gilroy-UltraLight.ttf') format("truetype");
}
@font-face {
  font-family: 'Gilroy';
  font-style: italic;
  font-weight: 200;
  src:
    url('../../../public/fonts/gilroy/Gilroy-UltraLightItalic.eot') format("embedded-opentype"),
    url('../../../public/fonts/gilroy/Gilroy-UltraLightItalic.woff') format("woff"),
    url('../../../public/fonts/gilroy/Gilroy-UltraLightItalic.ttf') format("truetype");
}

@font-face {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 300;
  src:
    url('../../../public/fonts/gilroy/Gilroy-Light.eot') format("embedded-opentype"),
    url('../../../public/fonts/gilroy/Gilroy-Light.woff') format("woff"),
    url('../../../public/fonts/gilroy/Gilroy-Light.ttf') format("truetype");
}
@font-face {
  font-family: 'Gilroy';
  font-style: italic;
  font-weight: 300;
  src:
    url('../../../public/fonts/gilroy/Gilroy-LightItalic.eot') format("embedded-opentype"),
    url('../../../public/fonts/gilroy/Gilroy-LightItalic.woff') format("woff"),
    url('../../../public/fonts/gilroy/Gilroy-LightItalic.ttf') format("truetype");
}

@font-face {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 400;
  src:
    url('../../../public/fonts/gilroy/Gilroy-Regular.eot') format("embedded-opentype"),
    url('../../../public/fonts/gilroy/Gilroy-Regular.woff') format("woff"),
    url('../../../public/fonts/gilroy/Gilroy-Regular.ttf') format("truetype");
}
@font-face {
  font-family: 'Gilroy';
  font-style: italic;
  font-weight: 400;
  src:
    url('../../../public/fonts/gilroy/Gilroy-RegularItalic.eot') format("embedded-opentype"),
    url('../../../public/fonts/gilroy/Gilroy-RegularItalic.woff') format("woff"),
    url('../../../public/fonts/gilroy/Gilroy-RegularItalic.ttf') format("truetype");
}

@font-face {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 500;
  src:
    url('../../../public/fonts/gilroy/Gilroy-Medium.eot') format("embedded-opentype"),
    url('../../../public/fonts/gilroy/Gilroy-Medium.woff') format("woff"),
    url('../../../public/fonts/gilroy/Gilroy-Medium.ttf') format("truetype");
}
@font-face {
  font-family: 'Gilroy';
  font-style: italic;
  font-weight: 500;
  src:
    url('../../../public/fonts/gilroy/Gilroy-MediumItalic.eot') format("embedded-opentype"),
    url('../../../public/fonts/gilroy/Gilroy-MediumItalic.woff') format("woff"),
    url('../../../public/fonts/gilroy/Gilroy-MediumItalic.ttf') format("truetype");
}

@font-face {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 600;
  src:
    url('../../../public/fonts/gilroy/Gilroy-SemiBold.eot') format("embedded-opentype"),
    url('../../../public/fonts/gilroy/Gilroy-SemiBold.woff') format("woff"),
    url('../../../public/fonts/gilroy/Gilroy-SemiBold.ttf') format("truetype");
}
@font-face {
  font-family: 'Gilroy';
  font-style: italic;
  font-weight: 600;
  src:
    url('../../../public/fonts/gilroy/Gilroy-SemiBoldItalic.eot') format("embedded-opentype"),
    url('../../../public/fonts/gilroy/Gilroy-SemiBoldItalic.woff') format("woff"),
    url('../../../public/fonts/gilroy/Gilroy-SemiBoldItalic.ttf') format("truetype");
}

@font-face {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 700;
  src:
    url('../../../public/fonts/gilroy/Gilroy-Bold.eot') format("embedded-opentype"),
    url('../../../public/fonts/gilroy/Gilroy-Bold.woff') format("woff"),
    url('../../../public/fonts/gilroy/Gilroy-Bold.ttf') format("truetype");
}
@font-face {
  font-family: 'Gilroy';
  font-style: italic;
  font-weight: 700;
  src:
    url('../../../public/fonts/gilroy/Gilroy-BoldItalic.eot') format("embedded-opentype"),
    url('../../../public/fonts/gilroy/Gilroy-BoldItalic.woff') format("woff"),
    url('../../../public/fonts/gilroy/Gilroy-BoldItalic.ttf') format("truetype");
}

@font-face {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 800;
  src:
    url('../../../public/fonts/gilroy/Gilroy-ExtraBold.eot') format("embedded-opentype"),
    url('../../../public/fonts/gilroy/Gilroy-ExtraBold.woff') format("woff"),
    url('../../../public/fonts/gilroy/Gilroy-ExtraBold.ttf') format("truetype");
}
@font-face {
  font-family: 'Gilroy';
  font-style: italic;
  font-weight: 800;
  src:
    url('../../../public/fonts/gilroy/Gilroy-ExtraBoldItalic.eot') format("embedded-opentype"),
    url('../../../public/fonts/gilroy/Gilroy-ExtraBoldItalic.woff') format("woff"),
    url('../../../public/fonts/gilroy/Gilroy-ExtraBoldItalic.ttf') format("truetype");
}

@font-face {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 900;
  src:
    url('../../../public/fonts/gilroy/Gilroy-Black.eot') format("embedded-opentype"),
    url('../../../public/fonts/gilroy/Gilroy-Black.woff') format("woff"),
    url('../../../public/fonts/gilroy/Gilroy-Black.ttf') format("truetype");
}
@font-face {
  font-family: 'Gilroy';
  font-style: italic;
  font-weight: 900;
  src:
    url('../../../public/fonts/gilroy/Gilroy-BlackItalic.eot') format("embedded-opentype"),
    url('../../../public/fonts/gilroy/Gilroy-BlackItalic.woff') format("woff"),
    url('../../../public/fonts/gilroy/Gilroy-BlackItalic.ttf') format("truetype");
}


// RUBIK

@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  src:
    url('../../../public/fonts/rubik/Rubik-Regular.ttf') format("truetype");
}

@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 700;
  src:
    url('../../../public/fonts/rubik/Rubik-Bold.ttf') format("truetype");
}


// PLAY
@font-face {
  font-family: 'Play';
  font-style: normal;
  font-weight: 400;
  src:
    url('../../../public/fonts/play/Play-Regular.ttf') format("truetype");
}

@font-face {
  font-family: 'Play';
  font-style: normal;
  font-weight: 700;
  src:
    url('../../../public/fonts/play/Play-Bold.ttf') format("truetype");
}


// UBUNTU
@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 400;
  src:
    url('../../../public/fonts/ubuntu/Ubuntu-Regular.ttf') format("truetype");
}
