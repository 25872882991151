@import "../../../../../shared/ui-kit-2/_constants/typography";
@import "../../../../../shared/ui-kit-2/_constants/colors";
@import "../../../../../shared/ui-kit-2/_constants/transition-mixins";


.Header {
  display: flex;
  align-items: center;
  flex-direction: row;
  column-gap: 24px;
  justify-content: flex-start;

  &__date {
    @include font-gilroy-14;
    color: $dark-text;
  }

  &__sentAndReceived {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    column-gap: 16px;

    &__item {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      column-gap: 8px;
      @include font-rubik-12;
      color: $text-gray;
    }
  }
}

.Time {
  @include font-gilroy-14;
  color: $dark-text;
}

.Contact {
  display: flex;
  flex-direction: column;
  row-gap: 3px;
  justify-content: center;

  &__name {
    @include font-gilroy(12px, 120%, 500);
    color: $text-gray;
    max-width: 77px;
    word-break: break-word;

    &_device {
      color: $green;
    }
  }

  &__phone {
    @include font-gilroy-14;
    color: $dark-text;
  }
}

.RightBordered {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 34px;
  border-right: 1px solid $border-gray;
  flex-grow: 1;
}

.MessageText {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 159px;
  white-space: nowrap;
}

.Bar {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.Actions {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  column-gap: 8px;

  & > svg {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}

.ReplyButton {
  /*
  margin-right: 16px;
  */
  height: 28px;
  color: $white;
  background: $green;
  @include transition-default(background);
  border: none;
  border-radius: 20px;
  outline: none;
  padding: 0 12px;
  @include font-rubik-12;
  cursor: pointer;

  &:hover {
    background: $green-dark;
  }
}

.StatusIcons {
 /* margin-right: 23px;*/
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  column-gap: 10px;
}

.StatusIcon {
  cursor: default;

  &_active path {
    fill: $green-dark;
  }
}