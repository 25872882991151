@import "../../../../../../shared/ui-kit-2/_constants/colors";
@import "../../../../../../shared/ui-kit-2/_constants/typography";
@import "../../../../../../shared/ui-kit-2/_constants/transition-mixins";

.RadioGroupAdditional {
  margin-top: 16px;
    display: flex;
  flex-direction: row;
  column-gap: 16px;
}

.RadioAdditional {
  div {
    margin-left: 0;
  }
}

.Container {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  margin-top: 16px;
}