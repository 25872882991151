@import "../../../../../app/global-styles/vars";
@import '../../../../../shared/ui-kit-2/_constants/typography.scss';
@import '../../../../../shared/ui-kit-2/_constants/colors.scss';

.Form {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  justify-content: center;
  align-items: center;

  @media (max-width: $landing-screen-tablet) {
    align-items: flex-start;
    flex-direction: column;
    row-gap: 4px;
  }
}

.Copy {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  align-items: center;
  justify-content: space-between;
  height: 48px;
  width: 100%;

  &__field {
    @include font-rubik-14;
    line-height: 100% !important; //В макетах 100% на этот шрифт; В ките 120%;
    display: flex;
    align-items: center;
    width: 100%;
    height: 48px;
    border: 1px solid $border-gray;
    color: $text-gray;
    border-radius: 20px;
    padding: 21px 20px;
    box-sizing: border-box;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    white-space: nowrap;
  }

  &__button {
    width: 157px;
    height: 48px;
    padding: 12px;
    background: transparent;
    color: $green;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    column-gap: 8px;
    @include font-gilroy-14-bold;
    border-radius: 20px;
    border: 1px solid $green;
    cursor: pointer;

    svg {
      width: 17px;
      height: 17px;
    }
  }
}

.CopyAndButton {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  column-gap: 8px;
  height: 48px;
  width: 100%;
  max-width: 600px;
}

.Label {
  @include font-rubik-18;
  color: $dark-text;
  align-self: start;
}

.DocumentationButton {
  height: 48px;
  width: max-content;
  background: $green;
  color: $white;
  cursor: pointer;
  border: none;
  border-radius: 20px;
  min-width: 306px;
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  column-gap: 8px;
}
