@import "../../../../../../../../../shared/ui-kit-2/_constants/typography";
@import "../../../../../../../../../shared/ui-kit-2/_constants/colors";
@import "../../../../../../../../../shared/ui-kit-2/_constants/transition-mixins";

.CardAdditional {
  width: 100%;
  background: $sidebar-link-active;
  padding: 16px 16px 24px 16px;
  box-shadow: none;
  cursor: pointer;
  user-select: none;
  @include transition-default(all);

  &:hover {
    background: $bg-gray-4;
  }
}

.Header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 40px;

  &__left {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
  }
}

.Title {
  @include font-gilroy-24;
  color: $dark-text;
}

.Description {
  @include font-rubik-12;
  color: $text-gray;
}

.Content {
  display: flex;
  flex-direction: row;
  column-gap:  8px;
  justify-content: space-between;
  align-items: center;
}

.KeyValueItem {
  display: flex;
  flex-direction: column;
  row-gap: 8px;

  &:last-child {
    margin-left: auto;
  }

  &__title {
    @include font-rubik-14;
    color: $text-gray;

    &_dark {
      color: $dark-text;
    }
  }

  &__value {
    @include font-gilroy-16;
    color: $dark-text;
  }
}

.Divider {
  width: 1px;
  height: 44px;
  background: $border-gray;
  margin: 0 24px;
}