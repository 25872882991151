@import '../../../../shared/ui-kit-2/_constants/colors.scss';
@import '../../../../shared/ui-kit-2/_constants/typography.scss';
@import '../../../../app/global-styles/vars.scss';

.Card {
  display: flex;
  flex-direction: row;
  column-gap: 55px;
  align-items: center;
  padding: 0 74px;
  height: 632px;

  @media (max-width: $lk-mobile-xl) {
    flex-direction: column;
    height: auto;
    padding: 16px;
    margin-bottom: 16px;

    button {
      margin-top: 24px;
    }

    svg {
      width: 220px;
      height: 220px;
    }
  }
}

.Container {
  display: flex;
  flex-direction: column;
  max-width: 554px;
  row-gap: 48px;
  color: $dark-text;

  @media (max-width: $lk-mobile-xl) {
    row-gap: 0;
  }
}

.Info {
  @include font-rubik-16;
  display: flex;
  flex-direction: column;
  row-gap: 16px;

  @media (max-width: $lk-mobile-xl) {
    row-gap: 24px;
  }
}

.Title {
  @include font-gilroy-48;

  @media (max-width: $lk-mobile-xl) {
    font-size: 37px;
  }
}

.Subtitle {
  font-family: Rubik, sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}