@import "../../../../../shared/ui-kit-2/_constants/colors";
@import "../../../../../shared/ui-kit-2/_constants/typography";
@import "../../../../../app/global-styles/vars";

.DeviceDetails {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: auto;
  grid-template-areas:
    "tabs tabs tabs tabs tabs tabs tabs tabs"
    "modemInfo modemInfo modemInfo modemInfo modemInfo wifi wifi wifi"
    "subscription subscription subscription subscription ntInformation ntInformation ntInformation ntInformation"
    "redirects redirects redirects redirects redirects redirects redirects redirects"
    "ussd ussd ussd ussd ussd ussd ussd ussd";
  grid-gap: 16px;
  width: 100%;

  @media (max-width: $lk-mobile-l) {
    grid-template-columns: 1fr;
    grid-template-areas:
      "tabs"
      "modemInfo"
      "wifi"
      "subscription"
      "ntInformation"
      "redirects"
      "ussd";
    grid-gap: 8px;
    padding-bottom: 39px;

    .Ussd {
      &Card {
        flex-direction: column;
        align-items: flex-start;
        padding: 24px 16px;
      }

      &Title {
        margin-bottom: 22px;
      }

      &Input {
        width: calc(100% - 89px - 8px);
      }
    }

    .GridRedirects {
      grid-template-columns: 1fr;
    }
  }
}

.WifiWrapper {
  position: relative;
  display: flex;
  grid-area: wifi;
  flex-direction: column;
}

.Card {
  display: flex;
  flex-direction: column;
  padding: 24px 16px;
  border-radius: 20px;
  border: 1px solid $border-gray;
  background: $white-bg;

  &_modemInfo {
    padding: 18px;
    grid-area: modemInfo;
    position: relative;
    overflow: hidden;
  }

  &_wifi {
    grid-area: wifi;
  }

  &_subscription {
    grid-area: subscription;
  }

  &_networkInformation {
    grid-area: ntInformation;
  }

  &_redirects {
    grid-area: redirects;
  }
}

.Offline {
  pointer-events: none;
  opacity: 30%;
}

.OfflineWifi {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  row-gap: 8px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  > div {
    color: $danger;
    width: 191px;
    @include font-rubik-14;
  }
}

.VideoContainer {
  position: absolute;
  width: 234px;
  height: 153px;
  overflow: hidden;
  top: 0;
  right: 0;
  border-radius: 20px;
  z-index: 0;
}

.Video {
  position: absolute;
  width: 234px;
  top: -107px;
  height: 302%;
  z-index: 0;
}

.Mask {
  z-index: 2;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 234px;
  border-radius: 20px;
  background: linear-gradient(264deg, #F6F6FB 0%, rgba(250, 250, 250, 0) 40%, #F6F6FB 100%)
}

.Header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.Title {
  font-family: Gilroy, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  z-index: 3;
}

.ErrorSubscriptionTitle {
  opacity: 0.3;
}

.ErrorSubscription {
  opacity: 0.3;
  pointer-events: none;
}

.EditButton {
  g {
    path {
      stroke: $green-dark;
    }
  }
  span {
    color: $green-dark;
  }
  &:hover {
    span {
      color: $light-green;
    }
    svg {
      g {
        path {
          stroke: $light-green;
        }
      }
    }
  }
}

.Divider {
  width: 100%;
  height: 1px;
  margin: 21px 0 16px;
  background: $border-gray;
}

.List {
  display: flex;
  flex-direction: column;
  grid-gap: 16px;
}

.Row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 4px;
}

.RebootButton {
  display: flex;
  flex-direction: row;
  column-gap: 4px;
  align-items: center;
  @include font-gilroy-12;
  color: $dark-text;
  border: none;
  padding: 10px 7px;
  border-radius: 13px;
  background: $white;
  cursor: pointer;

  svg {
    width: 14px;
    height: 14px;
  }
}

.Label {
  color: $text-gray;
  font-family: Rubik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.2;
}

.Value {
  color: $dark-text;
  font-family: Gilroy, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

.Cols {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.Col {
  display: flex;
  flex-direction: column;
  grid-gap: 8px;

  &_mrXL {
    margin-right: 32px;
  }
}

.IMEI {
  display: flex;
  align-items: center;
  margin-top: 5px;
  z-index: 3;
}

.Bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: auto;
  z-index: 3;
}

.WifiSwitcher {
  margin-left: auto;
}

.WifiSettings {
  display: flex;
  width: 37px;
  margin-left: 10px;
  color: $text-gray;
  background: none;
  border: none;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    opacity: 0.7;
  }
}

.Copy {
  display: flex;
  align-items: center;
  margin-left: 12px;
  color: $green-dark;
  font-family: Gilroy, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  border: none;
  background: transparent;
  transition: 0.3s;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
}

.CopyIcon {
  margin: 2px 8px 0 0;
}

.Control {
  &:not(:last-child) {
    svg {
      path {
        stroke: $dark-text;
        fill: none;
      }
    }
  }

  button {
    min-width: 34px;
    min-height: 34px;
    max-width: 34px;
    max-height: 34px;
    width: 34px;
    height: 34px;
    background: $white;
    border-radius: 13px;
    transition: 0.3s;

    svg {
      path {
        fill: $dark-text;
      }
    }

    &:hover {
      opacity: 0.7;
    }
  }
}

.UssdCard {
  display: flex;
  flex-direction: row;
  column-gap: 16px;
  grid-area: ussd;
  background: $sidebar-link-active;
  padding: 16px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.UssdTitle {
  display: flex;
  flex-direction: row;
  column-gap: 18px;
  align-items: center;
  justify-content: center;

  &__text {
    color: $dark-text;
    @include font-gilroy-20;
  }
}

.UssdFakeForm {
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  align-items: center;
  justify-content: center;
}

.UssdInput {
  width: 253px;
  opacity: 0.5;
}

.UssdSubmitBtn {
  border-radius: 20px;
  width: max-content;
  padding: 0 27px;
}

.ErrorDate {
  border: 1px solid $red-alert;
}

.Status {
  display: flex;
  flex-direction: row;
  column-gap: 13px;
  align-items: center;
  color: $red-alert;
  @include font-rubik-16;
}