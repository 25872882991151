@import "../../_constants/typography";
@import "../../_constants/colors";
@import "../../_constants/transition-mixins";


.TextButton {
    display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  column-gap: 8px;
  @include transition-default(all);
  width: max-content;
  cursor: pointer;
  border: none;
  background: none;
  color: $dark-text;

  &:not([disabled]):hover,
  &:not([disabled]):focus{
    color: $green-dark;
    .TextButton__icon > svg {
      @include transition-default(all);
    }
  }

  &:disabled {
    cursor: default;
    color: $text-gray;
  }

  &_default {
    @include font-rubik-16;
    height: 19px;
  }

  &_bold {
    @include font-gilroy-16;
    height: 19px;
  }

  &_small {
    @include font-rubik-14;
    height: 17px;
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      width: 16px;
      height: 16px;
      path {
        @include transition-default(all);
      }
    }
  }
}
