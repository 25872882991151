@import "../../../shared/ui-kit-2/_constants/colors";
@import "../../../shared/ui-kit-2/_constants/typography";
@import "../../../app/global-styles/vars";

.Container {
  width: 796px;
  height: 100%;

  @media (max-width: $lk-mobile-l) {
    width: 100%;
  }
}