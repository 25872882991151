@import '../../_constants/colors.scss';
@import '../../_constants/typography.scss';

.StatusSubscription {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 5px;
  @include font-rubik-12;
  color: $green-dark;
}

.Inactive {
  color: $red-alert;
}