@import '../../../../../../../../shared/ui-kit-2/_constants/colors.scss';
@import '../../../../../../../../shared/ui-kit-2/_constants/typography.scss';
@import '../../../../../../../../app/global-styles/vars.scss';

.ContactGroup {
  width: 339px;
  min-width: 339px;
  padding: 32px 24px 16px 24px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;

  @media (max-width: $lk-tablet) {
    width: 100%;
  }
}

.ContactGroupHeader {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.Title {
  @include font-gilroy-24;
  color: $dark-text;
}

.Container {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  width: 100%;
  overflow-y: scroll;
  scrollbar-width: none;
}

.Button {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  align-items: center;
  padding: 12px;
  justify-content: center;
  background: transparent;
  border: 1px solid $green-dark;
  border-radius: 20px;
  color: $green-dark;
  @include font-gilroy-16;
  margin-top: auto;
  cursor: pointer;
}