@import '../../../../shared/ui-kit-2/_constants/colors.scss';
@import "../../../../app/global-styles/vars";

.Faq {
  position: relative;
  display: flex;
  flex-direction: column;
  grid-gap: 16px;
  z-index: 1;

  @media screen and (max-width: $landing-screen-laptop) {
    grid-gap: calc(8rem / $rem-mobile);

    .Item {
      padding: calc(24rem / $rem-mobile) calc(16rem / $rem-mobile);
    }

    .Label {
      padding-right: calc(16rem / $rem-mobile);
      line-height: 1.5;
      font-size: calc(16rem / $rem-mobile);
    }

    .Value {
      padding-top: calc(16rem / $rem-mobile);
      font-size: calc(14rem / $rem-mobile);
    }

    .Button {
      width: calc(39rem / $rem-mobile);
      height: calc(39rem / $rem-mobile);
      min-width: calc(39rem / $rem-mobile);
      min-height: calc(39rem / $rem-mobile);
      border-radius: calc(15rem / $rem-mobile);
    }
  }
}

.Top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.Item {
  padding: 16px 16px 16px 35px;
  background: $sidebar-link-active;
  color: $dark-text;
  border-radius: 30px;
  overflow: hidden;
  box-shadow: 1px 1px 1px 0 rgba(255, 255, 255, 0.24) inset, 0 6px 24.2px 0 rgba(0, 0, 0, 0.07), -3px -3px 6px 0 rgba(255, 255, 255, 0.21);
}

.Label {
  padding-right: 45px;
  font-family: Gilroy, sans-serif;
  font-size: 27px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.2;
}

.Value {
  position: fixed;
  opacity: 0;
  width: 85%;
  padding-top: 20px;
  font-family: Rubik, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;

  &_expanded {
    position: static;
    opacity: 1;
  }
}

.Button {
  min-width: 55px;
  min-height: 55px;
  background: $white;
  color: $green;
  transition: 0.3s;

  &:hover {
    opacity: 0.8;
  }
}