@import "../../../../../shared/ui-kit-2/_constants/colors";
@import "../../../../../shared/ui-kit-2/_constants/typography";

.Container {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  padding: 22px 24px 32px 24px;
  border: 1px solid $border-gray;
  border-radius: 20px;
}

.Header {
  display: flex;
  flex-direction: row;
  column-gap: 16px;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 16px;
  border-bottom: 1px solid $border-gray;
  margin-bottom: 24px;
}

.SmsType {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  column-gap: 8px;
  @include font-rubik-18;
  color: $dark-text;
}

.SmsTimeAndStatus {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  column-gap: 16px;
  @include font-gilroy-14;
  color: $dark-text;
}

.GrayCard {
  background: $sidebar-link-active;
  width: 100%;
  position: relative;
  border-radius: 20px;
}

.Arrow {
  position: absolute;
  width: 30px;
  height: 30px;
  left: 50%;
  top: 100%;
  transform: translate(-50%, -50%);
  background: $sidebar-link-active;
  border: 3px solid $white-bg;
  border-radius: 100%;
    display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.ContactContainer {
  display: flex;
  flex-direction: column;
  row-gap: 3px;
  align-items: center;
  justify-content: center;
  @include font-rubik-18;
  height: 95px;

  &__darkText {
    color: $dark-text;
  }

  &__textGray {
    color: $text-gray;
  }

  &__greenDark {
    color: $green-dark;
  }
}

.MessageContainer {
  padding: 16px;
  display: flex;
  flex-direction: column;
  row-gap: 8px;

  &__title {
    @include font-rubik-14;
    color: $text-gray;
  }

  &__text {
    @include font-rubik-16;
    color: $dark-text;
    line-height: 120%;
    word-break: break-all;
  }
}

