@import "../../../../../../shared/ui-kit-2/_constants/colors";
@import "../../../../../../shared/ui-kit-2/_constants/typography";
@import '../../../../../../app/global-styles/vars.scss';

.Detail {
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: 8px;
  padding: 20px 16px 19px 16px;
  border: 1px solid $border-gray;
  border-radius: 20px;

  @media (max-width: $lk-mobile-l) {
    padding: 0;
    border: none;
  }
}

.Title {
  @include font-rubik-14;
  color: $text-gray;
}

.Value {
  @include font-gilroy(16px, 120%, 600);
  color: $dark-text;
}
