@import "../../../shared/ui-kit-2/_constants/colors";
@import "../../../shared/ui-kit-2/_constants/typography";
@import "../../../app/global-styles/vars";

.BuyNewDeviceModal {
  width: 100vw;
  height: 100%;
  background: $bg-gray-3 !important;
}

.Content {
  display: flex;
  flex-direction: row;
  column-gap: 21px;

  @media screen and (max-width: $lk-mobile-l) {
    flex-direction: column-reverse;
  }
}