@import '../../../shared/ui-kit-2/_constants/colors.scss';
@import '../../../shared/ui-kit-2/_constants/typography.scss';

.Badge {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  min-width: 28px;
  height: 28px;
  min-height: 28px;
  border-radius: 100%;

  &_incoming {
    background: $light-green;
    transform: rotate(180deg);
  }

  &_outgoing {
    background: $light-blue;
  }

  &_delayed {
    background: $light-yellow;
  }

  &__delayedIcon {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: -1px;
    right: -3px;
    width: 14px;
    min-width: 14px;
    height: 14px;
    min-height: 14px;
    border-radius: 100%;
    background: $sidebar-link-active;
  }
}