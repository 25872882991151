.IconButton {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  transition: 0.2s ease;
  flex-shrink: 0;
  cursor: pointer;

  &:disabled {
    cursor: default;
    opacity: 0.3;
  }

  &_white {
    width: 58px;
    height: 58px;
    background: #fff;
    color: #79B75E;
    border-radius: 50%;

    &_active,
    &:not([disabled]):hover,
    &:not([disabled]):active {
      background: #79B75E;

      svg path {
        fill: #fff;
      }
    }
  }

  &_green {
    width: 36px;
    height: 36px;
    background: rgba(121, 183, 94, 0.1);
    border-radius: 4px;

    svg path {
      fill: #79B75E;
    }

    &_active,
    &:not([disabled]):hover,
    &:not([disabled]):active {
      background: #79B75E;

      svg path {
        fill: #fff;
      }
    }
  }

  &_search {
    display: flex;
    background: linear-gradient(90deg, #79B75E 0%, #D1D74E 100.01%);
    width: 48px;
    height: 48px;
    align-items: center;
    justify-content: center;
    border-radius: 3.857px;
    cursor: pointer;

    svg path {
      transition: stroke 0.2s ease;
      stroke: #080E16;
    }

    &_active,
    &:not([disabled]):hover,
    &:not([disabled]):active {
      & > svg {
        & > path {
          stroke: #79B75E;
        }
      }
    }
  }

  &_delete {
    svg path {
      fill: #EE1A26;
      transition: 0.2s ease;
    }

    &_active,
    &:not([disabled]):hover,
    &:not([disabled]):active {
      background: #EE1A26;

      svg path {
        fill: #fff;
      }
    }
  }

  &_text {
    width: 24px;
    height: 24px;
    background: none;
    padding: 0;
  }
}