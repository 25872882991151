
.Container {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  flex-grow: 1;
  height: 100%;
}

.Description {
  margin-top: 16px;
  font-family: Rubik, sans-serif;
  font-size: 16px;
  line-height: 1.5;
}

.Actions {
  display: flex;
  justify-content: space-between;
  grid-gap: 16px;
  margin-top: auto;

  button {
    height: 60px;
  }
}