@import '../../../app/global-styles/vars.scss';
@import "../../../shared/ui-kit-2/_constants/colors";

.GoodText {
  color: $green-dark;
}

.BadText {
  color: $red-alert;
}

.InvoiceText {
  margin-left: 6px;
}

.TableContainer {
  height: calc(100% - 64px);
  border-radius: 0 0 20px 20px;
  @media (max-width: $lk-mobile-l) {
    height: calc(100% - 87px);
    overflow: auto;
  }

  @media (max-width: $lk-tablet) {
    height: calc(100% - 87px);
    overflow: auto;
  }
}

.DongleName {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 65px;
  white-space: nowrap;
}

.TableRowGroups {
  @media (max-width: $lk-mobile-l) {
    padding-bottom: 31px;
  }
}

.TableGroupHeader {
  background: $white-bg;
  padding: 0;
  margin-top: 17px;
}

.Download {
  display: flex;
  align-items: center;
  transition: 0.3s;

  &:hover {
    opacity: 0.7;
  }
}

.Button {
  width: auto;
}

.NoDataMobile {
  @media (max-width: $lk-mobile-l) {
    margin-top: 25px;
    color: $text-gray;
    font-family: Rubik, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
  }
}