@import "../../../shared/ui-kit-2/_constants/colors";
@import "../../../shared/ui-kit-2/_constants/typography";
@import "../../../shared/ui-kit-2/_constants/transition-mixins";
@import "../../../app/global-styles/vars";


.DrawerAdditional {
  width: 100%;
}

.Container {
  flex-grow: 1;
    display: flex;
  flex-direction: column;


  @media (max-width: $lk-mobile-l) {
    .Actions {
      height: 60px;

      button {
        height: 100%;
      }
    }
  }
}

.SectionFrom {
  display: flex;
  flex-direction: column;
  row-gap: 18px;
}

.ForwardTo {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  justify-content: center;
  align-items: center;
  @include font-rubik-12;
  color: $text-gray;
  margin: 8px 0;
}

.SectionTo {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  margin-bottom: 24px;
}

.AutoReplyMessageAdditional {
  margin-top: 16px;
}

.Actions {
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  align-items: center;
  justify-content: center;
  margin-top: auto;

  button {
    height: 60px;
  }
}