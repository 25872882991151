@import '../../../../../../../../../../shared/ui-kit-2/_constants/typography.scss';
@import '../../../../../../../../../../shared/ui-kit-2/_constants/colors.scss';
@import '../../../../../../../../../../shared/ui-kit-2/_constants/transition-mixins';


.CardAdditional {
  width: 100%;
  background: $sidebar-link-active;
  padding: 16px 16px 24px 16px;
  box-shadow: none;
  cursor: pointer;
  user-select: none;
  @include transition-default(all);

  &:hover {
    background: $bg-gray-4;
  }
}

.Header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 40px;

  &__left {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
  }
}

.Title {
  @include font-gilroy-24;
  color: $dark-text;
}

.Description {
  @include font-rubik-12;
  color: $text-gray;
}

.Content {
  display: flex;
  flex-direction: column;
  column-gap: 8px;
  align-items: center;
  justify-content: space-between;
  row-gap: 16px;
}

.KeyValueItem {
  display: inline-flex;
  flex-direction: column;
  row-gap: 8px;

  &:last-child {
    align-self: center;
    justify-content: center;
  }

  &__title {
    @include font-rubik-12;
    color: $text-gray;

    &_dark {
      color: $dark-text;
      @include font-rubik-14;
    }
  }

  &__value {
    @include font-gilroy-14-bold;
    color: $dark-text;
    white-space: nowrap;
    width: 100%;

    &_dark {
      @include font-gilroy-20-bold
    }
  }
}

.ContentInfo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}