@import "../../../../../../shared/ui-kit-2/_constants/typography";
@import "../../../../../../shared/ui-kit-2/_constants/colors";

.Form {
  display: flex;
  flex-direction: column;
}

.FormFields {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.SignInButtonAdditional {
  width: 100% !important;
  margin-top: 24px;
}

.ForgotPasswordAdditional {
  width: 100% !important;
  margin-top: 24px;
}

.RuHelpText {
  margin-top: 48px;
  text-align: center;
  color: $text-gray;
  font-size: 16px;
}