@import "../../../../../../../shared/ui-kit-2/_constants/colors";
@import "../../../../../../../shared/ui-kit-2/_constants/typography";

.Container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.Overview {
  padding: 24px 16px 0 16px;
  border-radius: 20px;
  background: $sidebar-link-active;
}

.OverviewHeader {
  display: flex;
  flex-direction: row;
  column-gap:  8px;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 24px;
  border-bottom: 1px solid $border-gray;

  &__title {
    @include font-rubik-16;
    color: $dark-text;
  }

  &__statuses {
    display: flex;
    flex-direction: row;
    column-gap: 16px;
    align-items: center;
  }
}

.OverviewStatusItem {
  display: flex;
  flex-direction: row;
  column-gap: 5px;
  align-items: center;

  &__greenCircle {
    width: 10px;
    height: 10px;
    background: $green-dark;
    border-radius: 100%;
  }

  &__redCircle {
    width: 10px;
    height: 10px;
    background: $red-alert;
    border-radius: 100%;
  }

  &__text {
    @include font-rubik-12;
    color: $dark-text;
  }
}

.OverviewBody {
  height: 100%;
  max-height: 371px;
  overflow-y: auto;
}

.Sms {
  display: flex;
  flex-direction: row;
  column-gap: 16px;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0;
  border-bottom: 1px solid $border-gray;

  &:last-child {
    border-bottom: none;
  }

  &__receiver {
    display: flex;
    flex-direction: row;
    column-gap:  8px;
    align-items: center;
    @include font-rubik-16;

    &__phone {
      color: $text-gray;
    }

    &__contact {
      color: $dark-text;
    }
  }

  &__red {
    @include font-rubik-12;
    color: $red-alert;
  }

  &__green {
    @include font-rubik-12;
    color: $green-dark;
  }
}

.Button {
  margin-top: auto;
}