@import '../../../../../shared/ui-kit-2/_constants/colors.scss';
@import "../../../../../app/global-styles/vars";

.Container {
  position: relative;
  padding-top: 152px;

  @media screen and (min-width: $landing-screen-laptop) and (max-width: $landing-screen-laptop-l) {
    .Wrapper {
      width: 1058px;
    }

    .Title {
      font-size: 77px;
    }

    .Modem {
      width: 300px;
      height: 704px;
    }

    .Col {
      margin-top: 177px;

      &_right {
        margin-top: 285px;
      }
    }

    .Background {
      top: 20%;
    }
  }

  @media screen and (max-width: $landing-screen-laptop) {
    padding-top: calc(57rem / $rem-mobile);

    .Wrapper {
      width: calc(100% - 8.5rem);
      flex-direction: column-reverse;
      margin: 0 4.25rem;
    }

    .Item {
      display: flex;
      align-items: center;
      max-width: 100%;
      min-height: 10.37rem;
      margin-bottom: 4.25rem !important;
      padding-left: calc(17rem / $rem-mobile);
      border-left: calc(2rem / $rem-mobile) solid $green;
      font-size: 4.25rem;

      &:not(:last-child) {
        font-size: 4.25rem;
      }
    }

    .Line {
      display: none;
    }

    .Text {
      margin-top: 0;
      font-size: 3.72rem;
    }

    .Col {
      margin-top: 0;
    }

    .Title {
      margin-bottom: 0;
      font-size: calc(52rem / $rem-mobile);
    }

    .Modem {
      display: flex;
      justify-content: center;
      width: 53.2rem;
      height: 125.79rem;
      margin: 0 auto;
      order: 1;
      scale: 1;
    }

    .Background {
      top: 10%;
      height: 100%;
      scale: 0.8;
    }
  }
}

.Background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.Wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  width: 1280px;
  margin: 0 auto;
  z-index: 1;
}

.Title {
  position: relative;
  color: $dark-text;
  text-align: center;
  font-family: Gilroy, sans-serif;
  font-size: 87px;
  font-style: normal;
  font-weight: 700;
  line-height: 1;
  z-index: 1;
}

.Marked {
  color: $green-dark;
}

.Col {
  margin-top: 264px;

  &_right {
    margin-top: 370px;
  }
}

.Modem {
  scale: 0.8;

  img {
    width: 100%;
    height: 100%;
    margin-left: 30px;
  }
}

.Item {
  max-width: 352px;
  overflow: hidden;

  &:not(:last-child) {
    margin-bottom: 138px;
  }
}

.Line {
  &_right {
    transform: scale(-1, 1);
  }
}

.Text {
  margin-top: 20px;
  color: $dark-text;
  font-family: Rubik, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.2;
}