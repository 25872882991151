@import "../../../../../shared/ui-kit-2/_constants/colors";
@import "../../../../../shared/ui-kit-2/_constants/typography";
@import "../../../../../app/global-styles/vars";

.Content {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  width: 75%;

  @media screen and (max-width: $lk-mobile-l) {
    width: 100%;
  }
}

.TotalAmount {
  display: flex;
  align-items: center;
  flex-direction: row;;
  justify-content: flex-end;
  margin-top: auto;
  height: 60px;

  &__title {
    color: $dark-text;
    @include font-rubik(20px, 120%, 400);
  }

  &__value {
    color: $dark-text;
    @include font-gilroy(35px, 120%, 600);
    margin-left: 21px;
  }

  &__btn {
    width: 399px;
    margin-left: 32px;

    @media screen and (max-width: $lk-mobile-l) {
      width: 100%;
      margin: 6px 0 32px;
    }
  }
}

.CallToUs {
  @media screen and (max-width: $lk-mobile-l) {
    margin-bottom: 16px;
  }
}

.Form {
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  align-items: center;

  @media screen and (max-width: $lk-mobile-l) {
    flex-direction: column;
    align-items: flex-start;
    grid-gap: 24px;
  }
}

.FormContainer {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  padding: 24px;
  border: 1px solid $green;
  border-radius: 20px;
  width: 100%;
  height: 236px;

  @media screen and (max-width: $lk-mobile-l) {
    height: auto;
    padding: 16px;
  }
}

.FormTitle {
  @include font-gilroy-27;
  color: $dark-text;
  width: 537px;

  @media screen and (max-width: $lk-mobile-l) {
    width: 100%;
    margin-bottom: 12px;
  }
}

.Item {
  height: 78px;
  align-self: center;

  @media screen and (max-width: $lk-mobile-l) {
    width: 100%;
    align-self: flex-start;
  }
}

.Button {
  width: 156px;
  height: 56px;

  @media screen and (max-width: $lk-mobile-l) {
    width: 100%;
  }
}

.ItemButton {
  padding-top: 31px;

  @media screen and (max-width: $lk-mobile-l) {
    width: 100%;
  }
}

.ItemName {
  width: 280px;
  height: 78px;

  @media screen and (max-width: $lk-mobile-l) {
    width: 100%;
  }
}

.ItemPhone {
  width: 300px;
  height: 78px;

  @media screen and (max-width: $lk-mobile-l) {
    width: 100%;
  }
}