@import '../../_constants/colors.scss';
@import '../../_constants/typography.scss';

.PaginationContainer {
  z-index: 3;
  position: absolute;
  left: 0;
  bottom: 0;
  height: 59px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 32px;
  box-sizing: border-box;
  background: $white-bg;
  box-shadow: 0px -4px 12.3px 0px rgba(0, 0, 0, 0.04);
  border-radius: 25px;
  user-select: none;
}

.FooterInfo {
  color: $dark-text;
  @include font-gilroy(13px, normal, 500);
}

.Pagination {
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  align-items: center;

  &__arrow {
    height: 16px;
    width: 16px;
    cursor: pointer;

    &_disabled {
      opacity: 0.3;
      cursor: default;
    }
  }

  &__page {
    cursor: pointer;
    height: 28px;
    padding: 7px 10px;
    box-sizing: border-box;
    background: $sidebar-link-active;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $dark-text;
    @include font-gilroy(12px, normal, 500);
    border-radius: 8px;

    &_active {
      color: $white;
      background: $dark-text;
    }

    &_rest {
      cursor: default;
    }
  }
}