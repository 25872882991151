.Actions {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  column-gap: 8px;

  & > svg {
    cursor: pointer;
  }
}

.Name {
  width: 150px;
}