@import '../../../../../../app/global-styles/vars.scss';

.Container {
  display: flex;
  flex-direction: row;
  column-gap: 16px;
  min-height: calc(100vh - 182px);

  @media (max-width: $lk-tablet) {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
  }
}