@import "../../../app/global-styles/vars";
@import '../../../shared/ui-kit-2/_constants/colors.scss';
@import '../../../shared/ui-kit-2/_constants/typography.scss';

.Page {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 112px;
  padding-bottom: 100px;
  color: $dark-text;
  @include font-rubik-18;
  line-height: 150%;

  a {
    color: $green-dark;
  }

  h3 {
    @include font-gilroy-27;
  }

  &_ol {
    list-style-type: none;
  }

  &_ol_lower_alpha {
    list-style-type: lower-alpha;
  }

  &_ol_inherit {
    list-style-type: inherit;
  }
}

.Container {
  padding: 32px 24px;
}

.Content {
  display: flex;
  width: 100%;
  max-width: 1280px;
}

.Text {
  white-space: pre-wrap;

  table {
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 5px;
    border: 1px solid $border-gray;
    @include font-rubik-14;
    border-spacing: 0;
    border-collapse: collapse;

    td {
      padding: 10px;
    }

    tr:nth-child(odd) {
      background: $sidebar-link-active;
    }
  }
}

.Title {
  @include font-gilroy-48;
  text-align: center;
}