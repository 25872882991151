@import '../../../../../../../shared/ui-kit-2/_constants/colors.scss';
@import "../../../../../../../app/global-styles/vars";

.Input {
  width: 100%;
  padding: 1rem 0.5rem 1rem 0;
  background: transparent;
  border: none;
  border-bottom: 1px solid $white;
  border-radius: 0;
  color: $white;
  font-family: Gilroy, sans-serif;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.2;
  text-transform: uppercase;
  outline: none;

  &::placeholder {
    color: $white;
    opacity: 1;
  }

  &_error {
    color: $red-alert;
    border-color: $red-alert;

    &::placeholder {
      color: $red-alert;
    }
  }

  @media screen and (max-width: $landing-screen-laptop) {
    padding: calc(16rem / $rem-mobile) calc(8rem / $rem-mobile) calc(16rem / $rem-mobile) 0;
    font-size: calc(14rem / $rem-mobile);
  }
}