@import "../../../shared/ui-kit-2/_constants/colors";
@import "../../../shared/ui-kit-2/_constants/typography";

.Container {
  display: flex;
  align-items: center;
  flex-direction: column;
  row-gap: 24px;
  justify-content: flex-start;
  padding-top: 30px;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.Title {
  @include font-gilroy-27;
  color: $dark-text;
  max-width: 321px;
  text-align: center;
  z-index: 2;
}

.Btn {
  z-index: 2;
  max-width: 196px;
}

.Image {
  position: absolute;
  top: 120px;
  left: 0;
  width: 100%;
  z-index: 1;
  filter: blur(3px);
}
