@import "../../../shared/ui-kit-2/_constants/colors";
@import "../../../shared/ui-kit-2/_constants/typography";

.DrawerAdditional {
  width: 100%;
  position: relative;
}

.DeleteContact {
  cursor: pointer;
}

.Container {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  flex-grow: 1;
}

.CallContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.Button:hover {
  background-color: #0056b3;
}

.Actions {
  display: flex;
  align-items: center;
  grid-gap: 8px;
  margin-top: auto;

  button {
    height: 60px;
  }
}