@import "../../_constants/typography";
@import "../../../../app/global-styles/vars";

.Paper {
  border-radius: 20px !important;

  @media (max-width: $lk-mobile-s) {
    margin: 16px !important;
  }
}

.DialogContent {
  border: none !important;
}

.Title {
  margin-top: 8px;
  @include font-gilroy-24;
}

.Actions {
  display: flex;
  flex-direction: row;
  height: 50px;
  grid-gap: 8px;
  margin: 8px;

  button {
    width: max-content;
    min-width: 80px;
    height: 100%;
    padding: 0 10px;
  }
}

.Hidden {
  height: 0;
  opacity: 0;
}