@import "../../../../../../shared/ui-kit-2/_constants/typography";
@import "../../../../../../shared/ui-kit-2/_constants/colors";
@import '../../../../../../app/global-styles/vars';

.CallsSettingsPage {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(262px, 1fr));
  grid-gap: 16px;
}

.Card {
  padding: 24px 16px;
  height: 217px;
}

.Name {
  margin-bottom: 2px;
  color: $dark-text;
  font-family: Gilroy, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.PhoneNumber {
  height: 18px;
  margin-bottom: 16px;
  color: #7B8AA0;
  font-family: Gilroy, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}

.Selector {
  display: flex;
  justify-content: space-between;
  grid-gap: 8px;
  width: 100%;
  height: 48px;
  margin-bottom: 16px;
  padding: 4px;
  background: $bg-gray-2;
  border-radius: 20px;
  opacity: 0.5;

  &__Card {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    padding: 4px;
    font-family: Rubik, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    border-radius: 15px;

    &_active {
      background: #fff;
    }
  }
}

.MuteMode {
  display: flex;
  justify-content: space-between;
  grid-gap: 8px;

  &__Title {
    color: $dark-text;
    font-family: Gilroy, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
  }

  &__Subtitle {
    color: #7B8AA0;
    font-family: Gilroy, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
  }
}