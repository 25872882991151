@import "../../../../../../shared/ui-kit-2/_constants/typography";
@import "../../../../../../shared/ui-kit-2/_constants/colors";
@import '../../../../../../app/global-styles/vars.scss';

.Container {
  display: flex;
  flex-direction: column;
}

.Title {
  margin-top: 16px;
  margin-bottom: 24px;
  @include font-gilroy-48;
  color: $dark-text;

  @media (max-width: $lk-mobile-l)  {
    @include font-gilroy-27;
  }
}

.Form {
  display: flex;
  flex-direction: column;
}

.FormFields {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.SubmitButtonAdditional {
  width: 100% !important;
  margin-top: 24px;
}