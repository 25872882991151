@import "../../shared/ui-kit-2/_constants/colors";
@import "../../shared/ui-kit-2/_constants/typography";

.Container {
    display: flex;
  flex-direction: column;
  position: fixed;
  left: 0;
  top: 0;
  width: 210px;
  height: var(--app-height);
  background: $white-bg;
  border-radius: 0 26px 26px 0;
  box-shadow: 0 6px 24.2px 0 rgba(0, 0, 0, 0.07);
}

.Content {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 32px 16px 24px 16px;
  align-items: center;
  height: 100%;
}

.Logo {
  height: 40px;
  width: 119px;
  cursor: pointer;
  margin-bottom: 78px;
}